import React, { useEffect, useState } from "react";
import FavSearchBar from "../searchBar/FavSearchBar";
import "./Favorites.scss";
import { useLocation } from "react-router-dom";
import { Card, Button, Modal, Spin, Tag, Dropdown } from "antd";
import axios from "axios";
import { AudioFilled, SearchOutlined } from "@ant-design/icons";
import getSector from "../../json/getSector.json";
import { useNavigate, Link } from "react-router-dom";
import userapiservice from "../../api/userapiservice";
import { getRequest, postRequest, deleteRequest } from "../../api/http";
import PageNotFound from "../../components/PageNotFound/PageNotFound";
import ytPlayBtn from "../../assets/images/YouTube_play_button.svg";
import { Footer } from "antd/es/layout/layout";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Filter from "../home/filter";
import { motion } from "framer-motion";
import ModalPlayer from "../../components/PlayerModal/playerModal";
import { RWebShare } from "react-web-share";
import share from "../../assets/images/share.svg";
import organisations from "../../assets/images/organisations.svg";
import home from "../../assets/images/home.svg";
import homeBlue from "../../assets/images/homeBlue.svg";
import fav from "../../assets/images/favorites.svg";
import favBlue from "../../assets/images/favoritesBlue.svg";
import bookmarkedFilled from "../../assets/images/bookmark.svg";
import shareIcon from "../../assets/images/share-icon.svg";
import saved from "../../assets/images/saved.svg";
import EditProfileLogo from "../../assets/images/EditProfileIcon.svg";
import EditProfileLogoBlue from "../../assets/images/EditProfileIconBlue.svg";
import EditProfilePage from "../../screens/editProfile/EditProfile";

export default function Favorites() {
  const partner = localStorage.getItem("partner");
  const location = useLocation();
  const ageID = localStorage.getItem("ageID");
  const [microsites, setMicrosites] = useState();
  // console.log("dtaa", microsites);
  const [loading, setLoading] = useState(true);
  const [updateFilterData, setUpdateFilterData] = useState(false);
  const [allResponse, setAllResponse] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [mediatype, setmediatype] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [af, setAf] = useState("");
  const [gl, setGl] = useState("");
  const [ms, setMs] = useState("");
  const [tz, setTz] = useState("");
  const [boolaf, setBoolAf] = useState(false);
  const [boolgl, setBoolGl] = useState(false);
  const [boolms, setBoolMs] = useState(false);
  const [booltz, setBoolTz] = useState(false);
  const page = "favorites";
  const [bookmarkData, setBookmarkData] = useState([]);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [county, setCounty] = useState("");
  const [country, setCountry] = useState("");

  const baseUrl = window.location.origin;
  const url1 = location?.pathname?.split("/")[1];
  // console.log("url", `${baseUrl}/${url1}`)

  // console.log("bookmark data", bookmarkData);

  const [subSectors, setSubSectors] = useState([]);
  const [sectorId, setSectorId] = useState();
  // localStorage.getItem("sector") ? localStorage.getItem("sector") : null
  const [subsectorId, setSubsectorId] = useState();
  // localStorage.getItem("subsector") ? localStorage.getItem("subsector") : null
  const [selectedEvent, setselectedEvent] = useState();
  // localStorage.getItem("Interest/AssetType")
  //   ? localStorage.getItem("Interest/AssetType")
  //   : null
  const [size, setSize] = useState();
  // localStorage.getItem("size") ? localStorage.getItem("size") : null

  const [interestName, setinterestName] = useState();
  // localStorage.getItem("Interest/AssetType")
  //   ? localStorage.getItem("Interest/AssetType")
  //   : null

  const phoneNumber = localStorage.getItem("Phone Number");
  const accessToken = localStorage.getItem("accessToken");

  const [searchName, setSearchName] = useState();
  // localStorage.getItem("searchName")
  //   ? localStorage.getItem("searchName")
  //   : null

  const interest = [
    "",
    "PRODUCT",
    "CONTRACTS",
    "SERVICE",
    "VACANCY",
    "APPRENTICESHIP",
    "WORK_EXPERIENCE",
  ];

  const defaultOrganisationType = [""];

  const [organisationTypeName, setOrganisationTypeName] = useState();
  // localStorage.getItem("OrganisationType")
  //   ? localStorage.getItem("OrganisationType")
  //   : null

  const [organisationNameById, setOrganisationNameById] = useState("");

  const [selectedOrganisationType, setSelectedOrganisationType] = useState();
  // localStorage.getItem("OrganisationType")
  //   ? localStorage.getItem("OrganisationType")
  //   : null

  const [cardId, setCardId] = useState();

  const { id } = useParams();
  const [userData, setUserData] = useState(null);

  const navigate = useNavigate();
  const [partnerData, setPartnerData] = useState({});
  const [dynamicOrganisationTypes, setDynamicOrganisationTypes] = useState([]);

  // console.log("dyna", dynamicOrganisationTypes);

  const getPartnerDetails = async () => {
    try {
      const response = await getRequest(
        userapiservice.getPartnerDetails(partner)
      );
      if (response) {
        // console.log(response)
        setPartnerData(response);
        // setDynamicOrganisationTypes(response.organizationTypes);
        // localStorage.setItem("partnerId", response.id);
        // console.log("response",response.organizationTypes);
      }
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  useEffect(() => {
    getPartnerDetails();
  }, []);

  const removeBookmark = async (id, name) => {
    try {
      const response = await deleteRequest(
        userapiservice.removeBookmark(id, phoneNumber)
      );

      if (response) {
        getBookmark();
        fetchMicrosites(
          partner,
          selectedOrganisationType,
          selectedEvent,
          sectorId,
          subsectorId,
          size,
          searchName,
          af,
          gl,
          ms,
          tz
        );
        toast.success(`${name} removed from favorites`);
      }
    } catch (error) {
      toast.error(error.error);
    }
  };

  const organisationType = [
    ...defaultOrganisationType,
    ...dynamicOrganisationTypes,
  ];

  // console.log("orgg", organisationType);

  const suffix = (
    <AudioFilled
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );
  const prefix = <SearchOutlined style={{ color: "gray" }} />;

  const convertToStandardURL = (shortUrl) => {
    if (shortUrl?.indexOf("youtu.be") !== -1) {
      const video_id = shortUrl?.split("youtu.be/")[1].split("?")[0];
      return `https://www.youtube.com/watch?v=${video_id}`;
    }
    return shortUrl;
  };

  const getBookmark = async () => {
    // console.log("getMicrositeLogo");
    try {
      const response = await getRequest(
        userapiservice.getBookmark(phoneNumber, partner)
      );
      // console.log("res", response);
      if (response) {
        setBookmarkData(response.microsites);
      }
    } catch (error) {
      toast.error(error.error);
    }
  };

  const postBookmark = async (id) => {
    try {
      const response = await postRequest(
        userapiservice.postBookmark(id, phoneNumber)
      );
      // console.log(response)
      if (response) {
        toast.success("Added to favorites");
        getBookmark();
      }
    } catch (error) {
      toast.error(error.error);
    }
  };

  const handleBookmark = (id) => {
    postBookmark(id);
  };

  useEffect(() => {
    getBookmark();
  }, []);

  const fetchMicrosites = async (
    partner,
    organisationTypeName,
    interestName,
    sectorId,
    subsectorId,
    size,
    searchName,
    af,
    gl,
    ms,
    tz
  ) => {
    setLoading(true);
    try {
      await getRequest(
        userapiservice.filterFavorites(
          partner,
          phoneNumber,
          organisationTypeName,
          interestName,
          sectorId,
          subsectorId,
          size,
          searchName,
          af,
          gl,
          ms,
          tz
        )
      ).then((res) => {
        let response = [...res.result];
        let output = [...res.result],
          subsectorOutput = [],
          sectorOutput = [];

        // if(!sectorId ) {
        //   output = [...response];
        // }
        // console.log("Before mapping output",output)
        setAllResponse(response.result);
        // console.log("getMicrosite Response:",response);

        // response.map((res) => {
        //   res.sectorsOutputs?.map((data) => {
        //     if(sectorId && data.sectorOutput?.id == sectorId){
        //       output.push(res)
        //       if(subsectorId && data.subSectorOutput?.id != subsectorId) {
        //         output.pop(res)
        //       }
        //     }
        //   })
        // })

        // if(size) {
        //   let temp = [...output]
        //   output = [];
        //   temp.map((res,i) => {
        //     if(res.size == size){
        //       output.push(res)
        //     }
        //   })
        // }

        // response.map((res) => {
        //   res.sectorsOutputs?.map((data) => {
        //     if ((sectorId && data.sectorOutput?.id == sectorId) || !sectorId) {
        //       sectorOutput.push(res);
        //     }
        //   });
        // });
        // console.log('sectorOutput: ', sectorOutput);

        // sectorOutput.map((res) => {
        //   res.sectorsOutputs?.map((data) => {
        //     if (
        //       (subsectorId && data.subSectorOutput?.id == subsectorId) ||
        //       !subsectorId
        //     ) {
        //       output.push(res);
        //     }
        //   });
        // });
        // console.log('subsectorOutput :', subsectorOutput)

        // output.map((res) => {
        //   if ((size && res.size == size) || !size) {
        //     output.push(res);
        //   }
        // });
        // console.log('output: ', output)

        setMicrosites([
          ...new Map(output.map((item) => [item["id"], item])).values(),
        ]);

        if (interestName) {
          localStorage.setItem(
            "Interest/AssetType",
            interestName ? interestName : ""
          );
          if (interestName === "PRODUCT") {
            localStorage.setItem("tabValue", "product");
          } else if (interestName === "SERVICE") {
            localStorage.setItem("tabValue", "service");
          } else if (interestName === "VACANCY") {
            localStorage.setItem("tabValue", "vacancy");
          } else if (interestName === "APPRENTICESHIP") {
            localStorage.setItem("tabValue", "apprenticeship");
          } else if (interestName === "WORK_EXPERIENCE") {
            localStorage.setItem("tabValue", "work-experience");
          } else if (interestName === "COURSES") {
            localStorage.setItem("tabValue", "courses");
          } else if (interestName === "CONTRACTS") {
            localStorage.setItem("tabValue", "contract");
          }
        }

        setLoading(false);
      });
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.state?.data && location.state?.data.length > 0) {
      // console.log('true');
      setMicrosites(location.state.data);
    } else
      fetchMicrosites(
        partner,
        selectedOrganisationType,
        selectedEvent,
        sectorId,
        subsectorId,
        size,
        searchName,
        af,
        gl,
        ms,
        tz
      );
    setLoading(true);

    // if (token) {
    //   getEndUserData();
    // }
  }, [af, gl, ms, tz]);

  const OnCardClickHandler = async (id, logo, name) => {
    // localStorage.setItem("micrositeID", id);
    const formattedName = name.replace(/ /g, "_");
    localStorage.setItem("micrositeName", formattedName);
    navigate(`/microsite/${formattedName}`, {
      state: { page: "favorites", logo: { logo } },
    });
    // try {
    //   const response = await getRequest(userapiservice.getMicrositeList(id));
    //   // console.log(response)
    //   const micrositeLogo = response[0].mediaLink;
    //   if (response && response.length == 1) {
    //     navigate(`/microsite/${response[0].id}`, { state: { page: "home", logo: { micrositeLogo } } });
    //   } else {
    //     navigate(`/micrositeList/${id}`);
    //   }
    // } catch (error) {
    //   toast.error(error.error);
    // }
  };

  const GetValueFromSearch = (data) => {
    setMicrosites(data);
  };

  const RemoveHandler = (value) => {
    localStorage.removeItem(value);
    // console.log(value);
    if (value == "organisationType") {
      localStorage.removeItem("OrganisationType");
      setOrganisationTypeName(null);
      setSelectedOrganisationType(null);
      fetchMicrosites(
        partner,
        null,
        interestName,
        sectorId,
        subsectorId,
        size,
        searchName,
        af,
        gl,
        ms,
        tz
      );
    }
    if (value == "interest") {
      localStorage.removeItem("Interest/AssetType");
      localStorage.removeItem("tabValue");
      setinterestName(null);
      setselectedEvent(null);
      fetchMicrosites(
        partner,
        organisationTypeName,
        null,
        sectorId,
        subsectorId,
        size,
        searchName,
        af,
        gl,
        ms,
        tz
      );
    }
    if (value == "sector") {
      setSectorId(null);
      setSubsectorId(null);
      setSubSectors(null);
      localStorage.removeItem("subsector");
      fetchMicrosites(
        partner,
        organisationTypeName,
        interestName,
        null,
        null,
        size,
        searchName,
        af,
        gl,
        ms,
        tz
      );
    }
    if (value == "subsector") {
      setSubsectorId(null);
      fetchMicrosites(
        partner,
        organisationTypeName,
        interestName,
        sectorId,
        null,
        size,
        searchName,
        af,
        gl,
        ms,
        tz
      );
    }
    if (value == "size") {
      setSize(null);
      fetchMicrosites(
        partner,
        organisationTypeName,
        interestName,
        sectorId,
        subsectorId,
        null,
        searchName,
        af,
        gl,
        ms,
        tz
      );
    }
    if (value == "searchName") {
      // console.log("removed")
      setSearchName(null);
      fetchMicrosites(
        partner,
        organisationTypeName,
        interestName,
        sectorId,
        subsectorId,
        size,
        null,
        af,
        gl,
        ms,
        tz
      );
    }
    setUpdateFilterData(!updateFilterData);
  };

  const FindSectorName = (id) => {
    let response = "";
    getSector.map((data) => {
      if (id === data.id) {
        // console.log(data.name)
        response = data.name;
      }
    });
    return response;
  };

  const getSubSector = async (sectorId) => {
    try {
      setLoading(true);
      // const headers = {
      //   Authorization: `Bearer ${localStorage.getItem("token")}`,
      // };
      const response = await getRequest(userapiservice.getSubSectors(sectorId));

      setSubSectors(response);
      // setSubsectorValue(subSectorId);
      setLoading(false);
    } catch (error) {
      toast.error(error.error);
      setLoading(false);
    }
  };

  const FindSubSectorName = (id) => {
    let response = "";
    // if (allResponse?.length > 0) {
    //   allResponse.map((res) => {
    //     res.sectorsOutputs?.map((data) => {
    //       if (data.subSectorOutput?.id == id) {
    //         response = data.subSectorOutput?.name;
    //       }
    //     });
    //   });
    // }
    subSectors.map((data) => {
      if (id === data.id) {
        response = data.name;
      }
    });
    return response;
  };

  const setFilterHandler = (
    list,
    organisationTypeName,
    interestName,
    sectorId,
    subSectorIds,
    size
  ) => {
    setOrganisationTypeName(organisationTypeName);
    setinterestName(interestName);
    setSectorId(sectorId);
    setSubsectorId(subSectorIds);
    setSize(size);
    setMicrosites(list);
    // console.log("List:",list)
  };

  const deleteSelectedValue = (variableName) => {
    if (variableName == "interest") {
      setselectedEvent(null);
    }
    if (variableName == "organisationType") {
      setSelectedOrganisationType(null);
    }
  };

  const handleOk = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setVideoUrl("");
  };

  const editProfileClickHandler = () => {
    // console.log('check');
    setShowEditProfile(true);
  };

  const closeModal = () => {
    // console.log("closed")
    setShowEditProfile(false);
  };

  function getVideoId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  const PlayVideoHandler = (url, mediaType) => {
    setmediatype(mediaType);
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  useEffect(() => {
    getSubSector(sectorId);
    localStorage.setItem("Page", "favorites");
  }, [sectorId]);

  const handleReset = () => {
    setAf("");
    setBoolAf(false);

    setGl("");
    setBoolGl(false);

    setMs("");
    setBoolMs(false);

    setTz("");
    setBoolTz(false);
  }

  const handleAF = () => {
    handleReset()
    if (!boolaf) {
      setAf("A-F");
      setBoolAf(true);
    } else {
      setAf("");
      setBoolAf(false);
    }
  };

  const handleGL = () => {
    handleReset()
    if (!boolgl) {
      setGl("G-L");
      setBoolGl(true);
    } else {
      setGl("");
      setBoolGl(false);
    }
  };

  const handleMS = () => {
    handleReset()
    if (!boolms) {
      setMs("M-S");
      setBoolMs(true);
    } else {
      setMs("");
      setBoolMs(false);
    }
  };

  const handleTZ = () => {
    handleReset()
    if (!booltz) {
      setTz("T-Z");
      setBoolTz(true);
    } else {
      setTz("");
      setBoolTz(false);
    }
  };

  return (
    <motion.div
      className="background-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <FavSearchBar GetValueFromSearch={GetValueFromSearch} />
      <div className="filter-options">
        {organisationTypeName && organisationNameById ? (
          <Tag
            style={{ marginTop: "3px" }}
            closable
            onClose={RemoveHandler.bind(this, "organisationType")}
          >
            {/* // <Tag closable> */}
            {organisationNameById ? organisationNameById : ""}
          </Tag>
        ) : null}
        {interestName ? (
          <Tag
            style={{ marginTop: "3px" }}
            closable
            onClose={RemoveHandler.bind(this, "interest")}
          >
            {/* // <Tag closable> */}
            {interestName === "APPRENTICESHIP"
              ? "Apprenticeships"
              : interestName === "VACANCY"
                ? "Vacancies"
                : interestName === "PRODUCT"
                  ? "Products"
                  : interestName === "SERVICE"
                    ? "Service"
                    : interestName === "WORK_EXPERIENCE"
                      ? "Experiences of work"
                      : interestName === "COURSES"
                        ? "Courses"
                        : interestName === "CONTRACTS"
                          ? "Contract"
                          : ""}
          </Tag>
        ) : null}
        {sectorId ? (
          <Tag
            style={{ marginTop: "3px" }}
            closable
            onClose={RemoveHandler.bind(this, "sector")}
          >
            {FindSectorName(sectorId)}
          </Tag>
        ) : null}
        {subsectorId ? (
          <Tag
            style={{ marginTop: "3px" }}
            closable
            onClose={RemoveHandler.bind(this, "subsector")}
          >
            {FindSubSectorName(subsectorId)}
          </Tag>
        ) : null}
        {size ? (
          <Tag
            style={{ marginTop: "3px" }}
            closable
            onClose={RemoveHandler.bind(this, "size")}
          >
            {size === "MICRO"
              ? "Micro 0-9"
              : size === "SMALL"
                ? "Small 10-49"
                : size === "MEDIUM"
                  ? "Medium 50-249"
                  : size === "LARGE"
                    ? "Large 250+"
                    : ""}
          </Tag>
        ) : null}
        {searchName ? (
          <Tag
            style={{ marginTop: "3px" }}
            closable
            onClose={RemoveHandler.bind(this, "searchName")}
          >
            {searchName}
          </Tag>
        ) : null}
      </div>
      <div className="filter-container">
        {!boolaf ? (
          <Button
            id="A-F-btn"
            // type="primary"
            className="alpha-filter-btn alpha-filter"
            style={{ backgroundColor: "rgba(198, 164, 230, 0.7)" }}
            onClick={handleAF}
          >
            <span style={{ color: "white" }}>A-F</span>
          </Button>
        ) : (
          <Button
            id="A-F-btn"
            //  type="primary"
            className="alpha-filter-btn alpha-filter"
            style={{
              // backgroundColor: "rgba(198, 164, 230, 0.7)",
              border: "1.5px solid #B771F9",
              backgroundColor: "#B771F9",
            }}
            onClick={handleAF}
          >
            <span style={{ color: "#fff", fontWeight: "bold" }}>A-F</span>
          </Button>
        )}
        {!boolgl ? (
          <Button
            id="G-L-btn"
            type="primary"
            className="alpha-filter-btn alpha-filter"
            style={{ backgroundColor: "rgba(242, 119, 148, 0.7)" }}
            onClick={handleGL}
          >
            <span style={{ color: "white" }}>G-L</span>
          </Button>
        ) : (
          <Button
            id="G-L-btn"
            //  type="primary"
            className="alpha-filter-btn alpha-filter"
            style={{
              // backgroundColor: "rgba(242, 119, 148, 0.7)",
              backgroundColor: "#FE3464",
              border: "1.5px solid #FE3464",
            }}
            onClick={handleGL}
          >
            <span style={{ color: "#fff", fontWeight: "bold" }}>G-L</span>
          </Button>
        )}
        {!boolms ? (
          <Button
            id="M-S-btn"
            type="primary"
            className="alpha-filter-btn alpha-filter"
            style={{ backgroundColor: "rgba(185, 230, 165, 0.7)" }}
            onClick={handleMS}
          >
            <span style={{ color: "white" }}>M-S</span>
          </Button>
        ) : (
          <Button
            id="M-S-btn"
            //  type="primary"
            className="alpha-filter-btn alpha-filter"
            style={{
              // backgroundColor: "rgba(185, 230, 165, 0.7)",
              border: "1.5px solid #65AD45",
              backgroundColor: "#65AD45",
            }}
            onClick={handleMS}
          >
            <span style={{ color: "#fff", fontWeight: "bold" }}>M-S</span>
          </Button>
        )}
        {!booltz ? (
          <Button
            id="T-Z-btn"
            type="primary"
            className="alpha-filter-btn alpha-filter"
            style={{ backgroundColor: "rgba(114, 225, 213, 0.7)" }}
            onClick={handleTZ}
          >
            <span style={{ color: "white" }}>T-Z</span>
          </Button>
        ) : (
          <Button
            id="T-Z-btn"
            //  type="primary"
            className="alpha-filter-btn alpha-filter"
            style={{
              // backgroundColor: "rgba(114, 225, 213, 0.7)",
              border: "1.5px solid #1EB8A7",
              backgroundColor: "#1EB8A7",
            }}
            onClick={handleTZ}
          >
            <span style={{ color: "#fff", fontWeight: "bold" }}>T-Z</span>
          </Button>
        )}
        <Filter
          setData={setFilterHandler}
          pageType="FavoritesPage"
          updateFilterData={updateFilterData}
          // organisationType={selectedOrganisationType}
          selectedOrganisationType={selectedOrganisationType}
          setSelectedOrganisationType={setSelectedOrganisationType}
          setOrganisationNameById={setOrganisationNameById}
          dynamicOrganisationTypes={dynamicOrganisationTypes}
          // interest={selectedEvent}
          selectedEvent={selectedEvent}
          setselectedEvent={setselectedEvent}
          title="Filter"
          className={
            "button-primary form-submit-btn text-regular filter-icon text-white"
          }
        // icon={""}
        >
          {/* <Form> */}
          {/* <div className="d-flex">
            <Form.Item>
              <label className="label-text">Organisation Type</label>
              <Select
                placeholder="Select Option"
                value={selectedOrganisationType}
                onChange={(e) => {
                  setSelectedOrganisationType(e);
                }}
              >
                {organisationType?.map((Search, i) => (
                  <Select.Option key={i} value={organisationType[i]}>
                    {organisationType[i] === "" ? "All" : organisationType[i]}
                  </Select.Option>
                ))}
              </Select>
              <div
                className={`remove-btn ${
                  !selectedOrganisationType ||
                  (selectedOrganisationType && selectedOrganisationType == "")
                    ? "d-none"
                    : ""
                }`}
              >
                <MinusCircleOutlined
                  onClick={() => deleteSelectedValue("organisationType")}
                />
              </div>
            </Form.Item>
          </div> */}
          {/* <div className="d-flex">
            <Form.Item>
              <label className="label-text">Interests</label>
              <Select
                placeholder="Select Option"
                value={selectedEvent}
                onChange={(e) => {
                  setselectedEvent(e);
                  if (e === "PRODUCT") {
                    localStorage.setItem("tabValue", "product");
                  } else if (e === "SERVICE") {
                    localStorage.setItem("tabValue", "service");
                  } else if (e === "VACANCY") {
                    localStorage.setItem("tabValue", "vacancy");
                  } else if (e === "APPRENTICESHIP") {
                    localStorage.setItem("tabValue", "apprenticeship");
                  } else {
                    localStorage.setItem("tabValue", "work-experience");
                  }
                }}
              >
                {interest?.map((Search, i) => (
                  <Select.Option key={i} value={interest[i]}>
                    {interest[i] === ""
                      ? "All"
                      : interest[i] === "APPRENTICESHIP"
                      ? "Apprenticeships"
                      : interest[i] === "VACANCY"
                      ? "Vacancies"
                      : interest[i] === "PRODUCT"
                      ? "Products"
                      : interest[i] === "SERVICE"
                      ? "Services"
                      : interest[i] === "WORK_EXPERIENCE"
                      ? "Experiences of work"
                      : ""}
                  </Select.Option>
                ))}
              </Select>
              <div
                className={`remove-btn ${
                  !selectedEvent || (selectedEvent && selectedEvent == "")
                    ? "d-none"
                    : ""
                }`}
              >
                <MinusCircleOutlined
                  onClick={() => deleteSelectedValue("interest")}
                />
              </div>
            </Form.Item>
          </div> */}
          {/* </Form> */}
        </Filter>
      </div>
      <div className="float-container">
        {/* <FloatButton.Group className="float-group"> */}
        <div className="float-group">
          <div
            className="icon-container"
            onClick={() => {
              navigate("/home");
            }}
          >
            {/* <Button icon={<HomeOutlined />} style={{backgroundColor: "#569dfa", color: "white"}}/> */}
            {page === "home" ? <img src={homeBlue} /> : <img src={home} />}
            <div className="icon-text">Home</div>
          </div>
          <div
            className="icon-container"
            onClick={() => {
              if (phoneNumber && accessToken) {
                navigate("/favorites");
              } else {
                toast.error("Please login to access Favorites");
              }
            }}
          >
            {page === "favorites" ? (
              <img src={favBlue} style={{ marginRight: "3px" }} />
            ) : (
              <img src={fav} style={{ marginRight: "3px" }} />
            )}

            <div className="icon-text fav-icon">Favorites</div>
          </div>
          <div
            className="icon-container"
            onClick={() => navigate("/onboarding")}
          >
            <img src={organisations} />
            <div className="icon-text">Organisation</div>
          </div>
          {/* </FloatButton.Group> */}
        </div>
      </div>

      <motion.div className="org-card-container">
        {microsites && microsites.length > 0
          ? microsites.map((microsite) => (
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.8,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              <Card
                className="card-item home-page-card-item card-hover"
                // onClick={() => {
                //   OnCardClickHandler(microsite.id, microsite.mediaLink);
                // }}
                key={microsite.id}
                bordered={false}
              >
                <div className="card-inner-container">
                  {/* Kebab Menu */}
                  <div className="kebab-menu">
                    <Dropdown
                      trigger={["click"]}
                      placement="bottomRight"
                      overlay={
                        <div
                          style={{
                            border: "1px solid #f0f0f0",
                            borderRadius: "4px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <div
                            style={{
                              padding: "10px",
                              borderBottom: "1px solid #f0f0f0",
                              cursor: "pointer",
                            }}
                          >
                            <div>
                              <RWebShare
                                data={{
                                  url: `${baseUrl}/microsite/${microsite.name.replace(
                                    / /g,
                                    "_"
                                  )}`,
                                }}
                              >
                                <span>
                                  <img src={shareIcon} alt="share" />
                                  <span style={{ marginLeft: "5px" }}>
                                    Share
                                  </span>
                                </span>
                              </RWebShare>
                            </div>
                          </div>
                          <div
                            style={{
                              padding: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <img src={saved} alt="share" />
                            <span
                              style={{ marginLeft: "5px" }}
                              onClick={() =>
                                removeBookmark(microsite.id, microsite.name)
                              }
                            >
                              Remove from Favorite
                            </span>
                          </div>
                        </div>
                      }
                    >
                      <img
                        src={share} // This should be your kebab/three-dots icon
                        alt="Options"
                        style={{ cursor: "pointer" }}
                      />
                    </Dropdown>
                  </div>
                </div>
                <div className="image-container">
                  {/* <img
                  src={microsite?.mediaLink}
                  alt={microsite.name}
                  className="preview-image"
                /> */}
                  {microsite.carouselLink?.mediaType === "SOCIAL_MEDIA" &&
                    microsite.carouselLink?.url ? (
                    <>
                      {(() => {
                        const standardURL = convertToStandardURL(
                          microsite.carouselLink.url
                        );
                        const videoID = standardURL
                          .split("v=")[1]
                          ?.split("&")[0];
                        return (
                          <div
                            className="media youtube"
                            style={{ objectFit: "contain" }}
                            onClick={() =>
                              PlayVideoHandler(
                                microsite.carouselLink?.url,
                                microsite.carouselLink?.mediaType
                              )
                            }
                          >
                            <img
                              src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                              alt=""
                              className="video-preview-image"
                              style={{ height: "100%", borderRadius: "8px" }}
                            />
                            <img
                              className="bookMarked"
                              src={bookmarkedFilled}
                              alt="bookmark"
                            />
                            <img className="ytBtnOverlay" src={ytPlayBtn} />
                          </div>
                        );
                      })()}
                    </>
                  ) : microsite.carouselLink?.mediaType === "VIMEO_MEDIA" &&
                    microsite.carouselLink?.url ? (
                    <div
                      className="media vimeo youtube"
                      onClick={() => {
                        PlayVideoHandler(
                          microsite.carouselLink?.url,
                          microsite.carouselLink?.mediaType
                        );
                      }}
                    >
                      <img
                        src={`https://vumbnail.com/${microsite.carouselLink?.url?.split("/")[4]
                          }.jpg`}
                        className="video-preview-image"
                        style={{ height: "100%", borderRadius: "8px" }}
                      />

                      <img
                        className="bookMarked"
                        src={bookmarkedFilled}
                        alt="bookmark"
                      />
                      <img className="ytBtnOverlay" src={ytPlayBtn} />
                      {/* <iframe
                        controls="false"
                        // video={VimeoLinkPlayHandler(data?.mediaLink.url)}
                        // src={`${microsite.carouselLink?.url}?title=0&byline=0&portrait=0&transparent=0&autoplay=1`}
                        src={microsite.carouselLink?.url}
                        light={true}
                        // responsive={true}
                        // onEnd={() => setVideoCompleted(false)}
                        // onProgress={() => setVideoCompleted(true)}
                        // onPlaying={() => setVideoCompleted(true)}
                        // onPlay={() => setVideoCompleted(true)}
                        // onPause={() => setVideoCompleted(false)}
                        allowFullScreen
                        height="100%"
                        width="100%"
                        style={{ backgroundColor: "black" }}
                      /> */}
                      {/* </div> */}
                    </div>
                  ) : (
                    <div className="media">
                      <div className="slider-Img">
                        <img
                          src={microsite.carouselLink?.url}
                          alt=""
                          className="home-preview-img"
                        />
                      </div>

                      <img
                        className="bookMarked"
                        src={bookmarkedFilled}
                        alt="bookmark"
                      />
                    </div>
                  )}
                </div>
                <div
                  className="text-container"
                  onClick={() => {
                    OnCardClickHandler(
                      microsite.id,
                      microsite.mediaLink,
                      microsite.name
                    );
                  }}
                >
                  <span className="microsite-position text-bold">
                    {/* {microsite.name} */}
                    {/* <img src={microsite.mediaLink} alt={microsite.name}
                      className="preview-image" /> */}
                    <img
                      src={microsite.micrositeLogo}
                      alt={microsite.name}
                      className="preview-image"
                    />
                    <div>
                      {/* <Rate value={microsite.averageRating} disabled allowHalf style={{fontSize:13}}></Rate> */}
                      {/* <p>{microsite}</p> */}
                    </div>
                  </span>
                  <div className="sector-info-container">
                    <span className="microsite-details text-bold">
                      {/* {microsite.type} */}
                      {/* {microsite.type === "EDUCATION_SECTOR"
                      ? "Education"
                      : microsite.type === "PUBLIC_SECTOR"
                        ? "Public"
                        : microsite.type === "BUSINESS_SECTOR"
                          ? "Business"
                          : ""} */}
                      {microsite.businessDescription}
                    </span>
                    {/* <span className="microsite-details text-bold">
                    {microsite?.sectorsOutputs[0]?.sectorOutput.name}
                  </span> */}
                  </div>
                </div>
              </Card>
            </motion.div>
          ))
          : ""}
      </motion.div>
      <div className="no-data-warning">
        {microsites?.length === 0 && (
          <div>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PageNotFound
                message={
                  "Sorry there are no organisations at present for this search so try removing filters"
                }
              />
            </span>
          </div>
        )}
      </div>
      {loading && (
        <div
          className="no-data-warning"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <span>
            <Spin tip="Loading" size="large" />
          </span>
        </div>
      )}
      <Footer className="footer">
        <div
          className="footer-content"
          onClick={() => window.open("https://www.connectsu.co.uk/", "_blank")}
          style={{ cursor: "pointer" }}
        >
          {/* <span>Powered by </span> */}
          <div className="footer-logo">
            <img src={require("../../assets/images/PoweredNew.png")} />
          </div>
        </div>
      </Footer>

      {isModalOpen ? (
        <ModalPlayer
          closeModal={handleCancel}
          mediaLink={videoUrl}
          mediaType={mediatype}
        />
      ) : (
        ""
      )}
    </motion.div>
  );
}
