import React from "react";

export default function PageNotFound({ message }) {
  return (
    <div
      className="not-found-container"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#9c9c9d",
        fontSize: "20px",
        width: "95%"
      }}
    >
      <div className="text-medium">{message}</div>
    </div>
  );
}

PageNotFound.defaultProps = {
  message: "Oops...! The page you're looking for does not exist",
};
