import React, { useEffect, useState } from "react";
import { Row, Col, Spin } from "antd";
import { toast } from "react-toastify";
import userapiservice from "../../../api/userapiservice";
import { getRequest } from "../../../api/http";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import ytPlayBtn from "../../../assets/images/YouTube_play_button.svg"
import ModalPlayer from "../../../components/PlayerModal/playerModal";


export default function OurStory({ activeKey }) {
    const [loading, setLoading] = useState(true);
    // const [selectedOption, setselectOption] = useState({});
    // const [data, setData] = useState([{}]);
    const [videoCompleted, setVideoCompleted] = useState(false);
    // const [activeKey, setActiveKey] = useState("about-us");
    const [responseData, setReponseData] = useState([]);
    // const { id } = useParams();
    const id = localStorage.getItem("micrositeID");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");
    const [mediatype, setmediatype] = useState("");
    const navigate = useNavigate();
    const color = ["#E75E0D", "#EB9F11", "#ABC710", "#009340", "#CC0370", "#8366A9", "#149BD7"];

    const getAboutData = async (activeKey, id) => {
        try {
            setLoading(true);
            if (id) {
                const response = await getRequest(
                    userapiservice.getAboutUsDetailsTabs(id, activeKey)
                );
                setReponseData(response);
                setLoading(false);
            }
        } catch (error) {
            toast.error(error.error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (activeKey && id) {
            getAboutData(activeKey, id);
        }
    }, []);

    const PlayVideoHandler = (url, mediaType) => {
        setmediatype(mediaType)
        setVideoUrl(url);
        setIsModalOpen(true);
    };

    const PlayVimeoVideoHandler = (url, mediaType) => {
        setmediatype(mediaType)
        setVideoUrl(url);
        setIsModalOpen(true);
    };

    const convertToStandardURL = (shortUrl) => {
        if (shortUrl?.indexOf('youtu.be') !== -1) {
            const video_id = shortUrl?.split('youtu.be/')[1].split('?')[0];
            return `https://www.youtube.com/watch?v=${video_id}`;
        }
        return shortUrl;
    }

    const handleOk = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setVideoUrl("");
    };

    const onCardClickHandler = (data, id) => {
        navigate(`/microsite/${data.id}/micrositeDetails`, {
            state: { page: activeKey, data: data },
        });
    };


    if (loading) {
        return (
            <div className="loader">
                <Spin tip="Loading" size="large" />
            </div>
        )
    } else {
        return (
            <div className="background-container" style={{ width: "100%" }}>
                <div className="card-container">
                    {responseData && responseData.length > 0
                        ? <Row
                            className="rowBody preview-container preview-tab-container"
                            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        >
                            {responseData.map((data, i) => {
                                return (
                                    <motion.div initial={{ opacity: 0, scale: 0.5 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        transition={{
                                            duration: 0.8,
                                            delay: 0.5,
                                            ease: [0, 0.71, 0.2, 1.01]
                                        }}>
                                        <Col onClick={() => {
                                            onCardClickHandler(data);
                                        }}
                                            className="card-item-container" style={{ borderLeft: `5px solid ${color[(i % 7)]}`, height: '200px' }}>

                                            {data.mediaLink?.url ? (
                                                <Col className="gutter-row" style={{ paddingRight: "0px", paddingLeft: "0px", display: "flex", justifyContent: "center", width: '100%' }}
                                                >
                                                    {data.mediaLink?.mediaType == "SOCIAL_MEDIA" ? (
                                                        <div
                                                            className=" videoOverlay"
                                                            onClick={() =>
                                                                PlayVideoHandler(
                                                                    data.mediaLink?.url,
                                                                    data.mediaLink?.mediaType
                                                                )
                                                            }
                                                        >
                                                            {(() => {
                                                                const standardURL = convertToStandardURL(
                                                                    data.mediaLink?.url
                                                                );
                                                                const videoID = new URLSearchParams(
                                                                    new URL(standardURL).search
                                                                ).get("v");
                                                                return (
                                                                    <>
                                                                        <img
                                                                            src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                                                                            style={{ objectFit: "cover" }}
                                                                            alt=""
                                                                            className="video-preview-image"
                                                                        />
                                                                        <img
                                                                            className="ytBtnOverlay"
                                                                            src={ytPlayBtn}
                                                                            onClick={() =>
                                                                                PlayVideoHandler(
                                                                                    data.mediaLink?.url,
                                                                                    data.mediaLink?.mediaType
                                                                                )
                                                                            }
                                                                        />
                                                                    </>
                                                                );
                                                            })()}
                                                        </div>
                                                    ) : data?.mediaLink?.mediaType == "VIMEO_MEDIA" ? (
                                                        <div
                                                            className="sliderImg videoOverlay"
                                                        // onClick={() => {
                                                        //   onCardClickHandler(data);
                                                        // }}

                                                        >
                                                            <img
                                                                src={`https://vumbnail.com/${data.mediaLink.url.split('/')[4]}.jpg`}
                                                                alt=""
                                                                className="video-preview-image"
                                                                onClick={PlayVimeoVideoHandler.bind(
                                                                    this,
                                                                    data.mediaLink?.url,
                                                                    data.mediaLink?.mediaType
                                                                )}
                                                            />
                                                            <img
                                                                className="ytBtnOverlay"
                                                                src={ytPlayBtn}
                                                                onClick={PlayVideoHandler.bind(
                                                                    this,
                                                                    data.mediaLink?.url,
                                                                    data.mediaLink?.mediaType
                                                                )}
                                                            />
                                                        </div>
                                                    ) : data?.mediaLink?.mediaType === "IMAGE" ? (
                                                        <div className="">
                                                            <img
                                                                src={data.mediaLink?.url}
                                                                className="preview-img"
                                                                onClick={() => {
                                                                    onCardClickHandler(data);
                                                                }}
                                                            />
                                                        </div>
                                                    ) : <div style={{ height: "100%" }}></div>}
                                                </Col>
                                            ) : (
                                                <Col className="gutter-row" span={8}>
                                                </Col>
                                            )}
                                        </Col>
                                    </motion.div>
                                );
                            })
                            }
                        </Row> : <div style={{
                            marginTop: '20px',
                            marginBottom: '10px',
                            textAlign: 'center',
                            width: '100%',
                            fontSize: 'large'
                        }} className="info-container" > Nothing here at the moment </div>
                    }
                </div>
                {(isModalOpen) ? (
                    <ModalPlayer closeModal={handleCancel} mediaLink={videoUrl} mediaType={mediatype} />
                ) : ""}
            </div >
        );
    }
}
