import React from 'react'
import './wysiwyg.scss'
import { formatDate } from "../../utils/helper";

const Wysisyg = ({ state }) => {
    return (
        <>
            <div className='ql-editor' dangerouslySetInnerHTML={{ __html: state?.data?.description }} />
            <small>
                {formatDate(state.data.createdAt)}
            </small>
        </>
    )
}

export default Wysisyg