import moment from "moment";
export function truncateFilename(filename, maxLength = 25) {
    if (filename.length <= maxLength) {
      return filename;
    } else {
      let basename = filename.substring(0, filename.lastIndexOf('.'));
      let extension = filename.substring(filename.lastIndexOf('.'));
  
      let truncatedName = basename.substring(0, maxLength - extension.length) + '...' + extension;
      return truncatedName;
    }
  }


  export const formatDate = (timestamp) => {
    const date = moment.utc(timestamp).local();
    const now = moment()
    const duration = moment.duration(now.diff(date));
    const hours = duration.asHours();
    const minutes = duration.asMinutes();

    if (minutes < 60) {
        return `${Math.floor(minutes)} minutes ago`;
    } else if (hours < 24) {
        return `${Math.floor(hours)} hours ago`;
    } else {
        return date.format('MMMM D, YYYY');
    }
}