import React, { useEffect, useState } from "react";
import SearchBar from "../searchBar/SearchBar";
import "../home/HomePage.scss";
import { Tag, Button, Modal, Layout, Form, Card } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./View.scss";
import { getRequest, postRequest } from "../../api/http";
import FiledCircle from "../../assets/images/filled-circle.svg";
import viewIcon from "../../assets/images/ListView.svg";
import userapiservice from "../../api/userapiservice";
import useComponentVisible from "../login/useComponentVisible";
import MapComponent from "../../components/maps/map";
import ytPlayBtn from "../../assets/images/YouTube_play_button.svg";
import Filter from "../home/filter";
import { motion } from "framer-motion";
import ModalPlayer from "../../components/PlayerModal/playerModal";
import useWindowDimensions from "../../custom-hooks/window";
import axios from "axios";

export default function ViewPage() {
  const [loading, setLoading] = useState(false);
  const [radiusValue, setRadiusValue] = useState(
    localStorage.getItem("zoom") ? localStorage.getItem("zoom") : 9
  );
  const [markerData, setMarkerData] = useState([]);
  const [subSectors, setSubSectors] = useState([]);

  const [boolaf, setBoolAf] = useState(false);
  const [boolgl, setBoolGl] = useState(false);
  const [boolms, setBoolMs] = useState(false);
  const [booltz, setBoolTz] = useState(false);

  const [af, setAf] = useState("");
  const [gl, setGl] = useState("");
  const [ms, setMs] = useState("");
  const [tz, setTz] = useState("");

  const [videoUrl, setVideoUrl] = useState("");
  const [mediatype, setmediatype] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const partnerId = localStorage.getItem("partnerId");
  const ageID = localStorage.getItem("ageID");
  const profID = localStorage.getItem("profID");

  const [getSector, setGetSector] = useState([]);

  const [organisationTypeName, setOrganisationTypeName] = useState(
    localStorage.getItem("OrganisationType")
      ? localStorage.getItem("OrganisationType")
      : null
  );
  const [selectedOrganisationType, setSelectedOrganisationType] = useState(
    localStorage.getItem("OrganisationType")
      ? localStorage.getItem("OrganisationType")
      : null
  );

  const [searchName, setSearchName] = useState(
    localStorage.getItem("searchName")
      ? localStorage.getItem("searchName")
      : null
  );

  const [selectedEvent, setselectedEvent] = useState(
    localStorage.getItem("Interest/AssetType")
      ? localStorage.getItem("Interest/AssetType")
      : null
  );
  const [interestName, setinterestName] = useState(
    localStorage.getItem("Interest/AssetType")
      ? localStorage.getItem("Interest/AssetType")
      : null
  );
  const [sectorId, setSectorId] = useState(
    localStorage.getItem("sector") ? localStorage.getItem("sector") : null
  );
  const [subsectorId, setSubsectorId] = useState(
    localStorage.getItem("subsector") ? localStorage.getItem("subsector") : null
  );
  const [size, setSize] = useState(
    localStorage.getItem("size") ? localStorage.getItem("size") : null
  );
  const [updateFilterData, setUpdateFilterData] = useState(false);
  const [selectedMicrosite, setSelectedMicrosite] = useState();
  const [cardState, setCardState] = useState(false);
  const [test, setTest] = useState(false);
  const [clicked, setClicked] = useState("");

  const interest = [
    "",
    "APPRENTICESHIP",
    "VACANCY",
    "PRODUCT",
    "CONTRACTS",
    "SERVICE",
    "WORK_EXPERIENCE",
  ];

  const defaultOrganisationType = [""];
  const [organisationNameById, setOrganisationNameById] = useState("");

  const [partnerData, setPartnerData] = useState({});
  const [dynamicOrganisationTypes, setDynamicOrganisationTypes] = useState([]);

  // console.log("dyna", dynamicOrganisationTypes);

  const getPartnerDetails = async () => {
    try {
      const response = await getRequest(
        userapiservice.getPartnerDetails(partner)
      );
      if (response) {
        // console.log(response)
        setPartnerData(response);
        // setDynamicOrganisationTypes(response.organizationTypes);
        // localStorage.setItem("partnerId", response.id);
        // console.log("response", response.organizationTypes);
      }
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  useEffect(() => {
    getPartnerDetails();
  }, []);
  useEffect(() => {
    if (clicked === "clicked") {
      setTest(true);
      setClicked("");
    }
  }, [clicked]);

  const organisationType = [
    ...defaultOrganisationType,
    ...dynamicOrganisationTypes,
  ];

  const { height, width } = useWindowDimensions();
  const { Content, Footer } = Layout;
  const navigate = useNavigate();
  const [isShowProfile, setIsShowProfile] = useState(false);
  const { setIsComponentVisible } = useComponentVisible(true, setIsShowProfile);
  const [allResponse, setAllResponse] = useState([]);
  const [selectedZoom, setSelectedZoom] = useState();
  const [manualControl, setManualControl] = useState(false);

  const partner = localStorage.getItem("partner");

  const viewHandler = () => {
    navigate("/home");
  };

  const handleReset = () => {
    setAf("");
    setBoolAf(false);

    setGl("");
    setBoolGl(false);

    setMs("");
    setBoolMs(false);

    setTz("");
    setBoolTz(false);
  }

  const handleAF = () => {
    handleReset()
    if (!boolaf) {
      setAf("A-F");
      setBoolAf(true);
    } else {
      setAf("");
      setBoolAf(false);
    }
  };

  const handleGL = () => {
    handleReset()

    if (!boolgl) {
      setGl("G-L");
      setBoolGl(true);
    } else {
      setGl("");
      setBoolGl(false);
    }
  };

  const handleMS = () => {
    handleReset()

    if (!boolms) {
      setMs("M-S");
      setBoolMs(true);
    } else {
      setMs("");
      setBoolMs(false);
    }
  };

  const handleTZ = () => {
    handleReset()

    if (!booltz) {
      setTz("T-Z");
      setBoolTz(true);
    } else {
      setTz("");
      setBoolTz(false);
    }
  };

  const GetValueFromSearch = (data) => {
    setMarkerData(data);
  };
  // console.log("scwheight --- ", height);
  // console.log("scwwidth --- ", width);

  // const organisationIds = markerData?.map(
  //   (organisation) => organisation.organizationId
  // );

  // const showProfile = () => {
  //   setIsShowProfile(!isShowProfile);
  //   setIsComponentVisible(true);
  // };

  const ChangeModeHandler = (value) => {
    setClicked("clicked");
    // setTest(true)
    // console.log("setting zoom from footer ", value)
    setManualControl(true);

    if (value === 2) {
      setSelectedZoom(2);
      if (width <= 375) {
        setRadiusValue(13);
      } else if (width <= 768) {
        setRadiusValue(14);
      } else if (width <= 1024) {
        setRadiusValue(15);
      } else {
        setRadiusValue(15);
      }
    } else if (value === 5) {
      setSelectedZoom(5);
      if (width <= 375) {
        setRadiusValue(12);
      } else if (width <= 768) {
        setRadiusValue(13);
      } else if (width <= 1024) {
        setRadiusValue(14);
      } else {
        setRadiusValue(14);
      }
    } else if (value === 15) {
      setSelectedZoom(15);
      if (width <= 375) {
        setRadiusValue(11);
      } else if (width <= 768) {
        setRadiusValue(12);
      } else if (width <= 1024) {
        setRadiusValue(13);
      } else {
        setRadiusValue(13);
      }
    } else if (value === 25) {
      setSelectedZoom(25);
      if (width <= 375) {
        setRadiusValue(10);
      } else if (width <= 768) {
        setRadiusValue(11);
      } else if (width <= 1024) {
        setRadiusValue(12);
      } else {
        setRadiusValue(11);
      }
    }
    // setRadiusValue(zoomLevel);
  };

  const ChangeRadiusHandler = (value) => {
    // console.log("function called", test)
    !(clicked === "clicked") && setSelectedZoom();

    // console.log(" !clicked", clicked)
    // setManualControl();

    // setRadiusValue(value);
  };

  const convertToStandardURL = (shortUrl) => {
    if (shortUrl?.indexOf("youtu.be") !== -1) {
      const video_id = shortUrl?.split("youtu.be/")[1].split("?")[0];
      return `https://www.youtube.com/watch?v=${video_id}`;
    }
    return shortUrl;
  };

  const zoomPercentage = (mode) => {
    if (width <= 375) {
      switch (true) {
        case mode === 2:
          return "11%";
        case mode === 5:
          return "36%";
        case mode === 15:
          return "61%";
        case mode === 25:
          return "87%";
        default:
          return "100%";
      }
    } else if (width <= 768) {
      switch (true) {
        case mode === 2:
          return "11%";
        case mode === 5:
          return "36%";
        case mode === 15:
          return "61%";
        case mode === 25:
          return "87%";
        default:
          return "100%";
      }
    } else if (width <= 1024) {
      switch (true) {
        case mode === 2:
          return "11%";
        case mode === 5:
          return "36%";
        case mode === 15:
          return "61%";
        case mode === 25:
          return "87%";
        default:
          return "100%";
      }
    } else {
      switch (true) {
        case mode === 2:
          return "11%";
        case mode === 5:
          return "36%";
        case mode === 15:
          return "61%";
        case mode === 25:
          return "87%";
        default:
          return "100%";
      }
    }
  };

  const SetFilterValue = (
    list,
    organisationTypeName,
    interestName,
    sectorId,
    subSectorIds,
    size
  ) => {
    setOrganisationTypeName(organisationTypeName);
    setinterestName(interestName);
    setSectorId(sectorId);
    setSubsectorId(subSectorIds);
    setSize(size);
    setMarkerData(list.result);
  };

  const onRemoveData = async (organisationType, interest, sectorId, subSectorIds, size, search) => {
    try {
      const response = await getRequest(
        userapiservice.filterMicrosites(
          partner,
          organisationType,
          interest,
          sectorId,
          subSectorIds,
          size,
          search
        )
      );
      setMarkerData(response.result);
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong....",
      });
    }
  };

  const fetchSectorList = async () => {
    try {
      const response = await getRequest(userapiservice.getAllSectors(partnerId));
      setGetSector(response);
      // if (sectorId) {
      //   setSectorValue(sectorId);
      //   sectorChange(sectorId);
      // }
      // if (size) {
      //   setSizeValue(size);
      // }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    fetchSectorList();
  }, []);

  const FindSectorName = (id) => {
    let response = "";
    getSector.map((data) => {
      if (id === data.id) {
        // console.log(data.name)
        response = data.name;
      }
    });
    return response;
  };

  const getSubSector = async (sectorId) => {
    try {
      setLoading(true);
      // const headers = {
      //   Authorization: `Bearer ${localStorage.getItem("token")}`,
      // };
      const response = await getRequest(userapiservice.getSubSectors(sectorId));

      setSubSectors(response);
      // setSubsectorValue(subSectorId);
      setLoading(false);
    } catch (error) {
      toast.error(error.error);
      setLoading(false);
    }
  };

  const FindSubSectorName = (id) => {
    let response = "";
    subSectors.map((data) => {
      if (id === data.id) {
        response = data.name;
      }
    });
    return response;
  };

  const deleteSelectedValue = (variableName) => {
    if (variableName === "interest") {
      setselectedEvent(null);
    }
    if (variableName == "organisationType") {
      setSelectedOrganisationType(null);
    }
  };

  const getMarkers = async () => {
    try {
      const response = await getRequest(
        userapiservice.filterMicrosites(
          partner,
          organisationTypeName,
          interestName,
          sectorId,
          subsectorId,
          size,
          searchName,
          af,
          gl,
          ms,
          tz
        )
      );
      if (response) {
        setMarkerData(response?.result);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    getMarkers();
  }, [af, gl, ms, tz]);

  const RemoveHandler = (value) => {
    localStorage.removeItem(value);
    if (value === "organisationType") {
      localStorage.removeItem("OrganisationType");
      setOrganisationTypeName(null);
      setSelectedOrganisationType(null);
      onRemoveData(null, selectedEvent, sectorId, subsectorId, size, searchName);
    }
    if (value === "interest") {
      localStorage.removeItem("Interest/AssetType");
      localStorage.removeItem("tabValue");
      setselectedEvent(null);
      setinterestName(null);
      onRemoveData(selectedOrganisationType, null, sectorId, subsectorId, size, searchName);
    }
    if (value === "sector") {
      setSectorId(null);
      setSubsectorId(null);
      localStorage.removeItem("subsector");
      onRemoveData(selectedOrganisationType, selectedEvent, null, null, size, searchName);
    }
    if (value === "subsector") {
      onRemoveData(selectedOrganisationType, selectedEvent, sectorId, null, size, searchName);
      setSubsectorId(null);
    }
    if (value === "size") {
      onRemoveData(selectedOrganisationType, selectedEvent, sectorId, subsectorId, null, searchName);
      setSize(null);
    }
    if (value === "searchName") {
      onRemoveData(selectedOrganisationType, selectedEvent, sectorId, subsectorId, size, null);
      setSearchName(null);
    }
    setUpdateFilterData(!updateFilterData);
  };

  const selectedOrg = (data, state) => {
    // console.log("data in view", data, state)
    setCardState(state);
    setSelectedMicrosite(data);
  };

  const OnCardClickHandler = async (id, logo, name) => {
    // localStorage.setItem("micrositeID", id);
    // navigate(`/microsite/${name}`, { state: { page: "home", logo: { logo } } });
    const formattedName = name.replace(/ /g, "_");
    localStorage.setItem("micrositeName", formattedName);
    navigate(`/microsite/${formattedName}`, {
      state: { page: "map", logo: { logo } },
    });
  };

  const PlayVideoHandler = (url, mediaType) => {
    setmediatype(mediaType);
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setVideoUrl("");
  };

  useEffect(() => {
    setLoading(true);
    // fetchMicrosites(selectedEvent, sectorId, subsectorId, size);
    FindSectorName();
    FindSubSectorName();
  }, []);

  useEffect(() => {
    if (sectorId) {
      getSubSector(sectorId);
    }
    localStorage.setItem("Page", "view");
  }, [sectorId]);


  // CREATE PROFILE CODE
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [county, setCounty] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");

  useEffect(() => {
    const createdUser = localStorage.getItem("user");
    // console.log("user", localStorage.getItem("user"));
    if (createdUser != "created") {
      console.log("fetching loc")
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            // console.log("position", position)
          },
          (error) => {
            console.error(error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    }
  }, []);

  useEffect(() => {
    const createdUser = localStorage.getItem("user");
    if (createdUser != "created") {
      if (latitude === "" || longitude === "") {
        // this is to stop fetching data on component mount, cause we have no Latitude and Longitude YET
        return;
      } else {
        // console.log("OSM func")
        async function getWeather() {
          let res = await axios.get(
            `https://nominatim.openstreetmap.org/reverse?format=geojson&zoom=8&lat=${latitude}&lon=${longitude}`
          );
          // https://nominatim.org/release-docs/develop/api/Reverse/#example-with-formatgeojson
          // For more info on the OSM API, refer the link mentioned above

          localStorage.setItem(
            "country",
            res.data?.features[0]?.properties?.address?.country
          );
          if (res.data?.features[0]?.properties?.address?.county) {
            localStorage.setItem(
              "county",
              res.data?.features[0]?.properties?.address?.county
            );
          } else if (res.data?.features[0]?.properties?.address?.state_district) {
            localStorage.setItem(
              "county",
              res.data?.features[0]?.properties?.address?.state_district
            );
          } else if (res.data?.features[0]?.properties?.address?.municipality) {
            localStorage.setItem(
              "county",
              res.data?.features[0]?.properties?.address?.municipality
            );
          } else if (res.data?.features[0]?.properties?.address?.town) {
            localStorage.setItem(
              "county",
              res.data?.features[0]?.properties?.address?.town
            );
          } else {
            localStorage.setItem(
              "county",
              res.data?.features[0]?.properties?.address?.city
            );
          }
          localStorage.setItem(
            "state",
            res.data?.features[0]?.properties?.address?.state
          );

          // console.log("loc", res.data?.features[0]?.properties?.address);

          // setCounty(res.data.location.region);
          // setCountry(res.data.location.country);

          if (res.data?.features[0]?.properties?.address?.county) {
            setCounty(res.data?.features[0]?.properties?.address?.county);
          } else if (res.data?.features[0]?.properties?.address?.state_district) {
            setCounty(res.data?.features[0]?.properties?.address?.state_district);
          } else if (res.data?.features[0]?.properties?.address?.municipality) {
            setCounty(res.data?.features[0]?.properties?.address?.municipality);
          } else if (res.data?.features[0]?.properties?.address?.town) {
            setCounty(res.data?.features[0]?.properties?.address?.town);
          } else {
            setCounty(res.data?.features[0]?.properties?.address?.city);
          }
          // setCounty(res.data?.features[0]?.properties?.address?.county);
          setCountry(res.data?.features[0]?.properties?.address?.country);
          setState(res.data?.features[0]?.properties?.address?.state);
        }
        getWeather();
      }
    }
  }, [latitude, longitude]);

  const sendUserDetails = async () => {
    try {
      const response = await postRequest(userapiservice.sendDetails(), {
        ageGroupId: ageID,
        tagId: profID, // make dynamic
        latitude: latitude,
        longitude: longitude,
        countryName: country,
        countyName: county,
        state: state,
        partnerId: partnerId
      });
      if (response) {
        localStorage.setItem("user", "created");
      }
      // console.log("res", response)
    } catch (error) {
      console.error("error in");
    }
  };

  useEffect(() => {
    if (latitude && longitude && country && county && state) {
      sendUserDetails();
    }
  }, [county]);


  if (markerData) {
    return (
      <Layout className="layout">
        <SearchBar GetValueFromSearch={GetValueFromSearch} page="View" />

        {/* Filter tags component */}
        <div className="map-filter-options">
          {organisationTypeName && organisationNameById ? (
            <Tag
              style={{ marginTop: "3px" }}
              closable
              onClose={RemoveHandler.bind(this, "organisationType")}
            >
              {/* // <Tag closable> */}
              {organisationNameById ? organisationNameById : ""}
            </Tag>
          ) : null}
          {interestName ? (
            <Tag
              style={{ marginTop: "3px" }}
              closable
              onClose={RemoveHandler.bind(this, "interest")}
            >
              {/* // <Tag closable> */}
              {interestName === ""
                ? "All"
                : interestName === "APPRENTICESHIP"
                  ? "Apprenticeship"
                  : interestName === "VACANCY"
                    ? "Vacancies"
                    : interestName === "PRODUCT"
                      ? "Products"
                      : interestName === "CONTRACTS"
                        ? "Contract"
                        : interestName === "SERVICE"
                          ? "Service"
                          : interestName === "WORK_EXPERIENCE"
                            ? "Experiences of work"
                            : ""}
            </Tag>
          ) : null}
          {sectorId ? (
            <Tag
              style={{ marginTop: "3px" }}
              closable
              onClose={RemoveHandler.bind(this, "sector")}
            >
              {FindSectorName(sectorId)}
            </Tag>
          ) : null}
          {subsectorId ? (
            <Tag
              style={{ marginTop: "3px" }}
              closable
              onClose={RemoveHandler.bind(this, "subsector")}
            >
              {FindSubSectorName(subsectorId)}
            </Tag>
          ) : null}
          {size ? (
            <Tag
              style={{ marginTop: "3px" }}
              closable
              onClose={RemoveHandler.bind(this, "size")}
            >
              {/* {size} */}
              {size === "MICRO"
                ? "Micro"
                : size === "SMALL"
                  ? "Small"
                  : size === "MEDIUM"
                    ? "Medium"
                    : size === "LARGE"
                      ? "Large"
                      : ""}
            </Tag>
          ) : null}
          {searchName ? (
            <Tag
              style={{ marginTop: "3px" }}
              closable
              onClose={RemoveHandler.bind(this, "searchName")}
            >
              {searchName}
            </Tag>
          ) : null}
        </div>

        <Content>
          {/* Filter component */}
          <div className="view-filter filter-container">
            {!boolaf ? (
              <Button
                id="A-F-btn"
                // type="primary"
                className="alpha-filter-btn alpha-filter"
                style={{ backgroundColor: "rgba(198, 164, 230, 0.7)" }}
                onClick={handleAF}
              >
                <span style={{ color: "white" }}>A-F</span>
              </Button>
            ) : (
              <Button
                id="A-F-btn"
                //  type="primary"
                className="alpha-filter-btn alpha-filter"
                style={{
                  // backgroundColor: "rgba(198, 164, 230, 0.7)",
                  backgroundColor: "#B771F9",
                  border: "1.5px solid #B771F9",
                }}
                onClick={handleAF}
              >
                <span style={{ color: "#fff", fontWeight: "bold" }}>A-F</span>
              </Button>
            )}
            {!boolgl ? (
              <Button
                id="G-L-btn"
                type="primary"
                className="alpha-filter-btn alpha-filter"
                style={{ backgroundColor: "rgba(242, 119, 148, 0.7)" }}
                onClick={handleGL}
              >
                <span style={{ color: "white" }}>G-L</span>
              </Button>
            ) : (
              <Button
                id="G-L-btn"
                //  type="primary"
                className="alpha-filter-btn alpha-filter"
                style={{
                  // backgroundColor: "rgba(242, 119, 148, 0.7)",
                  backgroundColor: "#FE3464",
                  border: "1.5px solid #FE3464",
                }}
                onClick={handleGL}
              >
                <span style={{ color: "#fff", fontWeight: "bold" }}>G-L</span>
              </Button>
            )}
            {!boolms ? (
              <Button
                id="M-S-btn"
                type="primary"
                className="alpha-filter-btn alpha-filter"
                style={{ backgroundColor: "rgba(185, 230, 165, 0.7)" }}
                onClick={handleMS}
              >
                <span style={{ color: "white" }}>M-S</span>
              </Button>
            ) : (
              <Button
                id="M-S-btn"
                //  type="primary"
                className="alpha-filter-btn alpha-filter"
                style={{
                  // backgroundColor: "rgba(185, 230, 165, 0.7)",
                  backgroundColor: "#65AD45",
                  border: "1.5px solid #65AD45",
                }}
                onClick={handleMS}
              >
                <span style={{ color: "#fff", fontWeight: "bold" }}>M-S</span>
              </Button>
            )}
            {!booltz ? (
              <Button
                id="T-Z-btn"
                type="primary"
                className="alpha-filter-btn alpha-filter"
                style={{ backgroundColor: "rgba(114, 225, 213, 0.7)" }}
                onClick={handleTZ}
              >
                <span style={{ color: "white" }}>T-Z</span>
              </Button>
            ) : (
              <Button
                id="T-Z-btn"
                //  type="primary"
                className="alpha-filter-btn alpha-filter"
                style={{
                  backgroundColor: "#1EB8A7",
                  border: "1.5px solid #1EB8A7",
                }}
                onClick={handleTZ}
              >
                <span style={{ color: "#fff", fontWeight: "bold" }}>T-Z</span>
              </Button>
            )}
            <Filter
              setData={SetFilterValue}
              updateFilterData={updateFilterData}
              pageType="Viewpage"
              dynamicOrganisationTypes={dynamicOrganisationTypes}
              // organizationIds={organisationIds}
              selectedOrganisationType={selectedOrganisationType}
              setSelectedOrganisationType={setSelectedOrganisationType}
              setOrganisationNameById={setOrganisationNameById}
              selectedEvent={selectedEvent}
              setselectedEvent={setselectedEvent}
              className={
                "button-primary form-submit-btn text-regular filter-icon text-white"
              }
              // interest={selectedEvent}
              title={"Filter"}
            >
              <Form>
                {/* <div className="d-flex">
                  <Form.Item>
                    <label className="label-text">Organisation Type</label>
                    <Select
                      placeholder="Select Option"
                      value={selectedOrganisationType}
                      onChange={(e) => {
                        setSelectedOrganisationType(e);
                      }}
                    >
                      {organisationType?.map((Search, i) => (
                        <Select.Option key={i} value={organisationType[i]}>
                          {organisationType[i] === ""
                            ? "All"
                            : organisationType[i]}
                        </Select.Option>
                      ))}
                    </Select>
                    <div
                      className={`remove-btn ${
                        !selectedOrganisationType ||
                        (selectedOrganisationType &&
                          selectedOrganisationType == "")
                          ? "d-none"
                          : ""
                      }`}
                    >
                      <MinusCircleOutlined
                        onClick={() => deleteSelectedValue("organisationType")}
                      />
                    </div>
                  </Form.Item>
                </div> */}
                {/* <div className="d-flex">
                  <Form.Item>
                    <label className="label-text">Interests</label>
                    <Select
                      placeholder="Select Option"
                      value={selectedEvent}
                      onChange={(e) => setselectedEvent(e)}
                    >
                      {interest?.map((Search, i) => (
                        <Select.Option key={i} value={interest[i]}>
                          {interest[i] === ""
                            ? "All"
                            : interest[i] === "APPRENTICESHIP"
                            ? "Apprenticeships"
                            : interest[i] === "VACANCY"
                            ? "Vacancies"
                            : interest[i] === "PRODUCT"
                            ? "Products"
                            : interest[i] === "SERVICE"
                            ? "Services"
                            : interest[i] === "WORK_EXPERIENCE"
                            ? "Experiences of work"
                            : ""}
                        </Select.Option>
                      ))}
                    </Select>
                    <div
                      className={`remove-btn ${
                        !selectedEvent ||
                        (selectedEvent && selectedEvent === "")
                          ? "d-none"
                          : ""
                      }`}
                    >
                      <MinusCircleOutlined
                        onClick={() => deleteSelectedValue("interest")}
                      />
                    </div>
                  </Form.Item>
                </div> */}
              </Form>
            </Filter>
            <Button
              id="submit-btn"
              type="primary"
              htmlType="submit"
              className="view"
              onClick={viewHandler}
            >
              <span style={{ color: "white" }}>View</span>
              <div className="view-img">
                <img src={viewIcon} alt="main logo" />
              </div>
            </Button>
          </div>

          {/* Map component */}
          {markerData && (
            <div className="map-container">
              <MapComponent
                interest={selectedEvent}
                sector={sectorId}
                subsector={subsectorId}
                sizeId={size}
                radiusValue={radiusValue}
                ChangeRadiusHandler={ChangeRadiusHandler}
                setMarkerData={setMarkerData}
                markerData={markerData}
                selectedOrg={selectedOrg}
              // closePopup={closePopup}
              />
              {/* <Map /> */}
            </div>
          )}

          {/* Floating card component */}
          <motion.div
            className="card-container-view"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            {selectedMicrosite && cardState ? (
              <div className="previewCard">
                <Card
                  className="card-item home-page-card-item card-hover map-card"
                  // onClick={() => {
                  //   OnCardClickHandler(microsite.id, microsite.mediaLink);
                  // }}
                  // key={microsite.id}
                  bordered={false}
                >
                  <div className="image-container">
                    {selectedMicrosite?.carouselLink?.mediaType ===
                      "SOCIAL_MEDIA" && selectedMicrosite?.carouselLink?.url ? (
                      <>
                        <div
                          className="media youtube"
                          style={{ objectFit: "contain" }}
                          onClick={() =>
                            PlayVideoHandler(
                              selectedMicrosite?.carouselLink?.url,
                              selectedMicrosite?.carouselLink?.mediaType
                            )
                          }
                        >
                          {(() => {
                            const standardURL = convertToStandardURL(
                              selectedMicrosite?.carouselLink?.url
                            );
                            const videoID = new URLSearchParams(
                              new URL(standardURL).search
                            ).get("v");
                            return (
                              <>
                                <img
                                  src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                                  style={{ height: "90%", borderRadius: "8px" }}
                                  alt=""
                                  className="video-preview-image"
                                />
                                <img
                                  className="ytBtnOverlay"
                                  src={ytPlayBtn}
                                  onClick={() =>
                                    PlayVideoHandler(
                                      selectedMicrosite?.carouselLink?.url,
                                      selectedMicrosite?.carouselLink?.mediaType
                                    )
                                  }
                                />
                              </>
                            );
                          })()}
                        </div>
                      </>
                    ) : selectedMicrosite?.carouselLink?.mediaType ===
                      "VIMEO_MEDIA" &&
                      selectedMicrosite?.carouselLink?.url ? (
                      <div
                        className="media vimeo youtube"
                        onClick={() => {
                          PlayVideoHandler(
                            selectedMicrosite?.carouselLink?.url,
                            selectedMicrosite?.carouselLink?.mediaType
                          );
                        }}
                      >
                        <img
                          src={`https://vumbnail.com/${selectedMicrosite?.carouselLink?.url?.split("/")[4]
                            }.jpg`}
                          className="video-preview-image"
                          style={{ height: "90%", borderRadius: "8px" }}
                        />
                        <img className="ytBtnOverlay" src={ytPlayBtn} />
                      </div>
                    ) : (
                      <div className="media">
                        <div className="slider-Img">
                          <img
                            src={selectedMicrosite?.carouselLink?.url}
                            alt=""
                            className="home-preview-img"
                            style={{ height: "90%", borderRadius: "8px" }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="text-container"
                  // onClick={() => {
                  //   OnCardClickHandler(selectedMicrosite?.organizationId, selectedMicrosite?.mediaLink, selectedMicrosite?.organizationName);
                  // }}
                  >
                    <span className="microsite-position text-bold">
                      <img
                        src={selectedMicrosite?.micrositeLogo}
                        alt={selectedMicrosite?.name}
                        className="preview-image"
                        onClick={() => {
                          OnCardClickHandler(
                            selectedMicrosite?.id,
                            selectedMicrosite?.micrositeLogo,
                            selectedMicrosite?.name
                          );
                        }}
                      />
                    </span>
                    <CloseOutlined
                      className="closeIcon"
                      onClick={() => setCardState(false)}
                    />
                    <div
                      className="sector-info-container"
                      onClick={() => {
                        OnCardClickHandler(
                          selectedMicrosite?.id,
                          selectedMicrosite?.micrositeLogo,
                          selectedMicrosite?.name
                        );
                      }}
                    >
                      <span className="microsite-details text-bold">
                        {/* {microsite.type} */}
                        {/* {microsite.type === "EDUCATION_SECTOR"
                          ? "Education"
                          : microsite.type === "PUBLIC_SECTOR"
                            ? "Public"
                            : microsite.type === "BUSINESS_SECTOR"
                              ? "Business"
                              : ""} */}
                        {selectedMicrosite?.businessDescription}
                      </span>
                      {/* <span className="microsite-details text-bold">
                        {microsite?.sectorsOutputs[0]?.sectorOutput.name}
                      </span> */}
                    </div>
                  </div>
                </Card>
              </div>
            ) : (
              <></>
            )}
          </motion.div>
          {isModalOpen ? (
            <ModalPlayer
              closeModal={handleCancel}
              mediaLink={videoUrl}
              mediaType={mediatype}
            />
          ) : (
            ""
          )}
        </Content>

        {/* zoom footer */}

        {/* <Footer className="page-footer">
          <div className="first-row">
            <div>2 Miles</div>
            <div>5 Miles</div>
            <div>15 Miles</div>
            <div>25 Miles</div>
          </div>
          <div>
            <hr className="line" />
            <img
              src={FiledCircle}
              alt="Filled Circle"
              className="filled-circle"
              // style={{ left: CurrentModePercentage(radiusValue) }}
              style={{ left: zoomPercentage(selectedZoom) }}
            />
          </div>
          <div className="last-row">
            <div className="footer-icons">
              <img
                src={require(`../../assets/images/${selectedZoom === 2 ? "filledwalk" : "walk"
                  }.svg`)}
                alt="main logo"
                // onClick={ChangeModeHandler.bind(this, 14)}
                onClick={() => ChangeModeHandler(2)}
              />
            </div>
            <div className="footer-icons">
              {" "}
              <img
                src={require(`../../assets/images/${selectedZoom === 5 ? "filledcycle" : "bicycle"
                  }.svg`)}
                alt="main logo"
                // onClick={ChangeModeHandler.bind(this, 13)}
                onClick={() => ChangeModeHandler(5)}
              />
            </div>
            <div className="footer-icons">
              {" "}
              <img
                src={require(`../../assets/images/${selectedZoom === 15 ? "filledcar" : "car"
                  }.svg`)}
                alt="main logo"
                // onClick={ChangeModeHandler.bind(this, 12)}
                onClick={() => ChangeModeHandler(15)}
              />
            </div>
            <div className="footer-icons train">
              {" "}
              <img
                src={require(`../../assets/images/${selectedZoom === 25 ? "filledTrain" : "train"
                  }.svg`)}
                // onClick={ChangeModeHandler.bind(this, 11)}
                onClick={() => ChangeModeHandler(25)}
              />
            </div>
          </div>
        </Footer> */}

      </Layout>
    );
  }
}
