import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Modal, Select, Radio, Row, Col, Input } from "antd";
import closeIcon from "../../assets/images/closecross.svg";
import "../editProfile/EditProfile.scss";
import { getRequest, putRequest, postRequest } from "../../api/http";
import { toast } from "react-toastify";
import userapiservice from "../../api/userapiservice";
import SearchBarHelp from "../login/searchHelp";
import username from "../../assets/images/username-profile.svg";
import phoneProfile from "../../assets/images/phone-profile.svg";
import mail from "../../assets/images/mail-profile.svg";
import ethnicityIcon from "../../assets/images/ethnicity-profile.svg";
import ageIcon from "../../assets/images/age-profile.svg";
import proftag from "../../assets/images/tag-profile.svg";
import gender from "../../assets/images/gender-profile.svg";

export default function EditProfilePage() {
  // console.log()
  const navigate = useNavigate();
  // console.log("onentry", show);
  const [form] = Form.useForm();
  const [age, setAge] = useState(
    localStorage.getItem("age") ? localStorage.getItem("age") : ""
  );
  const [profileData, setProfileData] = useState();
  const [ageGroups, setAgeGroups] = useState([]);
  const [ageGroupData, setAgeGroupsData] = useState([]);
  const [selectedAgeGroupsData, setSelectedAgeGroupsData] = useState();
  const [ethnicity, setEthnicity] = useState();
  const [viewProfile, setViewProfile] = useState(true);

  const [showAlert, setShowAlert] = useState(false);
  const [isActive, setActive] = useState("false");
  // const [userId, setUserId] = useState();

  const getProfileData = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      const response = await getRequest(
        userapiservice.getEndUserDetails(),
        headers
      );
      if (response) {
        setProfileData(response);
        // setUserId(response.id);
        if (response?.ageGroup) {
          getAgeData(response?.ageGroup?.id);
          setSelectedAgeGroupsData(response?.tag?.id)
        }
      }
    } catch (e) {
      Modal.error({
        title: "profile Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  const getAgeGroups = async () => {
    try {
      const response = await getRequest(userapiservice.getEndUserAgeGroups());
      setAgeGroups(response);
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  const getAgeData = async (value) => {
    try {
      const response = await getRequest(userapiservice.getWhatDoYouDo(value));
      setAgeGroupsData(response);
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };
  useEffect(() => {
    form.resetFields();
  }, [profileData]);

  const submitHandler = async (data) => {
    // console.log('data', data)
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    try {
      const response = await putRequest(
        userapiservice.getEndUserDetails(),
        data,
        headers
      );
      // console.log("data sent", data);
      if (response) {
        const AGEname =
          ageGroups?.find((ageGroup) => ageGroup.id === data.ageId)
            ?.ageGroup || "";
        setLocalAge(AGEname, data.ageId);
        localStorage.setItem("profID", data.tagId);
        const name =
          ageGroupData?.find((tagObj) => tagObj?.tag?.id === data.tag)?.tag
            ?.userTag || "";
        localStorage.setItem("prof", name);
        getAgeGroups();
        getProfileData();
        setViewProfile(true);
        toast.success("Profile Updated Successfully");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const ageGroupDataChange = async (groupData) => {
    setSelectedAgeGroupsData(groupData);
  };

  const toggleClass = () => {
    setActive(!isActive);
  };

  const setLocalAge = (value, id) => {
    if (value === "< 18") {
      setAge(18);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 18);
    } else if (value === "19 - 23") {
      setAge(23);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 23);
    } else if (value === "24 - 37") {
      setAge(37);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 37);
    } else if (value === "38 - 53") {
      setAge(53);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 53);
    } else if (value === "54+") {
      setAge(53);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 53);
    }
  };

  const setEthnicityHandler = (e) => {
    // console.log(e)
    setEthnicity(e);
  };

  const handleEditDetailsClick = async () => {
    getAgeGroups();
    await getProfileData();
    setViewProfile(false);
  };

  useEffect(() => {
    getAgeGroups();
    getProfileData();
  }, []);

  return (
    <>
      {viewProfile ? (
        <SearchBarHelp viewProfile="View Profile" />
      ) : (
        <SearchBarHelp viewProfile="Edit Profile" />
      )}
      {viewProfile && (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginRight: "20px",
          }}
        >
          <Button className="greenBtn" onClick={handleEditDetailsClick}>
            Edit Details
          </Button>
        </div>
      )}
      {/* <SearchBarHelp viewProfile="Edit Profile" /> */}
      <div className="profile-card-container">
        {viewProfile ? (
          <div className="partner-details">
            <div className="col-1 w-100" style={{ width: '100%' }} >
              <Row span={24} className="w-100" >
                <Col
                  className="gutter-row partner-info-label"
                  xs={10} md={6}
                >
                  <img src={username} alt="username"></img>
                  <label>Username:</label>
                </Col>
                <Col
                  className="gutter-row partner-info-value"
                >
                  <p>{profileData?.userName ? profileData?.userName : ""}</p>
                </Col>
              </Row>
              <Row span={24} className="w-100" >
                <Col
                  className="gutter-row partner-info-label"
                  xs={10} md={6}
                >
                  <img src={mail} alt="mail"></img>
                  <label>Email:</label>
                </Col>
                <Col
                  className="gutter-row partner-info-value"
                >
                  <p>{profileData?.email ? profileData?.email : ""}</p>
                </Col>
              </Row>
              <Row span={24} className="w-100" >
                <Col
                  className="gutter-row partner-info-label"
                  xs={10} md={6}
                >
                  <img src={phoneProfile} alt="phone"></img>
                  <label>Phone:</label>
                </Col>
                <Col
                  className="gutter-row partner-info-value"
                >
                  <p>
                    {profileData?.phoneNumber ? profileData?.phoneNumber : ""}
                  </p>
                </Col>
              </Row>
              <Row span={24} >
                <Col
                  className="gutter-row partner-info-label"
                  xs={10} md={6}
                >
                  <img src={ageIcon} alt="age"></img>
                  <label>Age:</label>
                </Col>
                <Col
                  className="gutter-row partner-info-value"
                  xs={10} md={6}
                >
                  <p>
                    {profileData?.ageGroup?.ageGroup
                      ? profileData?.ageGroup?.ageGroup
                      : ""}
                  </p>
                </Col>
              </Row>
              {profileData?.tag?.id && (
                <Row span={24} >
                  <Col
                    className="gutter-row partner-info-label"
                    xs={10} md={6}
                  >
                    <img src={proftag} alt="proftag"></img>
                    <label>Tag:</label>
                  </Col>
                  <Col
                    className="gutter-row partner-info-value"
                  >
                    <p>
                      {profileData?.tag?.userTag
                        ? profileData?.tag?.userTag
                        : ""}
                    </p>
                  </Col>
                </Row>
              )}
              {profileData?.gender && (
                <Row span={24} >
                  <Col
                    className="gutter-row partner-info-label"
                    xs={10} md={6}                  >
                    <img src={gender} alt="gender"></img>
                    <label>Gender:</label>
                  </Col>
                  <Col
                    className="gutter-row partner-info-value"
                  >
                    <p>{profileData?.gender ? profileData?.gender : ""}</p>
                  </Col>
                </Row>
              )}
              {profileData?.ethnicity && (
                <Row span={24} >
                  <Col
                    className="gutter-row partner-info-label"
                    xs={10} md={6}
                  >
                    <img src={ethnicityIcon} alt="ethnicity"></img>
                    <label>Ethnicity:</label>
                  </Col>
                  <Col
                    className="gutter-row partner-info-value"
                  >
                    <p>
                      {profileData?.ethnicity ? profileData?.ethnicity : ""}
                    </p>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        ) : (
          <div style={{ margin: "5px", marginTop: '10px', paddingLeft: '5px', paddingRight: '5px' }}>
            <Row xs={24} md={18} >
              <Col xs={24} md={18}>
                {Object.keys(profileData).length > 0 ? (
                  <Form
                    name="editForm"
                    form={form}
                    onFinish={submitHandler}
                    className="ProfileForm"
                    initialValues={{
                      email: profileData && profileData?.email,
                      userName: profileData && profileData?.userName,
                      ageId: profileData && profileData?.ageGroup?.id,
                      gender: profileData && profileData?.gender,
                      ethnicity: profileData && profileData?.ethnicity,
                      // tagId: profileData && profileData?.tagId
                      tagId: selectedAgeGroupsData,
                    }}
                  >
                    <Col span={24}>
                      <Form.Item
                        name="userName"
                        label={
                          <div className="style-label">
                            <img src={username} alt="username"></img>
                            <label>Username</label>
                          </div>
                        }
                        className="form-item-edit"
                        colon={false}
                        labelCol={{ span: window.innerWidth > 380 ? 13 : 7 }}
                        validateFirst={true}
                        rules={[
                          { required: true, message: "Username is required" },
                        ]}
                      >
                        <Input placeholder="UserName" />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        label={
                          <div className="style-label">
                            <img src={mail} alt="mail"></img>
                            <label>Email</label>
                          </div>
                        }
                        className="form-item-edit"
                        colon={false}
                        labelCol={{ span: window.innerWidth > 380 ? 13 : 7 }}
                        validateFirst={true}
                        rules={[
                          { required: true, message: "Email is required" },
                          { type: "email", message: "Enter valid email" },
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <Form.Item
                        label={
                          <div className="style-label">
                            <img src={ageIcon} alt="age"></img>
                            <label>Age</label>
                          </div>
                        }
                        colon={false}
                        className="form-item-edit"
                        labelCol={{ span: window.innerWidth > 380 ? 13 : 7 }}
                        rules={[{ required: true, message: "Age is required" }]}
                        name="ageId"
                      >
                        <Radio.Group
                          className="age-radio"
                          // defaultValue="a"
                          defaultValue="24 - 37"
                          // size="small"
                          buttonStyle="solid"
                          style={{
                            display: "block",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          {ageGroups && ageGroups.length > 0
                            ? ageGroups.slice(1).map((ageGroup) => (
                              <Radio.Button
                                value={ageGroup?.id}
                                onClick={() => {
                                  getAgeData(ageGroup?.id);
                                  toggleClass();
                                  setLocalAge(ageGroup.ageGroup, ageGroup.id);
                                  form.setFieldValue('tagId', null)
                                }}
                                onChange={() => {
                                  setSelectedAgeGroupsData();
                                }}
                              >
                                {ageGroup?.ageGroup}
                              </Radio.Button>
                            ))
                            : ""}
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        label={
                          <div className="style-label">
                            <img src={gender} alt="gender"></img>
                            <label>Gender</label>
                          </div>
                        }
                        className="form-item-edit"
                        colon={false}
                        labelCol={{ span: window.innerWidth > 380 ? 13 : 7 }}
                        name="gender"
                      >
                        <Select>
                          <Select.Option value="Male">Male</Select.Option>
                          <Select.Option value="Female">Female</Select.Option>
                          <Select.Option value="Others">Others</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={
                          <div className="style-label">
                            <img src={ethnicityIcon} alt="ethnicity"></img>
                            <label>Ethnicity</label>
                          </div>
                        }
                        className="form-item-edit"
                        colon={false}
                        labelCol={{ span: window.innerWidth > 380 ? 13 : 7 }}
                        name="ethnicity"
                      >
                        <Select
                          value={ethnicity}
                          onChange={(e) => setEthnicityHandler(e)}
                        >
                          <Select.Option value="Indian">Indian</Select.Option>
                          <Select.Option value="American">
                            American
                          </Select.Option>
                          <Select.Option value="British">British</Select.Option>
                          <Select.Option value="African">African</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={
                          <div className="style-label">
                            <img src={proftag} alt="prof"></img>
                            <label>What do you do?</label>
                          </div>
                        }
                        className="form-item-edit"
                        colon={false}
                        // initialValue={
                        //   localStorage.getItem("profID")
                        //     ? localStorage.getItem("profID")
                        //     : selectedAgeGroupsData
                        // }
                        rules={[
                          {
                            required: true,
                            message: "What do you do? is required",
                          },
                        ]}
                        labelCol={{ span: window.innerWidth > 380 ? 13 : 7 }}
                        name="tagId"
                      >
                        <Select
                          className="customSelect"
                          value={selectedAgeGroupsData}
                          onChange={(e) => ageGroupDataChange(e)}
                        >
                          {ageGroupData &&
                            ageGroupData?.map((ageGroupData, i) => (
                              <Select.Option
                                key={i}
                                value={ageGroupData.tag.id}
                              >
                                {ageGroupData.tag.userTag}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <div className="form-btn-container">
                        <Form.Item>
                          <Button
                            className="profile-cancel-btn"
                            onClick={() => {
                              setViewProfile(true);
                            }}
                          >
                            Cancel
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            className="profile-submit-btn"
                            htmlType="submit"
                            type="primary"
                          >
                            Save
                          </Button>
                        </Form.Item>
                      </div>
                    </Col>
                  </Form>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
        )}
        {showAlert && (
          <div className="alert-container">
            <label
              className="age-alert"
              style={{ color: "red", width: "100%" }}
            >
              Please set age!
            </label>
          </div>
        )}
      </div>
    </>
  );
}
