import React, { useEffect, useState } from "react";
import { Input, Modal, Dropdown } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import useComponentVisible from "../login/useComponentVisible";
import OrganisationLogin from "../../assets/images/organisationLogin.svg";
import userLogin from "../../assets/images/userLogin.svg";
import userLogout from "../../assets/images/userLogout.svg";
import helpIcon from "../../assets/images/helpIcon.svg";
import feedbackIcon from "../../assets/images/feedback.svg";
import "./searchBar.scss";
import ProfileDropdown from "../../components/ProfileDropdown/ProfileDropdown";
import userapiservice from "../../api/userapiservice";
import { getRequest } from "../../api/http";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Leftarrow from "../../assets/images/Leftarrow.svg";
import EditProfileLogoBlue from "../../assets/images/EditProfileIconBlue.svg";
import EditProfilePage from "../../screens/editProfile/EditProfile";

export default function FavSearchBar({ GetValueFromSearch }) {
  const page = localStorage.getItem("Page");
  const accessToken = localStorage.getItem("accessToken");
  // console.log(page)
  const age = localStorage.getItem("age");
  const phoneNumber = localStorage.getItem("Phone Number");

  const [isShowProfile, setIsShowProfile] = useState(false);
  const { setIsComponentVisible } = useComponentVisible(true, setIsShowProfile);
  const [organizationName, setOrganisationName] = useState();
  const [loading, setLoading] = useState(true);
  const [partnerLogo, setPartnerLogo] = useState(null);
  const { Search } = Input;
  const [showEditProfile, setShowEditProfile] = useState(false);

  const partner = localStorage.getItem("partner");

  const showProfile = () => {
    setIsShowProfile(!isShowProfile);
    setIsComponentVisible(true);
  };

  const searchOrganisationData = async (name) => {
    // console.log(organizationName,"orggg")
    try {
      setLoading(true);
      // const headers = {
      //   Authorization: `Bearer ${localStorage.getItem("token")}`,
      // };

      const response = await getRequest(
        userapiservice.filterFavorites(
          partner,
          phoneNumber,
          "",
          "",
          "",
          "",
          "",
          name
        ),
        name
      );

      setOrganisationName(response.result);
      GetValueFromSearch(response.result);
      setLoading(false);
      // setTimeout(() => {
      //   setOpen(false);
      //   setConfirmLoading(false);
      // }, 2000);
    } catch (error) {
      toast.error(error.error);
      setLoading(false);
    }
  };
  const navigate = useNavigate();

  const BackButtonHandler = () => {
    if (page === "view") {
      navigate("/home");
    } else if (page === "home") {
      navigate(`/`);
    } else if (page === "favorites") {
      navigate("/home");
    }
  };

  const closeDropdown = () => {
    setIsShowProfile(!isShowProfile);
  };

  const getPartnerLogo = async () => {
    try {
      const response = await getRequest(
        userapiservice.getPartnerDetails(partner)
      );
      // console.log(response)
      setPartnerLogo(response);
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };


  const editProfileClickHandler = () => {
    // console.log('check');
    setShowEditProfile(true);
  };

  useEffect(() => {
    setOrganisationName(organizationName);
  }, [organizationName]);

  useEffect(() => {
    if (partner) {
      getPartnerLogo();
    }
  }, []);
  const onSearch = (event) => {
    // console.log("search", event)
    if (!event.target.value) {
      // console.log("empty")
      searchOrganisationData();
    } else {
      searchOrganisationData(event.target.value);
    }
  };

  const LogoutHandler = () => {
    localStorage.removeItem("_grecaptcha");
    localStorage.removeItem("age");
    localStorage.removeItem("Phone Number");
    localStorage.removeItem("tabValue");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("sector");
    localStorage.removeItem("subsector");
    localStorage.removeItem("size");
    localStorage.removeItem("Interest/AssetType");
    localStorage.removeItem("zoom");
    localStorage.removeItem("lat");
    localStorage.removeItem("lng");
    localStorage.removeItem("prof");
    localStorage.removeItem("micrositeID");
    localStorage.removeItem("micrositeName");
    localStorage.removeItem("ageID");
    localStorage.removeItem("searchName");
    localStorage.removeItem("OrganisationType");
    navigate(`/`);
  };

  return (
    <div className="search-bar">
      <div className="back-btn" type="link" onClick={BackButtonHandler}>
        {/* <ArrowLeftOutlined
            style={{
              color: "#828282",
              fontSize: "1.15em",
              transform: "scaleX(1.2)",
              height: "50%"
            }}
          /> */}
        <img src={Leftarrow} alt="" />
      </div>
      <div className="header-logo">
        <img src={partnerLogo?.mediaLink} alt="" />
      </div>

      {/* {(page === "home") ? ( */}
      <div className="search-input-container">
        {/* <Input placeholder="Search for jobs" className="jobs-earch-input" />
        <AudioFilled className="mic-icon" /> */}
        <Search
          placeholder="Search Microsite"
          onChange={(e) => onSearch(e)}
          enterButton
        />
      </div>
      {/* ) : (
         <div className="search-input-container">
         </div>
       )} */}
      <div className="org-menu-btn">
        {page === "home" ? (
          <div></div>
        ) : (
          // <Button className="search-btn org-btn" type="primary" htmlType="submit" onClick={() => navigate("/onboarding")}>
          //   <img src={plusicon} />
          //   <span style={{ color: "white" }}>Organisation</span>
          // </Button>
          ""
        )}
        {/* </a> */}

        {/* <button className="drop-btn" onClick={showProfile}><MenuUnfoldOutlined /></button> */}
        <div>
          <Dropdown
            trigger={["click"]}
            placement="bottomRight"
            overlay={
              <div
                className="dropdown"
                style={{
                  border: "1px solid #569dfa",
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                  width: "190px",
                }}
              >
                <div
                  style={{
                    padding: "10px",
                    borderBottom: "1px solid #f0f0f0",
                    cursor: "pointer",
                  }}
                >
                  <a
                    href={`https://orgadmin.expo365.app/login/${localStorage.getItem("partnerId")}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "black", cursor: "pointer" }}
                  >
                    <div
                      className="dropdown-items"
                      style={{
                        display: "flex",
                        alignContent: "center",
                      }}
                    >
                      <img src={OrganisationLogin} alt="main logo" />
                      <label style={{ marginLeft: "10px" }}>
                        Organisation Login
                      </label>
                    </div>
                  </a>
                </div>

                <div
                  style={{
                    padding: "10px",
                    borderBottom: "1px solid #f0f0f0",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className="dropdown-items"
                    style={{
                      display: "flex",
                      alignContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/help")}
                  >
                    <img src={helpIcon} alt="main logo" />
                    <label style={{ marginLeft: "10px" }}>Help</label>
                  </div>
                </div>
                {age === "23" || age === "37" || age === "53" ? (
                  <div
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #f0f0f0",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="dropdown-items"
                      style={{
                        display: "flex",
                        alignContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/feedback");
                        localStorage.setItem("feedback", "feedback");
                      }}
                    >
                      <img src={feedbackIcon} alt="main logo" />
                      <label style={{ marginLeft: "10px" }}>
                        Support & Feedback
                      </label>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div
                  style={{
                    // paddingLeft: "0px",
                    padding: "10px",
                    borderBottom: "1px solid #f0f0f0",
                    cursor: "pointer",
                  }}
                >
                  {phoneNumber && accessToken ? (
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignContent: "center",
                        marginLeft: 5,
                      }}
                      onClick={() => navigate("/edit")}
                      className="dropdown-items"
                    >
                      <img src={EditProfileLogoBlue} style={{ height: 24 }} />
                      <div style={{ marginLeft: "10px", cursor: "pointer" }}>
                        Edit profile
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignContent: "center",
                        marginLeft: 5,
                      }}
                      onClick={() =>
                        toast.error("Please login to access edit profile")
                      }
                      className="dropdown-items"
                    >
                      <img src={EditProfileLogoBlue} style={{ height: 24 }} />
                      <div style={{ marginLeft: "10px", cursor: "pointer" }}>
                        Edit profile
                      </div>
                    </div>
                  )}
                </div>

                {!phoneNumber ? (
                  <div
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #f0f0f0",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="dropdown-items"
                      style={{
                        display: "flex",
                        alignContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        navigate("/login", { state: { page: "home" } })
                      }
                    >
                      <img src={userLogin} alt="main logo" />
                      <label style={{ marginLeft: "10px" }}>User Login</label>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div
                  style={{
                    padding: "10px",
                    borderBottom: "1px solid #f0f0f0",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className="share-dropdown"
                    style={{
                      display: "flex",
                      alignContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={LogoutHandler}
                  >
                    <img src={userLogout} alt="main logo" />
                    <label style={{ marginLeft: "10px" }}>User Logout</label>
                  </div>
                </div>
              </div>
            }
          >
            <div className="drop-btn">
              <MenuUnfoldOutlined />
            </div>
          </Dropdown>
        </div>

        {isShowProfile ? (
          <div>
            <ProfileDropdown closeDropdown={closeDropdown} />
          </div>
        ) : null}
        {/* </button> */}
      </div>
    </div>
  );
}
