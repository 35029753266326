import React, { useState } from "react";
import Slider from "react-slick";
import "../Slider/slider.scss"
import { motion } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Carousel(props) {

    const [arrows, setArrows] = useState(true);
    // console.log("slider", props)
    const settings = {
        className: "center",
        centerMode: window.innerWidth >= 768 ? true : false,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 1,
        slideToScroll: 0.5,
        autoplay: false,
        autoplaySpeed: 0,
        dots: false,
        arrows: arrows,
        swipeToSlide: true,
        swipe: arrows,
        fade: false,
    };

    function getVideoId(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url?.match(regExp);

        return (match && match[2].length === 11)
            ? match[2]
            : null;
    }

    return (
        <motion.div className="custom-slider-container" initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
                duration: 0.8,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01]
            }}>
            <Slider {...settings}>
                {props.carouselData &&
                    props.carouselData.map((data, i) => {
                        if (
                            data?.mediaLink?.mediaType === "SOCIAL_MEDIA" &&
                            data.mediaLink.url
                        ) {
                            return (
                                <div className="slide">
                                    <iframe
                                        title="youtube"
                                        loading="lazy"
                                        src={`https://www.youtube.com/embed/${getVideoId(data.mediaLink.url)}`}
                                        onPlay={() => setArrows(false)}
                                        onPause={() => setArrows(true)}
                                        frameBorder="0"
                                        width="100%"
                                        style={{ backgroundColor: "black" }}
                                        srcDoc={`<style>
                                        * {
                                        padding: 0;
                                        margin: 0;
                                        overflow: hidden;
                                        }
                                        
                                        body, html {
                                            height: 100%;
                                        }
                                        
                                        img, svg {
                                            position: absolute;
                                            width: 100%;
                                            top: 0;
                                            bottom: 0;
                                            margin: auto;
                                        }
                                        
                                        svg {
                                            filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
                                            transition: all 250ms ease-in-out;
                                        }
                                        
                                        body:hover svg {
                                            filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
                                            transform: scale(1.2);
                                        }
                                        </style>
                                        <a href='https://www.youtube.com/embed/${getVideoId(data.mediaLink.url)}?autoplay=1'>
                                        <img src='https://img.youtube.com/vi/${getVideoId(data.mediaLink.url)}/hqdefault.jpg' alt='Coffee Recipe Javascript Project'>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 24 24' fill='none' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-play-circle'><circle cx='12' cy='12' r='10'></circle><polygon points='10 8 16 12 10 16 10 8'></polygon></svg>
                                        </a>
                                        `}
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    />
                                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                        <label className="slider-label">{data.title}</label>
                                    </div>
                                </div>
                            );
                        } else if (
                            data?.mediaLink?.mediaType === "VIMEO_MEDIA" &&
                            data.mediaLink.url
                        ) {
                            return (
                                <div className="slide">
                                    <iframe
                                        title="vimeo"
                                        id="myvideo"
                                        frameBorder="0"
                                        loading="lazy"
                                        controls={true}
                                        src={data.mediaLink.url}
                                        onPlay={() => setArrows(false)}
                                        onPause={() => setArrows(true)} //Not working
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        width="100%"
                                        style={{ backgroundColor: "black" }}
                                        srcDoc={`<style>
                                        * {
                                        padding: 0;
                                        margin: 0;
                                        overflow: hidden;
                                        }
                                        
                                        body, html {
                                            height: 100%;
                                        }
                                        
                                        img, svg {
                                            position: absolute;
                                            width: 100%;
                                            top: 0;
                                            bottom: 0;
                                            margin: auto;
                                        }
                                        
                                        svg {
                                            filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
                                            transition: all 250ms ease-in-out;
                                        }
                                        
                                        body:hover svg {
                                            filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
                                            transform: scale(1.2);
                                        }
                                        </style>
                                        <a href='${data.mediaLink.url}'>
                                        <img src='https://vumbnail.com/${data.mediaLink.url?.split("/")[4]
                                            }.jpg' alt=''>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 24 24' fill='none' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-play-circle'><circle cx='12' cy='12' r='10'></circle><polygon points='10 8 16 12 10 16 10 8'></polygon></svg>
                                        </a>
                                        `}
                                        allowFullScreen
                                    />
                                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                        <label className="slider-label">{data.title}</label>
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <div className="slide">
                                    <div className="img-slide" >
                                        <img
                                            src={data?.mediaLink?.url}
                                            alt=""
                                            className="carousel-image"
                                            style={{ backgroundColor: 'white' }}
                                        />
                                    </div>
                                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                        <label className="slider-label" style={{ marginTop: "4px" }}>{data.title}</label>
                                    </div>
                                </div>
                            );
                        }
                        // }
                    })
                }
            </Slider >
        </motion.div >
    );
}