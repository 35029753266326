import React, { useState, useEffect } from "react";
import { Spin, Tabs } from "antd";
import AboutUsDetails from "./aboutUsSubTabs/AboutUs";
import Accreditation from "./aboutUsSubTabs/Accreditation";
import OurStory from "./aboutUsSubTabs/OurStory";
import OurTeam from "./aboutUsSubTabs/OurTeam";
export default function AboutUsComponent({ ChangeShowDetailHandler }) {
  const [loading, setLoading] = useState(false);
  const [aboutUsKey, setAboutUsKey] = useState('ABOUT_US');

  useEffect(() => {
    let tabVal = localStorage.getItem("aboutUsKey");
    if (tabVal) {
      setAboutUsKey(tabVal);
    }
  }, []);

  const changeAboutUsTab = (tabValue) => {
    setAboutUsKey(tabValue)
    localStorage.setItem("aboutUsKey", tabValue)
  }

  if (loading) {
    return (
      <div className="loader">
        <Spin tip="Loading" size="large" />
      </div>
    )
  } else {
    return (
      <div className="background-container" style={{ width: "100%" }}>
        <Tabs
          onChange={changeAboutUsTab}
          activeKey={aboutUsKey}
          destroyInactiveTabPane
        >
          <Tabs.TabPane tab="Our Purpose" key="ABOUT_US">
            <div style={{ marginTop: '10px' }} >
              <AboutUsDetails activeKey={aboutUsKey} />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Our Story" key="OUR_STORY">
            <div style={{ marginTop: '10px' }} >
              <OurStory activeKey={aboutUsKey} />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Our Team" key="OUR_TEAM">
            <div style={{ marginTop: '10px' }} >
              <OurTeam activeKey={aboutUsKey} />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Our Client" key="OUR_CLIENT">
            <div style={{ marginTop: '10px' }} >
              <OurTeam activeKey={aboutUsKey} />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Accreditations" key="ACCREDITATIONS_AWARDS">
            <div style={{ marginTop: '10px' }} >
              <Accreditation activeKey={aboutUsKey} />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
}
