import { Button, Modal, Select, Radio } from "antd";
import { useEffect, useState } from "react";
import { Form } from "antd";
import { getRequest } from "../../api/http";
import userapiservice from "../../api/userapiservice";
import closeIcon from "../../assets/images/closecross.svg";
import { useNavigate } from "react-router-dom";
import "../editProfile/profile.scss";

// export default function Profile(id, interest,) {
export default function Profile({ closeModal }) {
  // console.log()
  const navigate = useNavigate();
  // console.log("onentry", show);
  const [form] = Form.useForm();
  const [age, setAge] = useState(
    localStorage.getItem("age") ? localStorage.getItem("age") : ""
  );
  const [prof, setProf] = useState(
    localStorage.getItem("profID") ? localStorage.getItem("profID") : ""
  );
  const [ageGroups, setAgeGroups] = useState([]);
  const [ageGroupData, setAgeGroupsData] = useState([]);
  const [selectedAgeGroupsData, setSelectedAgeGroupsData] = useState();
  const [firstValue, setFirstValue] = useState();

  const [showAlert, setShowAlert] = useState(false);
  const [profAlert, setProfAlert] = useState(false);
  const [isActive, setActive] = useState("false");

  const [isDropdownDisabled, setisDropdownDisabled] = useState(true);

  const handleCancel = () => {
    closeModal();
    setAge(null);
    localStorage.removeItem("age");
    localStorage.removeItem("ageID");
    localStorage.removeItem("profID");
    localStorage.removeItem("ageName");
    // console.log("onclose", open)
    // setShowAlert(false);
  };
  const handleOk = () => {
    // age ? navigate("/home") : setShowAlert(true);
    // age && prof ? navigate("/view") : setShowAlert(true);
    age && prof ? navigate("/view") : age && !prof ? setProfAlert(true) : setShowAlert(true)
  };

  const getAgeGroups = async () => {
    try {
      const response = await getRequest(userapiservice.getEndUserAgeGroups());
      // console.log("age", response);
      setAgeGroups(response);
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  const getAgeData = async (value) => {
    try {
      const response = await getRequest(userapiservice.getWhatDoYouDo(value));
      // console.log("agedata", response);
      setAgeGroupsData(response);
      setFirstValue(response[0].tag.userTag);
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  const ageGroupDataChange = (groupData) => {
    setSelectedAgeGroupsData(groupData);
    // console.log("data", groupData)
    localStorage.setItem("prof", groupData);
  };

  const toggleClass = () => {
    setActive(!isActive);
  };

  const setLocalAge = (value, id) => {
    if (value === "< 18") {
      setAge(18);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 18);
    } else if (value === "19 - 23") {
      setAge(23);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 23);
    } else if (value === "24 - 37") {
      setAge(37);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 37);
    } else if (value === "38 - 53") {
      setAge(53);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 53);
    } else if (value === "54+") {
      setAge(53);
      localStorage.setItem("ageID", id);
      localStorage.setItem("ageName", value);
      localStorage.setItem("age", 53);
    }
  };

  useEffect(() => {
    getAgeGroups();
  }, []);

  return (
    <>
      <Modal
        title="Create my profile"
        open={true}
        onCancel={handleCancel}
        closeIcon={<img className="closeIcon" src={closeIcon} />}
        okText="Apply"
        className="modal-container profile-modal-container"
        footer={[
          <Button
            className="profile-submit-btn"
            type="primary"
            htmlType="submit"
            // key="submit"
            // loading={loading}
            onClick={handleOk}
          >
            Explore
          </Button>,
        ]}
      >
        <div
          className="radio-group-space"
          direction="vertical"
        // style={{ display: "flex" }}
        >
          <label>Select your age </label>
          <Radio.Group
            className="age-radio"
            // defaultValue="a"
            // defaultValue="24 - 37"
            // defaultChecked="true"
            // size="small"
            buttonStyle="solid"
            style={{
              // marginBottom: 16,
              display: "block",
              textAlign: "center",
              width: "100%",
            }}
          >
            <div className="create-age-radio">
              {ageGroups && ageGroups.length > 0
                ? ageGroups.map((ageGroup) => (
                  <Radio.Button
                    value={ageGroup?.id}
                    // type="radio"
                    onClick={() => {
                      getAgeData(ageGroup?.id);
                      setisDropdownDisabled(false);
                      localStorage.setItem("ageID", ageGroup?.id);
                      localStorage.setItem("ageName", ageGroup?.ageGroup);
                      setSelectedAgeGroupsData(null);
                      // setShowAlert(false);
                      toggleClass();
                      setLocalAge(ageGroup.ageGroup, ageGroup.id);
                    }}
                  >
                    {ageGroup?.ageGroup}
                  </Radio.Button>
                ))
                : ""}
            </div>
          </Radio.Group>
          <Form>
            <Form.Item>
              <label>What do you do?</label>
              <Select
                className="customSelect"
                disabled={isDropdownDisabled}
                // placeholder={firstValue}
                placeholder="Select Option"
                value={selectedAgeGroupsData}
                onChange={(e) => {
                  const profID = ageGroupData?.find(
                    (element) => element.tag.userTag === e
                  );
                  setProf(profID?.tag?.id);
                  // console.log("----", profID);
                  localStorage.setItem("profID", profID?.tag?.id);
                  ageGroupDataChange(e);
                }}
              >
                {ageGroupData &&
                  ageGroupData?.map((ageGroupData, i) => (
                    <Select.Option
                      key={ageGroupData.tag.id}
                      value={ageGroupData.tag.userTag}
                    >
                      {ageGroupData.tag.userTag}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Form>
        </div>
        {profAlert && (
          <div className="alert-container">
            <label
              className="age-alert"
              style={{ color: "red", width: "100%" }}
            >
              Please select profession!
            </label>
          </div>
        )}
        {showAlert && (
          <div className="alert-container">
            <label
              className="age-alert"
              style={{ color: "red", width: "100%" }}
            >
              Please set age & profession!
            </label>
          </div>
        )}
      </Modal>
    </>
  );
}
