import React from "react";
import "../searchBar/searchBar.scss";
import { useNavigate } from "react-router-dom";
import Leftarrow from "../../assets/images/Leftarrow.svg";

export default function SearchBarHelp({ viewProfile }) {
  const page = localStorage.getItem("Page");
  const id = localStorage.getItem("micrositeID");

  const partner = localStorage.getItem("partner");

  const navigate = useNavigate();

  const BackButtonHandler = () => {
    if (page === "home") {
      navigate(`/home`);
      localStorage.removeItem("feedback");
    } else if (page === "partner") {
      navigate(`/`);
      localStorage.removeItem("feedback");
    } else if (page === "view") {
      navigate(`/view`);
      localStorage.removeItem("feedback");
    } else {
      navigate(`/microsite/${id}`, { replace: true });
      localStorage.removeItem("feedback");
    }
  };

  return (
    <div className="search-bar">
      <div className="back-btn" type="link" onClick={BackButtonHandler}>
        {/* <ArrowLeftOutlined
            style={{
              color: "#828282",
              fontSize: "1.15em",
              transform: "scaleX(1.2)",
              height: "50%"
            }}
          /> */}
        <img src={Leftarrow} />
      </div>
      {localStorage.getItem("feedback") === "feedback" ? (
        <div className="help-header-logo">Support & Feedback</div>
      ) : localStorage.getItem("feedback") === "editProfile" ? (
        <div className="help-header-logo">{viewProfile}</div>
      ) : (
        <div className="help-header-logo">Help</div>
      )}
    </div>
  );
}
