import React, { useEffect, useState } from "react";
import { Row, Collapse, Spin } from "antd";
import { toast } from "react-toastify";
import userapiservice from "../../api/userapiservice";
import { getRequest } from "../../api/http";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import ModalPlayer from "../../components/PlayerModal/playerModal";
import ytPlayBtn from "../../assets/images/YouTube_play_button.svg"


export default function Products({ ChangeShowDetailHandler }) {
  const [loading, setLoading] = useState(true);
  // const [selectedOption, setselectOption] = useState({});
  // const [data, setData] = useState([{}]);
  const [activeKey] = useState("product");
  const [responseData, setReponseData] = useState([]);
  const [mediatype, setmediatype] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  // const [thumbNail, setThumbNail] = useState([]);
  // const { id } = useParams();
  const id = localStorage.getItem("micrositeID");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const color = ["#E75E0D", "#EB9F11", "#ABC710", "#009340", "#CC0370", "#8366A9", "#149BD7"];

  const getProductsData = async (activeKey, id) => {
    try {
      setLoading(true);
      // const headers = {
      //   Authorization: `Bearer ${localStorage.getItem("token")}`,
      // };
      if (id) {
        const response = await getRequest(
          userapiservice.getMicrositeDetailsTabs(id, activeKey)
        );
        setReponseData(response);
        // getThumbNail(response);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.error);
      setLoading(false);
    }
  };

  const transformedData = responseData?.reduce((acc, item) => {
    const teamEntry = acc.find((entry) => entry.assetTitle === item?.assetTitle);
    const itemCopy = {
      ...item,
      id: item?.id,
      assetTitle: item?.assetTitle,
      subTitle: item?.subTitle,
      available: item?.available,
      description: item?.description,
      type: item?.type,
      mediaLink: {
        id: item?.mediaLink?.id,
        mediaType: item?.mediaLink?.mediaType,
        url: item?.mediaLink?.url,
      },
      urlLink: item?.urlLink,
      micrositeLogo: item?.micrositeLogo,
    };

    if (teamEntry) {
      teamEntry.subTitles.push(itemCopy);
    } else {
      acc.push({
        assetTitle: item?.assetTitle,
        subTitles: [itemCopy],
      });
    }

    return acc;
  }, []);


  const PlayVideoHandler = (url, mediaType) => {
    setmediatype(mediaType)
    setVideoUrl(url);
    setIsModalOpen(true);
  };
  const PlayVimeoVideoHandler = (url, mediaType) => {
    setmediatype(mediaType)
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const convertToStandardURL = (shortUrl) => {
    if (shortUrl?.indexOf('youtu.be') !== -1) {
      const video_id = shortUrl?.split('youtu.be/')[1].split('?')[0];
      return `https://www.youtube.com/watch?v=${video_id}`;
    }
    return shortUrl;
  }


  const onCardClickHandler = (data, id) => {
    navigate(`/microsite/${data.id}/micrositeDetails`, {
      state: { page: activeKey, data: data },
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setVideoUrl("");
  };

  useEffect(() => {
    getProductsData(activeKey, id);
  }, [activeKey, id]);


  if (loading) {
    return (
      <div className="loader">
        <Spin tip="Loading" size="large" />
      </div>
    )
  } else {
    return (
      <div className="background-container" style={{ width: "100%", background: "white" }}>
        <div className="card-container">
          <Row
            className="rowBody collapse-container preview-tab-container"
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            {activeKey === "product" && responseData && responseData.length > 0
              ? (
                <Collapse accordion bordered={false} >
                  {transformedData.map((team, i) => (
                    <Collapse.Panel
                      key={`${team.id}-${team.assetTitle}`}
                      header={
                        <span className="tabContent" style={{ margin: '0' }} >
                          {team.assetTitle}
                        </span>
                      }
                      style={{
                        backgroundColor: "white", marginBottom: "10px", minHeight: "70px", borderRadius: 10, boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.2)", fontSize: "1.25em", fontWeight: "500", borderColor: 'white', paddingTop: 8, paddingBottom: 8,
                        borderLeft: `5px solid ${color[(i % 7)]}`
                      }}
                    >
                      {team.subTitles.map((data, index) => (
                        <motion.div
                          key={index}
                          initial={{ opacity: 0, scale: 0.5 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{
                            duration: 0.8,
                            delay: 0.5,
                            ease: [0, 0.71, 0.2, 1.01],
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              cursor: "pointer",
                              minHeight: "70px",
                              borderRadius: "10px",
                              borderLeft: `5px solid #ccc`
                            }}
                            onClick={() => onCardClickHandler(data)}
                          >
                            <div style={{ marginLeft: 24, fontWeight: 400 }}>{data.subTitle}</div>
                            {data.mediaLink?.mediaType == "SOCIAL_MEDIA" ? (
                              <div
                                className="sliderImg videoOverlay"
                                onClick={() =>
                                  PlayVideoHandler(
                                    data.mediaLink?.url,
                                    data.mediaLink?.mediaType
                                  )
                                }
                              >
                                {(() => {
                                  const standardURL = convertToStandardURL(
                                    data.mediaLink?.url
                                  );
                                  const videoID = new URLSearchParams(
                                    new URL(standardURL).search
                                  ).get("v");
                                  return (
                                    <>
                                      <img
                                        src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                                        style={{ objectFit: "cover" }}
                                        alt=""
                                        className="video-preview-image"
                                      />
                                      <img
                                        className="ytBtnOverlay"
                                        src={ytPlayBtn}
                                        onClick={() =>
                                          PlayVideoHandler(
                                            data.mediaLink?.url,
                                            data.mediaLink?.mediaType
                                          )
                                        }
                                      />
                                    </>
                                  );
                                })()}
                              </div>
                            ) : data?.mediaLink?.mediaType == "VIMEO_MEDIA" ? (
                              <div className="sliderImg videoOverlay">
                                <img
                                  src={`https://vumbnail.com/${data.mediaLink.url.split("/")[4]
                                    }.jpg`}
                                  alt=""
                                  className="video-preview-image"
                                />
                                <img
                                  className="ytBtnOverlay"
                                  src={ytPlayBtn}
                                  onClick={PlayVimeoVideoHandler.bind(
                                    this,
                                    data.mediaLink?.url,
                                    data.mediaLink?.mediaType
                                  )}
                                />
                              </div>
                            ) : data?.mediaLink?.mediaType == "IMAGE" ? (
                              <div className="sliderImg">
                                <img
                                  src={data.mediaLink?.url}
                                  className="preview-img"
                                  onClick={PlayVimeoVideoHandler.bind(
                                    this,
                                    data.mediaLink?.url,
                                    data.mediaLink?.mediaType
                                  )}
                                />
                              </div>
                            ) : <div style={{ height: "85px" }}></div>}
                          </div>
                          {i !== team.subTitles.length - 1 && <hr style={{ border: "1px solid lightgray" }} />}
                        </motion.div>
                      ))}
                    </Collapse.Panel>
                  ))}
                </Collapse>
              )
              : (
                <div className="background-container" style={{ width: "100%" }}>
                  <div className="login-wall">
                    <div className="info-container" >
                      <span>No products available at the moment</span>
                    </div>
                  </div>
                </div>
              )}
          </Row>
        </div>
        {(isModalOpen) ? (
          <ModalPlayer closeModal={handleCancel} mediaLink={videoUrl} mediaType={mediatype} />
        ) : ""}
      </div>
    );
  }
}
