import React, { useEffect, useState } from "react";
import "./PartnerPage.scss";
import ProfileDropdown from "../../components/ProfileDropdown/ProfileDropdown";
import { Form, Dropdown } from "antd";
import { Button, Modal, Input, Select } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import OrganiationSizes from "../../json/organizationSize.json";
import useComponentVisible from "../login/useComponentVisible";
import { useNavigate } from "react-router-dom";
import userapiservice from "../../api/userapiservice";
import { getRequest } from "../../api/http";
import plusicon from "../../assets/images/plusicon.svg";
import { toast } from "react-toastify";
import Profile from "../editProfile/profile";
import { motion } from "framer-motion";
import { SearchOutlined } from "@ant-design/icons";
import PartnerCarousel from "./partnerSlider";
import OrganisationLogin from "../../assets/images/organisationLogin.svg";
import userLogin from "../../assets/images/userLogin.svg";
import userLogout from "../../assets/images/userLogout.svg";
import helpIcon from "../../assets/images/helpIcon.svg";
import { useLocation } from "react-router-dom";
import feedbackIcon from "../../assets/images/feedback.svg";
import { RWebShare } from "react-web-share";
import share from "../../assets/images/shareIconBlue.svg";

const LogoCarousel = ({ partnerLogos }) => {
  // Create a copy of the partner logos array and append it to the original
  // Check use of useMemo for this array
  const extendedPartnerLogos = [...partnerLogos, ...partnerLogos];

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      setScrollPosition((prevPosition) => {
        // Calculate the new scroll position (scrolling up)
        const newPosition = prevPosition - 1;

        // Reset the scroll position when it reaches the end
        if (newPosition < -(extendedPartnerLogos?.length * 25)) {
          return 0;
        }

        return newPosition;
      });
    }, 50); // Adjust the interval for scrolling speed

    return () => clearInterval(scrollInterval);
  }, [extendedPartnerLogos]);

  return (
    <div className="logo-main-container">
      {/* <h4 className="carousel-title" style={{ fontSize: 17, fontWeight: 500 }}>
        Thanks to our Business Partners
      </h4> */}
      <div className="logo-carousel-container">
        <div
          className="logo-carousel"
          style={{ transform: `translateY(${scrollPosition}px)`, cursor: "pointer" }}
        >
          {extendedPartnerLogos.map((partner, index) => (
            <div
              onClick={() => window.open(partner?.urlLink.startsWith("https://") ? partner?.urlLink : `https://${partner?.urlLink}`, "_blank")}
              className="sponsor"
            >
              <div className="sponsor-img-container">
                <img src={partner?.mediaLink?.url} alt="Partner Logo" />
              </div>
              <div className="sponsor-description">{partner?.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default function PartnerPage() {
  const [partnerLogos, setPartnerLogos] = useState([]);
  const phoneNumber = localStorage.getItem("Phone Number");

  // const location = useLocation();
  // const partnerNotFormat = location?.pathname?.split("/")[2];
  // const formattedName = partnerNotFormat?.replace(/_/g, " ");
  // const partner = formattedName;

  // const partner = "partner name"

  // FOR SUBDOMAIN IMPLEMENTATION
  const location = window.location.href;
  // // console.log("location", location);
  const firstSplit = location.split('/')[2];
  // // console.log("firstSplit", firstSplit);
  const secondSplit = firstSplit.split('.')[0];
  // // console.log("secondSplit", secondSplit);
  const partner = secondSplit?.replace(/-/g, " ");
  // console.log("partner", partner);

  localStorage.setItem("partner", partner);

  const getPartnersLogo = async () => {
    try {
      const response = await getRequest(
        userapiservice.getPartnerSupporterLogo(partner)
      );
      if (response) {
        // console.log("res", response);
        setPartnerLogos(response);
      }
    } catch (error) {
      toast.error(error.error);
    }
  };
  // console.log("partnerlogos", partnerLogos);

  useEffect(() => {
    if (partner) {
      getPartnersLogo();
    }
  }, []);

  const navigate = useNavigate();
  const [subSectors, setSubSectors] = useState([]);
  const [sectorId, setSectorId] = useState(
    localStorage.getItem("sector") ? localStorage.getItem("sector") : null
  );
  const [subsectorId, setSubsectorId] = useState(
    localStorage.getItem("subsector") ? localStorage.getItem("subsector") : null
  );
  const [size, setSize] = useState(
    localStorage.getItem("size") ? localStorage.getItem("size") : null
  );
  const [interestName, setinterestName] = useState(
    localStorage.getItem("Interest/AssetType")
      ? localStorage.getItem("Interest/AssetType")
      : ""
  );
  const interest = [
    "",
    "PRODUCT",
    "SERVICE",
    "CONTRACTS",
    "VACANCY",
    "APPRENTICESHIP",
    "WORK_EXPERIENCE",
    "COURSES",
  ];
  const [selectedEvent, setselectedEvent] = useState("");
  const [partnerLogo, setPartnerLogo] = useState(null);
  const [partnerCarousel, setPartnerCarousel] = useState(null);
  // const [organizationName, setOrganisationName] = useState([]);
  const [loading, setLoading] = useState(false);

  // const [freeTextSearchName, setFreeTextSearchName] = useState(
  //   localStorage.getItem("searchName")
  //     ? localStorage.getItem("searchName")
  //     : null
  // );

  const handleFreeTextChange = (e) => {
    // console.log(e.target.value, "freetextsearch");
    // setFreeTextSearchName(e.target.value);
    localStorage.setItem("searchName", e.target.value);
  };

  const [partnerId, setPartnerId] = useState();

  useEffect(() => {
    if (localStorage.getItem("partnerId")
    ) {
      setPartnerId(localStorage.getItem("partnerId"))
    }
  }, [])


  const [isShowProfile, setIsShowProfile] = useState(false);
  const { setIsComponentVisible } = useComponentVisible(true, setIsShowProfile);

  const [sectors, setSectors] = useState([]);
  const [sectorValue, setSectorValue] = useState(
    localStorage.getItem("sector") ? localStorage.getItem("sector") : ""
  );
  const [subSectorValue, setSubsectorValue] = useState(
    localStorage.getItem("subsector") ? localStorage.getItem("subsector") : ""
  );
  const [sizeValue, setSizeValue] = useState(
    localStorage.getItem("size") ? localStorage.getItem("size") : ""
  );
  // const [subSectors, setSubSectors] = useState([]);
  // const [subSectors, setSubSectors] = useState([]);

  const [open, setOpen] = useState(false);
  const [openHome, setOpenHome] = useState(false);

  const [age, setAge] = useState(
    localStorage.getItem("age") ? localStorage.getItem("age") : ""
  );

  const getPartnerLogo = async () => {
    try {
      const response = await getRequest(
        userapiservice.getPartnerDetails(partner)
      );
      if (response) {
        // console.log(response)
        setPartnerLogo(response);
        setPartnerId(response.id)
        localStorage.setItem("partnerId", response.id);
        // console.log(response)
      }
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  const getPartnerCarousel = async () => {
    setLoading(false);
    try {
      const response = await getRequest(
        userapiservice.getPartnerCarousel(partner)
      );
      setPartnerCarousel(response);
      setLoading(true);
    } catch (e) {
      setLoading(false);
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  // const FindSectorName = (id) => {
  //   let response = "";
  //   getSector.map((data) => {
  //     if (id === data.id) {
  //       console.log(data.name)
  //       response = data.name
  //     }
  //   })
  //   return response;
  // };

  const getSubSector = async (sectorId) => {
    try {
      // const headers = {
      //   Authorization: `Bearer ${localStorage.getItem("token")}`,
      // };
      const response = await getRequest(userapiservice.getSubSectors(sectorId));
      console.log("res in getSubSector", response)
      setSubSectors(response);
      // setSubsectorValue(subSectorId);
    } catch (error) {
      toast.error(error.error);
    }
  };

  // const RemoveHandler = (value) => {
  //   localStorage.removeItem(value);
  //   if (value == "interest") {
  //     localStorage.removeItem("Interest/AssetType")
  //     setinterestName(null)
  //     setselectedEvent(null)
  //     // fetchMicrosites(null,sectorId,subsectorId,size);
  //   }
  //   if (value == "sector") {
  //     setSectorId(null);
  //     setSubsectorId(null);
  //     localStorage.removeItem("subsector");
  //     // fetchMicrosites(interestName,null,null,size);
  //   }
  //   if (value == "subsector") {
  //     setSubsectorId(null);
  //     // fetchMicrosites(interestName,sectorId,null,size);
  //   }
  //   if (value == "size") {
  //     setSize(null);
  //     // fetchMicrosites(interestName,sectorId,subsectorId,null);
  //   }
  //   setUpdateFilterData(!updateFilterData);
  // };

  const closeModal = () => {
    setOpen(false);
  };

  const onInterest = (e) => {
    setselectedEvent(e);
    // setinterestName(e);
    localStorage.setItem("Interest/AssetType", e);
    // console.log(e)
    if (e === "PRODUCT") {
      localStorage.setItem("tabValue", "product");
    } else if (e === "SERVICE") {
      localStorage.setItem("tabValue", "service");
    } else if (e === "CONTRACTS") {
      localStorage.setItem("tabValue", "contract");
    } else if (e === "VACANCY") {
      localStorage.setItem("tabValue", "vacancy");
    } else if (e === "APPRENTICESHIP") {
      localStorage.setItem("tabValue", "apprenticeship");
    } else {
      localStorage.setItem("tabValue", "work-experience");
    }
    // localStorage.setItem("tabValue",e)
  };

  const searchClick = () => {
    // setOpen(true);
    if (age) {
      setOpenHome(true);
    } else {
      setOpen(true);
    }
  };

  const onSector = async (sectorId) => {
    setSectorValue(sectorId);
    localStorage.setItem("sector", sectorId ? sectorId : "");
    // setSubsectorValue("");
    if (sectorId) {
      try {
        // const headers = {
        //   Authorization: `Bearer ${localStorage.getItem("token")}`,
        // };
        const response = await getRequest(
          userapiservice.getSubSectors(sectorId)
        );
        // console.log("res in onSector", response)
        setSubSectors(response);
        // setSubsectorValue(subSectorId);
      } catch (error) {
        toast.error(error.error);
      }
    } else {
      // console.log("empty")
      setSubsectorValue("");
      setSubSectors(null);
    }
  };

  const SizeChange = async (size) => {
    setSizeValue(size);
    localStorage.setItem("size", size ? size : "");
  };

  const subSectorChange = async (subSectorIds) => {
    // console.log(subSectorIds)
    setSubsectorValue(subSectorIds);
    localStorage.setItem("subsector", subSectorIds ? subSectorIds : "");
  };

  const fetchSectorList = async () => {
    try {
      const response = await getRequest(
        userapiservice.getAllSectors(partnerId)
      );
      // console.log(response);
      setSectors(response);
      if (sectorId) {
        setSectorValue(sectorId);
        // sectorChange(sectorId);
      }
      if (size) {
        // setSizeValue(size);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  // const showProfile = () => {
  //   setIsShowProfile(!isShowProfile);
  //   setIsComponentVisible(true);
  // };

  const closeDropdown = () => {
    setIsShowProfile(!isShowProfile);
    setinterestName("");
    setSizeValue("");
    setSubsectorValue("");
    setSectorValue("");
  };

  // console.log("orgName", organizationName)

  useEffect(() => {
    if (partner) {
      getPartnerLogo();
      getPartnerCarousel();
      // searchOrganisationData("");
    }
  }, [partner, sectorId, subsectorId, size, interestName]);

  useEffect(() => {
    if (partner && partnerId) {
      fetchSectorList();
      localStorage.setItem("Page", "partner");
    }
  }, [partner, partnerId]);

  useEffect(() => {
    if (partner && sectorId) {
      getSubSector(sectorId);
    }
  }, [partner, sectorId]);

  useEffect(() => {
    if (localStorage.getItem("changeAge") === "true") {
      setOpen(true);
    }
    localStorage.removeItem("changeAge")
  }, [])
  // console.log("walll", partnerLogo.wallpaper)

  if (loading) {
    return (
      <div className="background-container">
        <div className="partner-header">
          <div className="partner-header-logo">
            <img src={partnerLogo?.mediaLink} alt="" />
            {/* <img style={{ height: "" }} src={JLRLogo} alt="" /> */}
          </div>
          {/* <div className="partner-header-siteName">
            <h2><b>{partnerLogo?.siteName}</b></h2>
          </div> */}
          <div style={{ display: "flex" }}>
            {/* <a target="_blank" href="https://connectsu-dev.eastus.cloudapp.azure.com/signup"> */}
            {/* <a target="_blank" href="http://localhost:3001/signup"> */}
            <Button
              className="org-btn"
              type="primary"
              htmlType="submit"
              onClick={() => navigate("/onboarding")}
            >
              <img src={plusicon} alt="" />
              <span style={{ color: "white" }}>Organisation</span>
            </Button>
            {/* </a> */}
            {/* <button className="org-login-btn" onClick={showProfile}>
              <MenuUnfoldOutlined />
            </button> */}
            <div>
              <Dropdown
                trigger={["click"]}
                placement="bottomRight"
                overlay={
                  <div
                    className="dropdown"
                    style={{
                      border: "1px solid #569dfa",
                      borderRadius: "4px",
                      backgroundColor: "#fff",
                      width: "190px",
                    }}
                  >
                    <div
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid #f0f0f0",
                        cursor: "pointer",
                      }}
                    >
                      <a
                        href={`https://orgadmin.expo365.app/login/${partnerId}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "black", cursor: "pointer" }}
                      >
                        <div
                          className="dropdown-items"
                          style={{
                            display: "flex",
                            alignContent: "center",
                          }}
                        >
                          <img src={OrganisationLogin} alt="main logo" />
                          <label
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                          >
                            Organisation Login
                          </label>
                        </div>
                      </a>
                    </div>
                    <div
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid #f0f0f0",
                        cursor: "pointer",
                      }}
                    >
                      <RWebShare
                        data={{
                          url: `${window.location.href}`,
                        }}
                      >
                        <div
                          className="dropdown-items"
                          style={{
                            display: "flex",
                            alignContent: "center",
                          }}
                        >
                          <img src={share} alt="main logo" />
                          <label
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                          >
                            Website URL
                          </label>
                        </div>
                      </RWebShare>
                    </div>
                    <div
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid #f0f0f0",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className="dropdown-items"
                        style={{
                          display: "flex",
                          alignContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/help")}
                      >
                        <img src={helpIcon} alt="main logo" />
                        <label
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                        >
                          Help
                        </label>
                      </div>
                    </div>

                    {age === "23" || age === "37" || age === "53" ? (
                      <div
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid #f0f0f0",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          className="dropdown-items"
                          style={{
                            display: "flex",
                            alignContent: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate("/feedback");
                            localStorage.setItem("feedback", "feedback");
                          }}
                        >
                          <img src={feedbackIcon} alt="main logo" />
                          <label
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                          >
                            Support & Feedback
                          </label>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    {/* {!phoneNumber ? (
                      <div
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid #f0f0f0",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          className="dropdown-items"
                          style={{
                            display: "flex",
                            alignContent: "center",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigate("/login", { state: { page: "partner" } })
                          }
                        >
                          <img src={userLogin} alt="main logo" />
                          <label
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                          >
                            User Login
                          </label>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )} */}

                    {/* <div
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid #f0f0f0",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className="share-dropdown"
                        style={{
                          display: "flex",
                          alignContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={LogoutHandler}
                      >
                        <img src={userLogout} alt="main logo" />
                        <label
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                        >
                          User Logout
                        </label>
                      </div>
                    </div> */}
                  </div>
                }
              >
                <div className="org-login-btn">
                  <MenuUnfoldOutlined />
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
        <div
          className="partner-page-container"
          style={{
            backgroundImage: `${partnerLogo?.wallpaperToggle === true
              ? `linear-gradient(45deg, rgba(103, 121, 148, 0.685), rgba(2, 102, 202, 0.601)), url(${partnerLogo?.wallpaper})`
              : "white"
              }`,
          }}
        >
          {/* <Row>
            <Col className="col-sm-0" span={6}> */}
          <div className="twitter">
            {loading && (
              <div>
                <div className="partner-details">
                  <div className="logo-div">
                    <img src={partnerLogo?.mediaLink} alt="" />
                    {/* <img src={JLRLogo} alt="" /> */}
                  </div>
                  <div className="info-div">
                    <div className="partnerMainText">
                      <p>
                        <b>{partnerLogo?.membershipAdTitle}</b>
                      </p>
                    </div>
                    <p>{partnerLogo?.membershipAdDescription}</p>
                    {/* <p>
                      {partnerLogo?.membershipLink}
                    </p> */}
                  </div>
                  <div className="btn-div">
                    <a
                      href={partnerLogo?.membershipLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="join-btn">Join Now</Button>
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* </Col>
            <Col className="col-sm-24" span={12}> */}
          {loading && (
            <div className="container">
              <div className="siteName">
                <p style={{ display: "flex", flexDirection: "row" }}>
                  <b style={{ fontSize: 25, fontWeight: 1000 }}>
                    {partnerLogo?.siteName}
                  </b>
                </p>
              </div>

              <div className="carousel-container">
                {/* <Carousel carouselData={partnerCarousel} /> */}
                <PartnerCarousel carouselData={partnerCarousel} />
              </div>
              <motion.div
                className="form-container"
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.8,
                  delay: 0.5,
                  ease: [0, 0.71, 0.2, 1.01],
                }}
              >
                <Form>
                  <div className="flex">
                    <Form.Item className="form-item">
                      <label className="label-text">
                        Search organisations for
                      </label>
                      <Select
                        value={selectedEvent}
                        placeholder="Select interest"
                        onChange={(e) => onInterest(e)}
                      >
                        {interest?.map((Search, i) => (
                          <Select.Option key={i} value={interest[i]}>
                            {interest[i] === ""
                              ? "All"
                              : interest[i] === "APPRENTICESHIP"
                                ? "Apprenticeships"
                                : interest[i] === "VACANCY"
                                  ? "Vacancies"
                                  : interest[i] === "PRODUCT"
                                    ? "Products"
                                    : interest[i] === "CONTRACTS"
                                      ? "Contracts"
                                      : interest[i] === "SERVICE"
                                        ? "Services"
                                        : interest[i] === "WORK_EXPERIENCE"
                                          ? "Experiences of work"
                                          : interest[i] === "COURSES"
                                            ? "Courses"
                                            : interest[i] === "CONTRACTS"
                                              ? "Contracts"
                                              : ""}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item className="form-item">
                      <label>Categories</label>
                      <Select
                        value={sectorValue}
                        placeholder="Select Sector"
                        onChange={(e) => {
                          localStorage.removeItem("subsector");
                        }}
                        onSelect={(e) => {
                          onSector(e);
                        }}
                      >
                        <Select.Option value="">All</Select.Option>
                        {sectors?.map((Sector, i) => (
                          <Select.Option key={i} value={Sector.id}>
                            {Sector.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="flex">
                    <Form.Item className="form-item">
                      <label>Sub categories</label>
                      <Select
                        value={subSectorValue}
                        placeholder="Select Subsector"
                        onSelect={(e) => {
                          subSectorChange(e);
                        }}
                      >
                        <Select.Option value="">All</Select.Option>
                        {subSectors &&
                          subSectors?.map((subSector, i) => (
                            <Select.Option key={i} value={subSector.id}>
                              {subSector.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                    <Form.Item className="form-item">
                      <label>Size by employees</label>
                      <Select value={sizeValue} onChange={(e) => SizeChange(e)}>
                        <Select.Option value="">All</Select.Option>
                        {OrganiationSizes.map((OrganiationSize, i) => (
                          <Select.Option key={i} value={OrganiationSize.id}>
                            {OrganiationSize.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <Form.Item className="form-item">
                    <Input
                      placeholder=" Search Microsites "
                      prefix={
                        <SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      // value={freeTextSearchName}
                      onChange={handleFreeTextChange}
                    />
                  </Form.Item>

                  <Form.Item style={{ marginBottom: "0px" }}>
                    <Button
                      className="search-btn"
                      type="primary"
                      htmlType="submit"
                      onClick={searchClick}
                    >
                      <span style={{ color: "white" }}>Search</span>
                    </Button>
                    {open ? <Profile closeModal={closeModal} /> : <></>}
                    {/* {openHome && navigate("/home", { replace: true })} */}
                    {openHome && navigate("/view")}
                    {/* {openHome && (age > 18) ? navigate("/home", { replace: true }) : navigate("/view")} */}
                  </Form.Item>
                </Form>
              </motion.div>
              <div className="footer">
                <div
                  className="footer-content"
                  onClick={() =>
                    window.open("https://www.connectsu.co.uk/", "_blank")
                  }
                  style={{ cursor: "pointer" }}
                >
                  <div className="footer-logo">
                    <img
                      src={require("../../assets/images/PoweredNew.png")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* </Col>
            <Col className="col-sm-0" span={6}> */}
          <div className="twitter">
            {partnerLogos && partnerLogos.length > 0 && (
              <>
                <h4
                  className="carousel-title"
                  style={{
                    fontSize: 17,
                    fontWeight: 500,
                    color: `${partnerLogo?.wallpaperToggle ? "white" : "black"
                      }`,
                  }}
                >
                  Thanks to our Business Partners
                </h4>
                <LogoCarousel partnerLogos={partnerLogos} />
              </>
            )}

            {/* <Timeline
              className="timeline"
              dataSource={{
                sourceType: 'profile',
                url: partnerLogo?.twitterHandle
              }}
              options={{
                username: 'TwitterDev',
                height: '800vh'
              }}
            // onLoad={() => console.log('Timeline is loaded!')}
            /> */}
          </div>
        </div>
        {isShowProfile ? (
          <div className="partner-dropdown">
            <ProfileDropdown closeDropdown={closeDropdown} />
          </div>
        ) : null}
      </div>
    );
  }
}
