import { API_URL } from "./http";

const partnerName = localStorage.getItem("partnerName");
// console.log("partner userService", partnerName);

const refreshToken = () => `${API_URL}/refresh-token`;

const filterMicrosites = (
  partner,
  organisationType,
  interest,
  sectorId,
  subSectorId,
  size,
  name,
  rangeAF,
  rangeGL,
  rangeMS,
  rangeTZ
) =>
  `${API_URL}/end-user/filterByMicrosite?${partner ? `partnerName=${partner}&` : ""
  }${sectorId ? `sectorId=${sectorId}&` : ""}${subSectorId ? `subSectorId=${subSectorId}&` : ""
  }${size ? `size=${size}&` : ""}${name ? `text=${name}&` : ""}${interest ? `assetType=${interest}&` : ""
  }${organisationType ? `organizationTypeId=${organisationType}&` : ""}${rangeAF ? `range=${rangeAF}&` : ""
  }${rangeGL ? `range=${rangeGL}&` : ""}${rangeMS ? `range=${rangeMS}&` : ""}${rangeTZ ? `range=${rangeTZ}&` : ""
  }`;

//   `${API_URL}/end-user/filterByMicrosite?${sectorId ? `sectorId=${sectorId}&` : ""
// }${subSectorId ? `subSectorId=${subSectorId}&` : ""}${size ? `size=${size}&` : ""}${text ? `text=${text}${name ? `name=${name}&` : ""}${interest ? `assetType=${interest}` : ""
// }`;

const filterFavorites = (
  partner,
  phoneNumber,
  organisationType,
  interest,
  sectorId,
  subSectorId,
  size,
  name,
  rangeAF,
  rangeGL,
  rangeMS,
  rangeTZ
) =>
  `${API_URL}/end-user/filterByfavMicrosite/${phoneNumber}?${partner ? `partnerName=${partner}&` : ""
  }${sectorId ? `sectorId=${sectorId}&` : ""}${subSectorId ? `subSectorId=${subSectorId}&` : ""
  }${size ? `size=${size}&` : ""}${name ? `text=${name}&` : ""}${interest ? `assetType=${interest}&` : ""
  }${organisationType ? `organizationTypeId=${organisationType}&` : ""}${rangeAF ? `range=${rangeAF}&` : ""
  }${rangeGL ? `range=${rangeGL}&` : ""}${rangeMS ? `range=${rangeMS}&` : ""}${rangeTZ ? `range=${rangeTZ}&` : ""
  }`;

const getFilteredMaps = (
  organisationType,
  interest,
  sectorId,
  subSectorId,
  size,
  name
) =>
  `${API_URL}/end-user/filter-on-map?${sectorId ? `sectorId=${sectorId}&` : ""
  }${subSectorId ? `subSectorId=${subSectorId}&` : ""}${size ? `size=${size}&` : ""
  }${name ? `name=${name}&` : ""}${interest ? `assetType=${interest}&` : ""}${organisationType ? `organisationtype=${organisationType}&` : ""
  }`;

const searchOrganisations = (name) =>
  `${API_URL}/end-user/filter?${name ? `name=${name}` : ""}`;

// const getAllSectors = () => `${API_URL}/sector`;

const getAllSectors = (partnerId) =>
  `${API_URL}/sector/get-all-sectors/${partnerId}`;

const getSubSectors = (sectorId) =>
  `${API_URL}/sector/${sectorId ? sectorId : ""}`;

const getMicrositeDetails = (id) => `${API_URL}/end-user/get-microsites/${id}`;

const getMicrositeDetailsByName = (name) =>
  `${API_URL}/end-user/get-microsite/${name}`;

const getMicrositeLogo = (id) =>
  `${API_URL}/end-user/organization/microsite/${id}`;

const getMicrositeDetailsTabs = (id, tabName) =>
  `${API_URL}/end-user/organization/${id}/details?section=${tabName}`;

const getAboutUsDetailsTabs = (id, tabName) =>
  `${API_URL}/end-user/organization/microsite/${id}/about-us-details?aboutUsEnum=${tabName}`;
// ${API_URL}/end-user/organization/microsite/${id}/about-us-details?aboutUsEnum=${tabName}

const getNewsByDate = (id, text) =>
  `${API_URL}/end-user/search-news-by-date/${id}?text=${text}`


const getContractsDetails = (id) =>
  `${API_URL}/end-user/organization/contracts/${id}`;

const getContactUsDetails = (id, tabName) =>
  `${API_URL}/end-user/contact-us/organization/${id}?contactUsTypeEnum=${tabName}`;

const getMicrositeDetailsVacancyTab = (id, tabName) =>
  `${API_URL}/end-user/organization/${id}/vacancy?apprenticeshipType=${tabName}`;

const getsettings = (microSiteId, partnerId) =>
  `${API_URL}/end-user/microsite/${microSiteId}/partner/pro-settings?partnerId=${partnerId}`

const getmaps = () => `${API_URL}/end-user/organization-coodinates?`;

const getMicrositeList = (id) => `${API_URL}/end-user/get-microsites/${id}`;

const getEndUserDetails = () => `${API_URL}/end-user/end-user-details`;
//
const getPartnerDetails = (partner) =>
  `${API_URL}/end-user/partner-details?${partner ? `partnerName=${partner}` : ""
  }`;

const getOnOrganisationType = (partnerId) =>
  `${API_URL}/get-all-orgType/${partnerId}?toggle=true`;

const postFeedbackForm = (partnerId) =>
  `${API_URL}/end-user/endUser-feedback/${partnerId}`;

const getPartnerCarousel = (partner) =>
  `${API_URL}/end-user/details?section=corousel&${partner ? `partnerName=${partner}` : ""
  }`;

const getEndUserAgeGroups = () => `${API_URL}/get-end-user-age-groups`;

const getWhatDoYouDo = (id) =>
  `${API_URL}/end-user/end-user-tags/age-group/${id}`;

const getExperienceOfWork = (id) =>
  `${API_URL}/end-user/organization/microsite/${id}/experience-of-work`;

const getCountries = () => `${API_URL}/end-user/country-details`;

const signUpOrg = () => `${API_URL}/end-user/signup-organization`;

const getPartnerSupporterLogo = (partner) =>
  `${API_URL}/end-user/supportLogo/${partner}`;

const postBookmark = (micrositeId, mobileNumber) =>
  `${API_URL}/end-user/organization/microsite/addMicrositeAsFavorites/${micrositeId}/${mobileNumber}`;

const removeBookmark = (micrositeId, mobileNumber) =>
  `${API_URL}/end-user/organization/microsite/deleteFavoritesOfMicrosite/${micrositeId}/${mobileNumber}`;

const getBookmark = (mobileNumber, partner) =>
  `${API_URL}/end-user/organization/microsite/getFavorites/${mobileNumber}?${partner ? `partnerName=${partner}` : ""
  }`;

const getReviews = (id, phone) => {
  return `${API_URL}/end-user/organization/microsite/getReview/${id}`;
};

const getFiles = (micrositeId) =>
  `${API_URL}/end-user/insuranceDocs/${micrositeId}`;

const download = (documentId, ageId) =>
  `${API_URL}/end-user/downloadDoc/${documentId}/ageTag/${ageId}`;

const sendDetails = () => `${API_URL}/end-user/create-profile`;

const help = () => `${API_URL}/end-user/getHelpSection?userRole=END_USER`;

const getFilteredHelpInfo = (userRole, searchKey) =>
  `${API_URL}/end-user/help-section/search/?userRole=${userRole}${searchKey ? `&text=${searchKey}` : ''}`


const deleteReview = (replyId) =>
  `${API_URL}/end-user/organization/microsite/deleteReview/${replyId}`;

const addReview = () => `${API_URL}/end-user/organization/microsite/addReview`;

const getLevels = () => `${API_URL}/end-user/getlevels`;

const micrositeViewCount = (micrositeId) =>
  `${API_URL}/end-user/addMicrositeView/${micrositeId}`;

const contactPageViewCount = (micrositeId, tab_enum) =>
  `${API_URL}/end-user/addContactUsView/${micrositeId}?contactUsTypeEnum=${tab_enum}`;

const checkPhonenumber = (phoneNumber) =>
  `${API_URL}/check-PhoneNumber?phoneNumber=${phoneNumber}`;

const downloadBrochure = (productId) => `${API_URL}/end-user/download-brochure/${productId}`
const viewBrochure = (productId) => `${API_URL}/end-user/view-brochure/${productId}`

export default {
  refreshToken,
  filterMicrosites,
  filterFavorites,
  getAllSectors,
  getSubSectors,
  getMicrositeDetailsTabs,
  getAboutUsDetailsTabs,
  getContractsDetails,
  getMicrositeDetails,
  getMicrositeDetailsByName,
  getMicrositeLogo,
  getContactUsDetails,
  getMicrositeDetailsVacancyTab,
  getsettings,
  getmaps,
  getFilteredMaps,
  getMicrositeList,
  getEndUserDetails,
  searchOrganisations,
  getPartnerDetails,
  postFeedbackForm,
  getPartnerCarousel,
  getEndUserAgeGroups,
  getWhatDoYouDo,
  getExperienceOfWork,
  getCountries,
  signUpOrg,
  signUpOrg,
  getPartnerSupporterLogo,
  postBookmark,
  removeBookmark,
  getBookmark,
  getReviews,
  getFiles,
  download,
  sendDetails,
  help,
  getFilteredHelpInfo,
  deleteReview,
  addReview,
  getLevels,
  micrositeViewCount,
  getOnOrganisationType,
  contactPageViewCount,
  checkPhonenumber,
  downloadBrochure,
  viewBrochure,
  getNewsByDate
};
