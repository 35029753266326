import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import LoginPage from "./screens/login/LoginPage";
import OtpPage from "./screens/otp/OtpPage";
import PartnerPage from "./screens/partnerScreen/PartnerPage";
import HomePage from "./screens/home/HomePage";
import EditProfilePage from "./screens/editProfile/EditProfile";
import ViewPage from "./screens/View/View";
import MicrositePage from "./screens/microsites/microsite";
import MicrositeDetailsPage from "./screens/microsites/micrositeDetails";
import OnboardingPage from "./screens/onboarding/OrgOnboarding";
import TermsPage from "./screens/onboarding/terms";
import Favorites from "./screens/favorites/Favorites";
import Nav from "./components/NavigateComponent/Navigate";
import Help from "./screens/login/Help";
import FeedBack from "./screens/login/Feedback";

import { AnimatePresence } from "framer-motion";
import PageNotFound from "./components/PageNotFound/PageNotFound";

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/help" element={<Help />} />
        <Route path="/feedback" element={<FeedBack />} />
        <Route path="/verifyOtp" element={<OtpPage />} />
        {/* <Route path="/frontend/:partner" element={<PartnerPage />} /> */}
        <Route path="/home" element={<HomePage />} />
        <Route path="/edit" element={<EditProfilePage />} />
        <Route path="/view" element={<ViewPage />} />
        <Route path="/microsite/:id" element={<MicrositePage />} />
        <Route path="/onboarding" element={<OnboardingPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/favorites" element={<Favorites />} />

        <Route
          path="/microsite/:id/micrositeDetails"
          element={<MicrositeDetailsPage />}
        />

        {/* <Route path="/" element={<Nav />} /> */}
        {/* FOR SUBDOMAIN IMPLEMENTATION */}
        <Route path="/" element={<PartnerPage />} />
        {/* REMOVE THE ROUTE SPECIFIED FOR PARTNER PAGE ABOVE AND MAKE IT THE ROUTE TO THE LANDING PAGE ie. /  */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
