import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { Button, Switch, Radio, Modal, Input, Row, Col } from "antd";
import "./OrgOnboarding.scss";
import { useNavigate } from "react-router-dom";
import userapiservice from "../../api/userapiservice";
import { getRequest } from "../../api/http";
import { motion } from "framer-motion";
import TextArea from "antd/es/input/TextArea";
import Leftarrow from "../../assets/images/Leftarrow.svg";
import { Fragment } from "react";

export default function OnboardingPage() {
  const navigate = useNavigate();
  const [partnerLogo, setPartnerLogo] = useState(null);
  const [formData, setFormData] = useState();
  const [countries, setCountries] = useState([]);
  const [formFilled, setFormFilled] = useState(false);
  const lastPage = localStorage.getItem("Page");
  const space = <Fragment>&nbsp&nbsp&nbsp&nbsp</Fragment>;
  const partner = localStorage.getItem("partner");
  const [member, setMember] = useState(false);
  const partnerID = localStorage.getItem("partnerId");
  // const [errorMessageVisible, setErrorMessageVisible] = useState(false)

  const [allOrgTypes, setAllOrgTypes] = useState([]);

  const getOnOrganisationType = async () => {
    try {
      const response = await getRequest(
        userapiservice.getOnOrganisationType(partnerID)
      );
      if (response) {
        setAllOrgTypes(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getOnOrganisationType();
  }, []);

  const getPartnerLogo = async () => {
    try {
      const response = await getRequest(
        userapiservice.getPartnerDetails(partner)
      );
      // console.log(response)
      setPartnerLogo(response);
      // console.log(response)
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  const getCountriesList = async () => {
    try {
      const response = await getRequest(userapiservice.getCountries());
      // console.log(response)
      setCountries(response);
      // console.log(response)
    } catch (e) {
      Modal.error({
        title: "Error",
        content: e.response?.data?.message || "Something went wrong...",
      });
    }
  };

  useEffect(() => {
    getPartnerLogo();
    getCountriesList();
  }, []);

  const submitHandler = (values) => {
    setFormData(values);

    let formData = {
      addressStreet: values?.addressStreet,
      email: values?.email,
      isActiveMember: member,
      name: values?.name,
      phone: values?.phone,
      postcode: values?.postcode,
      size: values?.size,
      typeId: values?.type,
      userName: values?.userName,
      websiteLink: values?.websiteLink,
    };
    console.log("FORMVALS", values)
    sessionStorage.setItem("adr", values?.addressStreet);
    sessionStorage.setItem("email", values?.email);
    sessionStorage.setItem("isactive", member);
    sessionStorage.setItem("name", values?.name);
    sessionStorage.setItem("phone", values?.phone);
    sessionStorage.setItem("postcode", values?.postcode);
    sessionStorage.setItem("size", values?.size);
    sessionStorage.setItem("userName", values?.userName);
    sessionStorage.setItem("webLink", values?.websiteLink);
    const typeName = allOrgTypes.find((type) => type.id === formData.typeId)?.type || "";
    sessionStorage.setItem("typeId", values?.type);
    sessionStorage.setItem("type", typeName);
    navigate("/terms", {
      state: {
        formData,
        partnerLogo: partnerLogo?.siteName,
        nameType: typeName,
      },
    });
    setFormFilled(true);
  };

  const validatePostalCode = (value) => {
    const ukPostalCodeRegex = /^[A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}$/;
    if (ukPostalCodeRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject();
  };

  useEffect(() => {
    const storedSwitchValue = sessionStorage.getItem("isactive");
    // console.log("stored", storedSwitchValue)
    if (storedSwitchValue !== null) {
      // Convert the stored value to boolean if necessary
      setMember(storedSwitchValue === "true");
    }
  }, []);

  // function leftScroll() {
  //   const left = document.querySelector(".orgTypeBtns");
  //   left.scrollBy(200, 0);
  // }
  // function rightScroll() {
  //   // console.log("clicked");
  //   const right = document.querySelector(".orgTypeBtns");
  //   right.scrollBy(-200, 0);
  // }

  return (
    <motion.div
      className="background-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="header">
        {/* <div className="backBtn">
                    <img src={Leftarrow} />
                </div> */}
        <div className="onBoarding-header">
          <img
            className="back-btn"
            src={Leftarrow}
            onClick={() => {
              sessionStorage.clear();
              if (lastPage === "partner") {
                navigate(`/`);
              } else {
                navigate("/home");
              }
            }}
          />

          <h2>Apply to join "{partnerLogo?.siteName}"</h2>
        </div>
      </div>
      <div className="onBoard-container">
        {/* <div className="apply-header">
                    <h2>Please apply</h2>
                </div> */}
        <div className="form-container">
          <Form
            className="signup-form"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 24 }}
            initialValues={{
              type: sessionStorage.getItem("typeId")
                ? sessionStorage.getItem("typeId")
                : allOrgTypes.length > 0
                  ? allOrgTypes[0]?.id
                  : "",
              isActiveMember: sessionStorage.getItem("isactive")
                ? sessionStorage.getItem("isactive")
                : false,
              name: sessionStorage.getItem("name")
                ? sessionStorage.getItem("name")
                : "",
              addressStreet: sessionStorage.getItem("adr")
                ? sessionStorage.getItem("adr")
                : "",
              postcode: sessionStorage.getItem("postcode")
                ? sessionStorage.getItem("postcode")
                : "",
              size: sessionStorage.getItem("size")
                ? sessionStorage.getItem("size")
                : "MICRO",
              userName: sessionStorage.getItem("userName")
                ? sessionStorage.getItem("userName")
                : "",
              email: sessionStorage.getItem("email")
                ? sessionStorage.getItem("email")
                : "",
              phone: sessionStorage.getItem("phone")
                ? sessionStorage.getItem("phone")
                : "",
              websiteLink: sessionStorage.getItem("webLink")
                ? sessionStorage.getItem("webLink")
                : "",
            }}
            onFinish={submitHandler}
          >
            <Row
              className="rowBody"
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
            ></Row>
            <Col
              className="gutter-row"
              xs={24}
              xl={24}
              style={{ height: "auto" }}
            >
              <Form.Item
                className="form-item orgType"
                label="Organisation type"
                name="type"
                rules={[
                  { required: true, message: "Organisation type is required" },
                ]}
              >
                <Radio.Group className="orgTypeBtns" buttonStyle="solid">
                  {allOrgTypes?.length > 0
                    ? allOrgTypes.map((type) => (
                      <Radio.Button
                        key={type.id}
                        value={type.id}
                        style={{ width: "auto" }}
                      >
                        {type.type}
                      </Radio.Button>
                    ))
                    : null}
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} xl={24}>
              <Form.Item
                className="switch-item memberFormItem"
                label="Are you an existing member"
                name="isActiveMember"
                rules={[
                  {
                    required: true,
                    message: "Choose if you are member or not",
                  },
                ]}
              >
                <div className="flexSwitch">
                  <Switch
                    // style={{ color: "green" }}
                    className="memberSwitch"
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    // defaultChecked={false}
                    checked={member}
                    onChange={(e) => {
                      if (e) {
                        setMember(true);
                      } else {
                        setMember(false);
                      }
                    }}
                  />
                  <div className="memberCondition">{`${partnerLogo?.name ? partnerLogo?.name : ""
                    }`}</div>
                </div>
              </Form.Item>
              <div className="condition">{`${partnerLogo?.name ? partnerLogo?.name : ""
                }`}</div>
            </Col>
            <Col className="gutter-row" xs={24} xl={24}>
              <Form.Item
                className="form-item adrField org-name"
                label="Organisation name"
                name="name"
                rules={[
                  { required: true, message: "Organisation name is required" },
                ]}
              >
                <Input placeholder="Organisation name" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} xl={24}>
              <Form.Item
                className="form-item adrField"
                label="Address"
                name="addressStreet"
                rules={[{ required: true, message: "Address is required" }]}
              >
                <TextArea
                  className="addressBox"
                  rows={1}
                  placeholder="Full address (You need to be in our catchment area)"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} xl={24}>
              <Form.Item
                className="form-item adrField"
                label="Postal code"
                name="postcode"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => validatePostalCode(value),
                    message: "Please enter a valid postal code",
                  },
                ]}
              >
                <TextArea
                  className="addressBox"
                  rows={1}
                  placeholder="postal code"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} xl={24}>
              <Form.Item
                className="form-item orgSize"
                label="Organisation size"
                name="size"
                rules={[
                  { required: true, message: "Organisation size is required" },
                ]}
              >
                <Radio.Group buttonStyle="solid" className="orgSizeBtns">
                  <Radio.Button
                    style={{
                      whiteSpace: "normal",
                      height: "auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    value="MICRO"
                  >
                    Micro 0-9
                  </Radio.Button>
                  <Radio.Button
                    style={{
                      whiteSpace: "normal",
                      height: "auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    value="SMALL"
                  >
                    Small 10-49
                  </Radio.Button>
                  <Radio.Button
                    style={{
                      whiteSpace: "normal",
                      height: "auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    value="MEDIUM"
                  >
                    Medium 50-249
                  </Radio.Button>
                  <Radio.Button
                    style={{
                      whiteSpace: "normal",
                      height: "auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    value="LARGE"
                  >
                    Large 249+
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} xl={24}>
              <Form.Item
                className="form-item adrField"
                label="Full name"
                name="userName"
                rules={[
                  { required: true, message: "Full name is required" },
                  {
                    pattern: new RegExp(/^[a-zA-Z_ ]*$/),
                    message:
                      "Field does not accept numbers or special characters",
                  },
                ]}
              >
                <Input placeholder="Full name" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} xl={24}>
              <Form.Item
                className="form-item adrField"
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Email is required" },
                  { type: "email", message: "Enter valid email" },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} xl={24}>
              <Form.Item
                className="form-item adrField"
                label="Phone number"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Phone number is required",
                  },
                  {
                    pattern: /^[0-9\s]+$/,
                    message: "Please enter only numbers.",
                  },
                ]}
              >
                <Input placeholder="Phone number" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} xl={24}>
              <Form.Item
                className="form-item"
                label="Organisation website"
                name="websiteLink"
              >
                <Input placeholder="Website" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} xl={24}></Col>
            <Col className="gutter-row" xs={24} xl={24}>
              <div className="submitBtn">
                <Button type="primary" htmlType="submit">
                  Next
                </Button>
              </div>
            </Col>
          </Form>
          {/* </Row> */}
        </div>
      </div>
    </motion.div>
  );
}
