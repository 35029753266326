import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Tag, Spin } from "antd";
import { toast } from "react-toastify";
import userapiservice from "../../api/userapiservice";
import { getRequest } from "../../api/http";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import ytPlayBtn from "../../assets/images/YouTube_play_button.svg"
import ModalPlayer from "../../components/PlayerModal/playerModal";
import "./experienceOfWork.scss"

export default function ExperienceOfWork({ ChangeShowDetailHandler, changeTab }) {
  const [loading, setLoading] = useState(true);
  const [selectedOption, setselectOption] = useState({});
  const [data, setData] = useState([{}]);
  const [videoCompleted, setVideoCompleted] = useState(false);
  const [activeKey, setActiveKey] = useState("work-experience");
  const [responseData, setReponseData] = useState([]);
  const [mediatype, setmediatype] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  // const { id } = useParams();
  const id = localStorage.getItem("micrositeID");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const color = ["#E75E0D", "#EB9F11", "#ABC710", "#009340", "#CC0370", "#8366A9", "#149BD7"];



  const getExperienceOfWork = async () => {
    try {
      setLoading(true);
      // const headers = {
      //   Authorization: `Bearer ${localStorage.getItem("token")}`,
      // };
      if (id) {
        const response = await getRequest(
          userapiservice.getExperienceOfWork(id)
        );
        setReponseData(response);

        setLoading(false);
      }
    } catch (error) {
      toast.error(error.error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getExperienceOfWork(activeKey, id);
  }, [activeKey, id]);

  // console.log("eow", responseData)

  const PlayVideoHandler = (url, mediaType) => {
    setmediatype(mediaType)
    setVideoUrl(url);
    setIsModalOpen(true);
  };


  const convertToStandardURL = (shortUrl) => {
    if (shortUrl?.indexOf('youtu.be') !== -1) {
      const video_id = shortUrl?.split('youtu.be/')[1].split('?')[0];
      return `https://www.youtube.com/watch?v=${video_id}`;
    }
    return shortUrl;
  }

  const PlayVimeoVideoHandler = (url, mediaType) => {
    setmediatype(mediaType)
    setVideoUrl(url);
    setIsModalOpen(true);
  };
  const onCardClickHandler = (data, id) => {
    // console.log("onclick", data)
    if (data.experienceOfWorkOpportunityOutput?.media) {
      const payload = {};
      payload['description'] = data.experienceOfWorkOpportunityOutput.description;
      payload['title'] = data.experienceOfWorkTitle;
      payload['mediaLink'] = {};
      payload['mediaLink']['mediaType'] = data.experienceOfWorkOpportunityOutput.media.mediaType;
      payload['mediaLink']['url'] = data.experienceOfWorkOpportunityOutput.media.url;
      navigate(`/microsite/${data.id}/micrositeDetails`, {
        state: { page: activeKey, data: payload },
      });
    } else {
      changeTab();
    }
  };
  const handleOk = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setVideoUrl("");
  };

  if (loading) {
    return (
      <div className="loader">
        <Spin tip="Loading" size="large" />
      </div>
    )
  } else {
    return (
      <div className="background-container">
        <div className="card-container">
          {/* {localStorage.getItem("prof") === "944c9d49-a40b-4d7e-8911-a8f3f945e3c1" &&
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px", marginBottom: "10px" }}>
              <Button type="primary"
                htmlType="submit"
                onClick={() => download()}
              >
                Download Insurance document
              </Button>
            </div>
          } */}
          <Row
            className="rowBody preview-container preview-tab-container"
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            {activeKey === "work-experience" &&
              responseData &&
              responseData.length > 0
              ? responseData.map((data, i) => {
                return (
                  <motion.div initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                      duration: 0.8,
                      delay: 0.5,
                      ease: [0, 0.71, 0.2, 1.01]
                    }}>
                    <Col className="card-item-container" style={{ borderLeft: `5px solid ${color[(i % 7)]}`, maxHeight: '100px', paddingLeft: '8px', paddingRight: '8px' }}>
                      <Col
                        className="gutter-row tabContent info-labels"
                        span={15}
                        onClick={() => {
                          onCardClickHandler(data);
                        }}
                        style={{ display: "flex", justifyContent: "space-between", padding: '0', flexDirection: 'column' }}
                      >

                        <span style={{ fontSize: '14px', fontWeight: '600', wordBreak: 'break-word' }} >
                          {data.experienceOfWorkTitle}
                        </span>
                        <div>
                          <Tag color="blue">
                            {data.experienceOfWorkOpportunityTitle === "VIRTUAL" ? "Virtual" : "Face to face"}
                          </Tag>
                        </div>
                      </Col>
                      {data.experienceOfWorkOpportunityOutput?.media?.url ? (
                        <Col className="gutter-row"
                          xs={8}
                          style={{ paddingRight: "0px", paddingLeft: "0px", display: "flex", justifyContent: "center" }}
                        >
                          {data.experienceOfWorkOpportunityOutput?.media?.mediaType == "SOCIAL_MEDIA" ? (
                            <div
                              className="sliderImg videoOverlay"

                              onClick={PlayVideoHandler.bind(
                                this,
                                data.experienceOfWorkOpportunityOutput?.media?.url,
                                data.experienceOfWorkOpportunityOutput?.media?.mediaType
                              )}
                            >
                              {(() => {
                                const standardURL = convertToStandardURL(
                                  data.experienceOfWorkOpportunityOutput?.media?.url
                                );
                                const videoID = new URLSearchParams(
                                  new URL(standardURL).search
                                ).get("v");
                                return (
                                  <>
                                    <img
                                      src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                                      style={{ objectFit: "cover" }}
                                      alt=""
                                      className="video-preview-image height-auto-important"
                                    />
                                    <img
                                      className="ytBtnOverlay"
                                      src={ytPlayBtn}
                                      onClick={PlayVideoHandler.bind(
                                        this,
                                        data.experienceOfWorkOpportunityOutput?.media?.url,
                                        data.experienceOfWorkOpportunityOutput?.media?.mediaType
                                      )}
                                    />
                                  </>
                                );
                              })()}
                              {/* <img
                                // src={VideoImg}
                                src={
                                  data.experienceOfWorkOpportunityOutput?.media?.url.includes('=') ?
                                    `https://img.youtube.com/vi/${((data.experienceOfWorkOpportunityOutput?.media?.url?.split('/')[3])?.split('&')[0])?.split('=')[1]}/hqdefault.jpg`
                                    : `https://img.youtube.com/vi/${data.experienceOfWorkOpportunityOutput?.media?.url?.split('/')[3]}/hqdefault.jpg`
                                }
                                alt=""
                                style={{ objectFit: "cover" }}
                                className="video-preview-image"
                                onClick={PlayVideoHandler.bind(
                                  this,
                                  data.experienceOfWorkOpportunityOutput?.media?.url,
                                  data.experienceOfWorkOpportunityOutput?.media?.mediaType
                                )}
                              />
                              <img
                                className="ytBtnOverlay"
                                src={ytPlayBtn}
                                onClick={PlayVideoHandler.bind(
                                  this,
                                  data.experienceOfWorkOpportunityOutput?.media?.url,
                                  data.experienceOfWorkOpportunityOutput?.media?.mediaType
                                )}
                              /> */}
                            </div>
                          ) : data?.experienceOfWorkOpportunityOutput?.media?.mediaType == "VIMEO_MEDIA" ? (
                            <div
                              className="sliderImg videoOverlay"
                              onClick={PlayVimeoVideoHandler.bind(
                                this,
                                data.experienceOfWorkOpportunityOutput?.media?.url,
                                data.experienceOfWorkOpportunityOutput?.media?.mediaType
                              )}
                            >
                              <img
                                // style={{ objectFit: "contain" }}
                                // src={VideoImg}
                                src={`https://vumbnail.com/${data.experienceOfWorkOpportunityOutput?.media?.url.split('/')[4]}.jpg`}
                                alt=""
                                className="video-preview-image"
                                onClick={PlayVimeoVideoHandler.bind(
                                  this,
                                  data.experienceOfWorkOpportunityOutput?.media?.url,
                                  data.experienceOfWorkOpportunityOutput?.media?.mediaType
                                )}
                              />
                              <img
                                className="ytBtnOverlay"
                                src={ytPlayBtn}
                                onClick={PlayVimeoVideoHandler.bind(
                                  this,
                                  data.experienceOfWorkOpportunityOutput?.media?.url,
                                  data.experienceOfWorkOpportunityOutput?.media?.mediaType
                                )}
                              />
                            </div>
                          ) : data?.experienceOfWorkOpportunityOutput?.media?.mediaType == "IMAGE" ? (
                            <div className="sliderImg">
                              <img
                                src={data.experienceOfWorkOpportunityOutput?.media?.url}
                                className="preview-img height-auto-important"
                                onClick={() => {
                                  onCardClickHandler(data);
                                }}
                              />
                            </div>
                          ) : <div style={{ height: "85px" }}></div>}
                        </Col>
                      ) : (
                        <Col className="gutter-row" span={8}>
                        </Col>
                      )}
                    </Col>
                  </motion.div>
                );
              })
              :
              <div className="background-container" style={{ width: "100%" }}>
                <div className="login-wall">
                  <div className="info-container" >
                    {/* <img src={lockIcon} alt="Partner Logo" /> */}
                    <span>No experience of work available at the moment</span>
                    {/* <a onClick={() => navigate("/login", { state: { id } })}>Login</a> */}
                  </div>
                </div>
              </div>
            }
          </Row>
        </div>
        {(isModalOpen) ? (
          <ModalPlayer closeModal={handleCancel} mediaLink={videoUrl} mediaType={mediatype} />
        ) : ""}
      </div >
    );
  }
}
