import React, { useState, useMemo, useEffect } from "react";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { Tabs, Dropdown } from "antd";
import { motion } from "framer-motion";
import "./microsites.scss";
import AboutUs from "../microsites/aboutUs";
import Products from "./products";
import Services from "./services";
import Contracts from "./contracts";
import Apprenticeships from "./apprenticeships";
import ExperienceOfWork from "./experienceOfWork";
import Vacancies from "./vacancies";
import ContactUs from "./contactUs";
import WhyWork from "./whyUs";
import TeamsAndRoles from "./teamsAndRoles";
import Courses from "./courses";
import ProfileDropdown from "../../components/ProfileDropdown/ProfileDropdown";
import userapiservice from "../../api/userapiservice";
import { getRequest, postRequest, deleteRequest } from "../../api/http";
import { toast } from "react-toastify";
import { RWebShare } from "react-web-share";
import shareIcon from "../../assets/images/share-icon.svg";
import bookmarkedFilled from "../../assets/images/bookmark.svg";
import bookmarkOutline from "../../assets/images/bookmarkOutline.svg";
import OrganisationLogin from "../../assets/images/organisationLogin.svg";
import feedbackIcon from "../../assets/images/feedback.svg";
import userLogin from "../../assets/images/userLogin.svg";
import userLogout from "../../assets/images/userLogout.svg";
import helpIcon from "../../assets/images/helpIcon.svg";
import useComponentVisible from "../login/useComponentVisible";
import Leftarrow from "../../assets/images/Leftarrow.svg";
import Carousel from "../../components/Slider/slider";
import News from "./News";
import AboutUsComponent from "../microsites/aboutUs";

export default function MicrositePage() {
  const navigate = useNavigate();
  const [microSiteId, setMicroSiteId] = useState(null);
  const location = useLocation();
  const { state } = location;
  const page =
    state?.page === "home"
      ? "home"
      : state?.page === "map"
        ? "map"
        : state?.page === "favorites"
          ? "favorites"
          : "home";
  const [logo, setLogo] = useState();
  const [isShowProfile, setIsShowProfile] = useState(false);
  const [activeKey, setActiveKey] = useState("about-us");
  const [loading, setLoading] = useState(true);
  const [showDetail, setShowDetail] = useState(false);
  const [siteSetting, setSiteSitting] = useState(null);
  const [bookmarked, setBookmarked] = useState(false);
  const [bookmarkData, setBookmarkData] = useState(null);
  const [detailsData, setDetailsData] = useState({});
  const [carouselData, setCarouselData] = useState([]);
  const { isComponentVisible, setIsComponentVisible } = useComponentVisible(
    true,
    setIsShowProfile
  );
  const [position, setPosition] = useState(["left", "right"]);
  const [showVac, setShowVac] = useState(false);
  const [hideBackBtn, setHideBackBtn] = useState(true);
  // using useEffect to set values from local storage
  const [partner, setPartner] = useState('')
  const [partnerId, setPartnerId] = useState('')
  const [micrositeId, setMicrositeId] = useState('')
  const [phoneNumber, setPhoneNumber] = useState("");
  const [accessToken, setAccessToken] = useState('')
  const [age, setAge] = useState('')

  const ageCheck = () => {
    const age = localStorage.getItem("age");
    if (age > 18) {
      setShowVac(true);
    }
  };

  const TAB_KEYS = {
    corousel: "corousel",
    aboutUs: "about-us",
    product: "product",
    service: "service",
    contract: 'contract',
    workExperience: "work-experience",
    contactUs: "contact-us",
    apprenticeship: "PART_TIME",
    vacancy: "FULL_TIME",
    courses: "courses",
    whyWork: "why-work",
    news: 'news'
  };

  const tabList = [
    {
      tabName: "About Us",
      tabKey: TAB_KEYS.aboutUs,
      settingsKey: TAB_KEYS.aboutUs,
    },
    {
      tabName: "Products",
      tabKey: TAB_KEYS.product,
      settingsKey: "products",
    },
    {
      tabName: "Services",
      tabKey: TAB_KEYS.service,
      settingsKey: "services",
    },
    {
      tabName: "Contracts",
      tabKey: TAB_KEYS.contract,
      settingsKey: "contracts",
    },
    {
      tabName: "Contact Us",
      tabKey: TAB_KEYS.contactUs,
      settingsKey: TAB_KEYS.contactUs,
    },
    {
      tabName: "Apprenticeships",
      tabKey: "apprenticeship",
      settingsKey: "apprenticeships",
    },
    {
      tabName: "Vacancies",
      tabKey: "vacancy",
      settingsKey: "vacancies",
    },
    {
      tabName: "Courses",
      tabKey: TAB_KEYS.courses,
      settingsKey: "courses",
    },
    {
      tabName: "Why work for us",
      tabKey: TAB_KEYS.whyWork,
      settingsKey: "whyWork",
    },
    {
      tabName: "Our teams and roles",
      tabKey: "teamsAndRoles",
      settingsKey: "teamsAndRoles",
    },
    {
      tabName: "Experiences of work",
      tabKey: TAB_KEYS.workExperience,
      settingsKey: "experienceOfWork",
    },
    {
      tabName: "News",
      tabKey: TAB_KEYS.news,
      settingsKey: 'newsTab',
    },
  ];

  const findValue = (id) => {
    switch (id) {
      case "1":
        return "about-us";
        break;
      case "2":
        return "products";
        break;
      case "3":
        return "services";
        break;
      case "4":
        return "contact-us";
        break;
      case "5":
        return "Apprenticeships";
        break;
      case "6":
        return "vacancies";
        break;
      case "7":
        return "experienceOfWork";
        break;
      case "8":
        return "corousel";
        break;
      case "9":
        return "whyWork";
        break;
      case "10":
        return "teamsAndRoles";
      case "11":
        return "courses";
      default:
        break;
    }
  };

  // Get microsite ID using the microsite name from URL path
  const getMicrositeDetails = async () => {
    // console.log("micrositeDetails")
    try {
      const micrositeName = location.pathname.split("/")[2];
      // console.log("micnam", micrositeName);
      const response = await getRequest(
        userapiservice.getMicrositeDetailsByName(micrositeName)
      );
      if (response) {
        setMicroSiteId(response[0].id);
        localStorage.setItem("micrositeID", response[0].id);
      }
    } catch (error) {
      toast.error(error.error);
    }
  };

  // Get header logo
  const getMicrositeLogo = async () => {
    // console.log("getMicrositeLogo");
    try {
      const response = await getRequest(userapiservice.getMicrositeLogo(microSiteId));
      // console.log(response)
      setLogo(response.mediaLink);
    } catch (error) {
      toast.error(error.error);
    }
  };

  const postBookmark = async () => {
    // console.log("getMicrositeLogo");
    try {
      const response = await postRequest(
        userapiservice.postBookmark(microSiteId, phoneNumber)
      );
      // console.log(response)
      if (response) {
        toast.success("Added to favorites");
        // setBookmarkData(response);
      }
    } catch (error) {
      toast.error(error.error);
    }
  };

  const getBookmark = async () => {
    // console.log("getMicrositeLogo");
    try {
      const response = await getRequest(
        userapiservice.getBookmark(phoneNumber, partner)
      );
      // console.log("res", response);
      if (response) {
        response.microsites.map((microsite) => {
          if (
            localStorage.getItem("micrositeID") &&
            microsite.id === localStorage.getItem("micrositeID")
          ) {
            setBookmarked(true);
          }
        });
      }
    } catch (error) {
      toast.error(error.error);
    }
  };

  useEffect(() => {
    if (phoneNumber && partner) {
      getBookmark();
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("accessToken")) { setAccessToken(localStorage.getItem("accessToken")) }
    if (localStorage.getItem("partner")) { setPartner(localStorage.getItem("partner")) }
    if (localStorage.getItem('partnerId')) { setPartnerId(localStorage.getItem('partnerId')) }
    if (localStorage.getItem("micrositeID")) { setMicrositeId(localStorage.getItem("micrositeID")) }
    if (localStorage.getItem("Phone Number")) { setPhoneNumber(localStorage.getItem("Phone Number")) }
    if (localStorage.getItem("age")) { setAge(localStorage.getItem("age")) }

    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.get('partnerId')) {
      setHideBackBtn(false)
      localStorage.setItem('sharedSite', false)
    }
    if (urlParams.get('partnerId')) {
      setPartnerId(urlParams.get('partnerId'))
      // set as shared site to hide login/logout drop down in micrositeDetails
      localStorage.setItem('sharedSite', true)
    }
  }, [])


  const removeBookmark = async () => {
    // console.log("getMicrositeLogo");
    try {
      const response = await deleteRequest(
        userapiservice.removeBookmark(microSiteId, phoneNumber)
      );

      if (response) {
        toast.success("Removed from favorites");
      }
    } catch (error) {
      toast.error(error.error);
    }
  };

  // Get carousel data
  const getTabData = async (activeKey, id) => {
    // console.log("getCarouselTabData")
    try {
      setLoading(true);
      let activeKeyLabel = findValue(activeKey);
      const response = await getRequest(
        userapiservice.getMicrositeDetailsTabs(id, activeKeyLabel)
      );
      if (response) {
        setCarouselData(response);
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.error);
      setLoading(false);
    }
  };

  const onClickArrow = (direction, site) => {
    // console.log(tabList);

    const modifiedTabLists = tabList.filter(
      (d) =>
        !(d.settingsKey in site) ||
        (d.settingsKey in site && site[d.settingsKey] !== false)
    );

    const getIndex = modifiedTabLists.findIndex((d) => d.tabKey == activeKey);

    if (getIndex !== -1) {
      if (direction === "right" && modifiedTabLists[getIndex + 1].tabKey) {
        // console.log(modifiedTabLists[getIndex + 1].tabKey);
        setActiveKey(modifiedTabLists[getIndex + 1].tabKey);
      } else if (
        direction === "left" &&
        modifiedTabLists[getIndex - 1].tabKey
      ) {
        setActiveKey(modifiedTabLists[getIndex - 1].tabKey);
      }
    }
  };

  const changeTab = (tabValue) => {
    localStorage.setItem("tabValue", tabValue);
    setActiveKey(tabValue);
  };

  const handleBookmark = () => {
    if (!bookmarked) {
      postBookmark();
      setBookmarked(true);
    } else {
      removeBookmark();
      setBookmarked(false);
    }
  };

  const LogoutHandler = () => {
    localStorage.clear();
    navigate(`/`);
  };

  const callViewedAPI = async (micrositeId) => {
    // console.log("in API func", micrositeId)
    let temp = {
      ageGroupId: localStorage.getItem("ageID"),
      tagId: localStorage.getItem("profID"),
      latitude: localStorage.getItem("lat"),
      longitude: localStorage.getItem("lng"),
      countryName: localStorage.getItem("country"),
      countyName: localStorage.getItem("county"),
      state: localStorage.getItem("state")
    }
    try {
      const res = await postRequest(
        userapiservice.micrositeViewCount(micrositeId), temp
      );
      if (res) {
        sessionStorage.setItem("viewed", "viewed");
      }
    } catch {
      console.log("error in microsite view");
    }
  };


  useEffect(() => {
    const micrositeViewed = sessionStorage.getItem("viewed");
    // console.log("micrositeViewed", micrositeViewed)
    if (micrositeId && micrositeViewed != "viewed") {
      callViewedAPI(micrositeId);
    }
  }, [micrositeId]);

  const OperationsSlot = {
    left: (
      <div onClick={() => onClickArrow("left", siteSetting)}>
        <img
          className="arrow-nav-btn"
          src={require("../../assets/images/Path3954.png")}
          alt="main logo"
          width="auto"
        />
        <img
          className="arrow-nav-btn"
          src={require("../../assets/images/Path3954.png")}
          alt="main logo"
          width="auto"
        />
      </div>
    ),
    right: (
      <div onClick={() => onClickArrow("right", siteSetting)}>
        <img
          className="arrow-nav-btn"
          src={require("../../assets/images/Path3900.png")}
          alt="main logo"
          width="auto"
        />
        <img
          className="arrow-nav-btn"
          src={require("../../assets/images/Path3900.png")}
          alt="main logo"
          width="auto"
        />
      </div>
    ),
  };

  const slot = useMemo(() => {
    if (position.length === 0) return null;
    return position.reduce(
      (acc, direction) => ({
        ...acc,
        [direction]: OperationsSlot[direction],
      }),
      {}
    );
  }, [position, activeKey, siteSetting]);

  const ChangeShowDetailHandler = (data) => {
    setDetailsData(data);
    setShowDetail(true);
  };

  const changeTabtoContactUs = () => {
    setActiveKey("contact-us");
    localStorage.setItem("contact-tabValue", "EXPERIENCES_OF_WORK");
  };

  const changeTabtoContactUsHR = () => {
    setActiveKey("contact-us");
    localStorage.setItem("contact-tabValue", "HUMAN_RESOURCE");
  }

  const BackButtonHandler = (tabValue) => {
    localStorage.removeItem("contact-tabValue");
    localStorage.removeItem("micrositeID");
    localStorage.removeItem("micrositeName");
    sessionStorage.clear();
    if (page === "home") {
      navigate("/home");
      // localStorage.removeItem("tabValue", tabValue);
    } else if (page === "map") {
      navigate("/view");
      // localStorage.removeItem("tabValue", tabValue);
    } else if (page === "favorites") {
      navigate("/favorites");
    } else {
      navigate("/home");
    }
    // navigate(-1);
  };

  const getSiteSetting = async () => {
    try {
      if (microSiteId) {
        const { settings: response } = await getRequest(userapiservice.getsettings(microSiteId, partnerId));
        if (response) {
          if (age > 18) {
            setSiteSitting(response);
          } else {
            let tempObj = {
              ...response,
              vacancies: false,
              contract: false,
            };
            setSiteSitting(tempObj);
          }
          // console.log(response);
        }
      }
    } catch (error) {
      toast.error(error.error);
    }
  };

  const closeDropdown = () => {
    setIsShowProfile(!isShowProfile);
  };

  useEffect(() => {
    let tabVal = localStorage.getItem("tabValue");
    if (tabVal) {
      setActiveKey(tabVal);
    }
    getMicrositeDetails();
  }, []);

  useEffect(() => {
    ageCheck();
  }, [activeKey, microSiteId]);

  useEffect(() => {
    if (microSiteId) {
      getSiteSetting();
      getMicrositeLogo();
      getTabData("8", microSiteId);
    }
  }, [microSiteId]);

  const changeAgeHandler = () => {
    localStorage.clear();
    localStorage.setItem("changeAge", true)
    navigate(`/`);
  }

  return (
    <motion.div
      className="background-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="microsite-header">
        <div className="back-btn" type="link" style={{ visibility: `${hideBackBtn ? 'hidden' : "visible"}` }} onClick={BackButtonHandler}>
          <img src={Leftarrow} />
        </div>
        <div
          className="header-org-logo"
          type="link"
        // onClick={BackButtonHandler}
        >
          <img src={logo} />
        </div>
        {/* <button
          disabled={disable}
          className="microsite-drop-btn"
          onClick={() => showProfile()}
        >
          <MenuUnfoldOutlined />
        </button> */}
        <div style={{ visibility: `${hideBackBtn ? 'hidden' : "visible"}` }}>
          <Dropdown
            trigger={["click"]}
            placement="bottomRight"
            overlay={
              <div
                className="dropdown"
                style={{
                  border: "1px solid #569dfa",
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                  width: "190px",
                }}
              >

                {/* ORG LOGIN */}
                <div
                  style={{
                    padding: "10px",
                    borderBottom: "1px solid #f0f0f0",
                    cursor: "pointer",
                  }}
                >
                  <a
                    href={`https://orgadmin.expo365.app/login/${localStorage.getItem("partnerId")}`}
                    target="_blank"
                    style={{ color: "black", cursor: "pointer" }}
                  >
                    <div
                      className="dropdown-items"
                      style={{
                        display: "flex",
                        alignContent: "center",
                      }}
                    >
                      <img src={OrganisationLogin} alt="main logo" />
                      <label style={{ marginLeft: "10px", cursor: "pointer" }}>
                        Organisation Login
                      </label>
                    </div>
                  </a>
                </div>

                {/* HELP */}
                <div
                  style={{
                    padding: "10px",
                    borderBottom: "1px solid #f0f0f0",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className="dropdown-items"
                    style={{
                      display: "flex",
                      alignContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/help")}
                  >
                    <img src={helpIcon} alt="main logo" />
                    <label style={{ marginLeft: "10px", cursor: "pointer" }}>
                      Help
                    </label>
                  </div>
                </div>

                {/* SUPPORT & FEEDBACK */}
                {age === "23" || age === "37" || age === "53" ? (
                  <div
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #f0f0f0",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="dropdown-items"
                      style={{
                        display: "flex",
                        alignContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/feedback");
                        localStorage.setItem("feedback", "feedback");
                      }}
                    >
                      <img src={feedbackIcon} alt="main logo" />
                      <label style={{ marginLeft: "10px", cursor: "pointer" }}>
                        Support & Feedback
                      </label>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {(age === "23" || age === "37" || age === "53") && !phoneNumber && !accessToken ? (
                  <div
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #f0f0f0",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="dropdown-items"
                      style={{
                        display: "flex",
                        alignContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        navigate("/login", { state: { page: "home" } })
                      }
                    >
                      <img src={userLogin} alt="main logo" />
                      <label style={{ marginLeft: "10px", cursor: "pointer" }}>
                        User Login
                      </label>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {/* USER LOGOUT */}
                {age === "23" || age === "37" || age === "53" ? (
                  <div
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #f0f0f0",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="share-dropdown"
                      style={{
                        display: "flex",
                        alignContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={LogoutHandler}
                    >
                      <img src={userLogout} alt="main logo" />
                      <label style={{ marginLeft: "10px", cursor: "pointer" }}>
                        User Logout
                      </label>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #f0f0f0",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="share-dropdown"
                      style={{
                        display: "flex",
                        alignContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={changeAgeHandler}
                    >
                      <img src={userLogout} alt="main logo" />
                      <label style={{ marginLeft: "10px", cursor: "pointer" }}>
                        Change age
                      </label>
                    </div>
                  </div>
                )}
              </div>
            }
          >
            <div className="microsite-drop-btn">
              <MenuUnfoldOutlined />
            </div>
          </Dropdown>
        </div>
      </div>
      <div className="spl-container">
        <div className="shareBar">
          {phoneNumber && accessToken ? (
            bookmarked ? (
              <div onClick={handleBookmark}>
                <img src={bookmarkedFilled} alt="bookmark" />
              </div>
            ) : (
              <div onClick={handleBookmark}>
                <img src={bookmarkOutline} alt="bookmark" />
              </div>
            )
          ) : (
            <div style={{ width: "17px" }}></div>
          )}
          <div className="carousel-container">
            <Carousel carouselData={carouselData} />
          </div>
          <div>
            <RWebShare
              data={{
                url: `${window.location.origin}${location.pathname}?partnerId=${partnerId}`,
              }}
            >
              <img
                src={shareIcon}
                alt="Share"
                style={{ width: 25, height: 25 }}
              />
            </RWebShare>
          </div>
        </div>
        <div className="container">
          <div>
            {siteSetting && (
              <Tabs
                onChange={changeTab}
                activeKey={activeKey}
                defaultActiveKey="about-us"
                tabBarExtraContent={slot}
                className="microsite-tabs"
                type="card"
                destroyInactiveTabPane
              >
                {siteSetting && (
                  <Tabs.TabPane
                    tab="About Us"
                    key="about-us"
                    className="tab-bg"
                  >
                    <AboutUsComponent
                      ChangeShowDetailHandler={ChangeShowDetailHandler}
                    />
                  </Tabs.TabPane>
                )}
                {siteSetting && siteSetting.products ? (
                  <Tabs.TabPane tab="Products" key="product" className="tab-bg">
                    <Products
                      ChangeShowDetailHandler={ChangeShowDetailHandler}
                    />
                  </Tabs.TabPane>
                ) : null}
                {siteSetting && siteSetting?.services ? (
                  <Tabs.TabPane tab="Services" key="service" className="tab-bg">
                    <Services
                      ChangeShowDetailHandler={ChangeShowDetailHandler}
                    />
                  </Tabs.TabPane>
                ) : null}
                {siteSetting && siteSetting?.contracts ? (
                  <Tabs.TabPane tab="Contracts" key="contract" className="tab-bg">
                    <Contracts
                      ChangeShowDetailHandler={ChangeShowDetailHandler}
                    />
                  </Tabs.TabPane>
                ) : null}
                {siteSetting && (
                  <Tabs.TabPane
                    tab="Contact Us"
                    key="contact-us"
                    className="tab-bg"
                  >
                    <ContactUs
                      ChangeShowDetailHandler={ChangeShowDetailHandler}
                    />
                  </Tabs.TabPane>
                )}

                {siteSetting && siteSetting?.apprenticeships ? (
                  <Tabs.TabPane
                    tab="Apprenticeships"
                    key="apprenticeship"
                    className="tab-bg"
                  >
                    <Apprenticeships
                      ChangeShowDetailHandler={ChangeShowDetailHandler}
                    />
                  </Tabs.TabPane>
                ) : null}
                {siteSetting && showVac === true && siteSetting?.vacancies ? (
                  <Tabs.TabPane
                    tab="Vacancies"
                    key="vacancy"
                    className="tab-bg"
                  >
                    <Vacancies
                      ChangeShowDetailHandler={ChangeShowDetailHandler}
                    />
                  </Tabs.TabPane>
                ) : null}
                {siteSetting && siteSetting?.courses ? (
                  <Tabs.TabPane tab="Courses" key="courses" className="tab-bg">
                    <Courses />
                  </Tabs.TabPane>
                ) : null}
                {siteSetting && siteSetting?.whyWork ? (
                  <Tabs.TabPane
                    tab="Why work for us"
                    key="why-work"
                    className="tab-bg"
                  >
                    <WhyWork
                      ChangeShowDetailHandler={ChangeShowDetailHandler}
                      changeTab={changeTabtoContactUsHR}
                    />
                  </Tabs.TabPane>
                ) : null}
                {siteSetting && siteSetting?.teamsAndRoles ? (
                  <Tabs.TabPane
                    tab="Our teams and roles"
                    key="teamsAndRoles"
                    className="tab-bg"
                  >
                    <TeamsAndRoles />
                  </Tabs.TabPane>
                ) : null}
                {siteSetting && siteSetting?.experienceOfWork ? (
                  <Tabs.TabPane
                    tab="Experiences of Work"
                    key="work-experience"
                    className="tab-bg"
                  >
                    <ExperienceOfWork
                      ChangeShowDetailHandler={ChangeShowDetailHandler}
                      changeTab={changeTabtoContactUs}
                    />
                  </Tabs.TabPane>
                ) : null}


                {siteSetting && siteSetting?.newsTab && (
                  <Tabs.TabPane
                    tab="News"
                    key="news"
                    className="tab-bg"
                  >
                    <News />
                  </Tabs.TabPane>
                )}
              </Tabs>
            )}
            {/* <Tabs /> */}
          </div>
        </div>
      </div>
      {isShowProfile ? (
        <div>
          <ProfileDropdown closeDropdown={closeDropdown} />
        </div>
      ) : null}
    </motion.div>
  );
}
