import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Input, Button } from "antd";
import { toast } from "react-toastify";
import userapiservice from "../../api/userapiservice";
import { getRequest } from "../../api/http";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import ytPlayBtn from "../../assets/images/YouTube_play_button.svg"
import ModalPlayer from "../../components/PlayerModal/playerModal";
import './news.scss'
import { formatDate } from "../../utils/helper";
import { SearchOutlined } from "@ant-design/icons";


function formatTitle(title) {

    if (title.length > 50) {
        return title.substring(0, 50) + '...';
    } else {
        return title;
    }
}

const News = () => {
    const color = ["#E75E0D", "#EB9F11", "#ABC710", "#009340", "#CC0370", "#8366A9", "#149BD7"];
    const id = localStorage.getItem("micrositeID");
    const [activeKey, setActiveKey] = useState("news_tab");
    const [mediatype, setmediatype] = useState("");

    const [loading, setLoading] = useState(true);
    const [responseData, setReponseData] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");

    const [textSearch, setTextSearch] = useState('')
    const navigate = useNavigate();

    const onCardClickHandler = (data, id) => {
        navigate(`/microsite/${data.id}/micrositeDetails`, {
            state: { page: activeKey, data: data },
        });
    };

    const getServicesData = async (activeKey, id, searchKeyword) => {
        try {
            setLoading(true);
            // const headers = {
            //   Authorization: `Bearer ${localStorage.getItem("token")}`,
            // };
            if (id) {
                const response = await getRequest(searchKeyword === '' ?
                    userapiservice.getMicrositeDetailsTabs(id, activeKey) :
                    userapiservice.getNewsByDate(id, searchKeyword)
                );
                setReponseData(response);
                // console.log(response)
            }
        } catch (error) {
            toast.error(error.error);
        }
        finally {
            setLoading(false);
        }
    };

    const PlayVimeoVideoHandler = (url, mediaType) => {
        setmediatype(mediaType)
        setVideoUrl(url);
        setIsModalOpen(true);
    };


    const PlayVideoHandler = (url, mediaType) => {
        setmediatype(mediaType)
        setVideoUrl(url);
        setIsModalOpen(true);
    };

    const convertToStandardURL = (shortUrl) => {
        if (shortUrl?.indexOf('youtu.be') !== -1) {
            const video_id = shortUrl?.split('youtu.be/')[1].split('?')[0];
            return `https://www.youtube.com/watch?v=${video_id}`;
        }
        return shortUrl;
    }

    useEffect(() => {

        const handler = setTimeout(() => {
            getServicesData(activeKey, id, textSearch)
        }, 500)
        return () => {
            clearTimeout(handler)
        }

    }, [activeKey, id, textSearch]);

    return (
        <div className="background-container" >
            <div className="card-container">
                <div className="searchContainer" style={{ marginTop: '10px' }}>
                    <Input
                        placeholder=" Search News "
                        prefix={
                            <SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        // type="Date"
                        value={textSearch}
                        onChange={(e) =>
                            setTextSearch(e.target.value)
                        }
                        style={{ maxWidth: '300px' }}
                    />
                    {/* <Button type="primary" icon={<SearchOutlined />} /> */}
                </div>
                {loading ?
                    <div className="loader">
                        <Spin tip="Loading" size="large" />
                    </div>
                    :
                    <Row
                        className="rowBody preview-container preview-tab-container"
                        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        style={{ marginTop: '10px' }}
                    >



                        {activeKey === "news_tab" && responseData && responseData.length > 0
                            ? responseData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((data, i) => {
                                return (
                                    <motion.div key={i} initial={{ opacity: 0, scale: 0.5 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        transition={{
                                            duration: 0.8,
                                            delay: 0.5,
                                            ease: [0, 0.71, 0.2, 1.01]
                                        }}
                                        style={{ display: 'grid' }}
                                    >
                                        <div className="newsCard" style={{
                                            borderLeft: `5px solid ${color[(i % 7)]}`
                                        }}

                                            onClick={() => {
                                                onCardClickHandler(data);
                                            }}
                                        >
                                            <div >
                                                {data.mediaLink?.mediaType == "SOCIAL_MEDIA" && (
                                                    <div
                                                        className=" videoOverlay"
                                                        onClick={() =>
                                                            PlayVideoHandler(
                                                                data.mediaLink?.url,
                                                                data.mediaLink?.mediaType
                                                            )
                                                        }
                                                    >
                                                        {(() => {
                                                            const standardURL = convertToStandardURL(
                                                                data.mediaLink?.url
                                                            );
                                                            const videoID = new URLSearchParams(
                                                                new URL(standardURL).search
                                                            ).get("v");
                                                            return (
                                                                <>
                                                                    <img
                                                                        src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                                                                        style={{ objectFit: "cover" }}
                                                                        alt=""
                                                                        className="news-video-preview-image"
                                                                    />
                                                                    <img
                                                                        className="ytBtnOverlay"
                                                                        src={ytPlayBtn}
                                                                        onClick={() =>
                                                                            PlayVideoHandler(
                                                                                data.mediaLink?.url,
                                                                                data.mediaLink?.mediaType
                                                                            )
                                                                        }
                                                                    />
                                                                </>
                                                            );
                                                        })()}
                                                    </div>
                                                )}
                                                {data?.mediaLink?.mediaType == "VIMEO_MEDIA" && (
                                                    <div
                                                        className=" videoOverlay"
                                                    >
                                                        <img
                                                            src={`https://vumbnail.com/${data.mediaLink.url.split('/')[4]}.jpg`}
                                                            alt=""
                                                            className="news-video-preview-image"
                                                        />
                                                        <img
                                                            className="ytBtnOverlay"
                                                            src={ytPlayBtn}
                                                            onClick={PlayVimeoVideoHandler.bind(
                                                                this,
                                                                data.mediaLink?.url,
                                                                data.mediaLink?.mediaType
                                                            )}
                                                        />
                                                    </div>
                                                )}
                                                {data?.mediaLink?.mediaType === "IMAGE" && (
                                                    <div className="newsSliderImg">
                                                        <img
                                                            src={data.mediaLink?.url}
                                                            className="news-preview-img"
                                                            onClick={PlayVimeoVideoHandler.bind(
                                                                this,
                                                                data.mediaLink?.url,
                                                                data.mediaLink?.mediaType
                                                            )}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div

                                            >
                                                <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }} >
                                                    <span>
                                                        {formatDate(data.createdAt)}
                                                    </span>

                                                    <h4 style={{ margin: 0 }} >
                                                        {formatTitle(data.title)}
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </motion.div>
                                );
                            })
                            :
                            <div className="background-container" style={{ width: "100%" }}>
                                <div className="login-wall">

                                    <div className="info-container" >
                                        <span>No news available at the moment</span>
                                    </div>
                                </div>
                            </div>
                        }
                    </Row>}
            </div>
        </div>
    )
}

export default News