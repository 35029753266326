import React, { useEffect, useState } from "react";
import { Row, Spin, Col } from "antd";
import { toast } from "react-toastify";
import userapiservice from "../../../api/userapiservice";
import { getRequest } from "../../../api/http";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";


export default function Accreditation({ activeKey }) {
    const [loading, setLoading] = useState(true);
    const color = ["#E75E0D", "#EB9F11", "#ABC710", "#009340", "#CC0370", "#8366A9", "#149BD7"];

    const [responseData, setReponseData] = useState([]);
    // const { id } = useParams();
    const id = localStorage.getItem("micrositeID");
    const navigate = useNavigate();

    const getAboutData = async (activeKey, id) => {
        try {
            setLoading(true);

            if (id) {
                const response = await getRequest(
                    userapiservice.getAboutUsDetailsTabs(id, activeKey)
                );
                setReponseData(response);


                setLoading(false);
            }
        } catch (error) {
            toast.error(error.error);
            setLoading(false);
        }
    };

    const onCardClickHandler = (data, id) => {
        navigate(`/microsite/${data.id}/micrositeDetails`, {
            state: {
                page: activeKey, data: data
            },
        });
    };


    useEffect(() => {
        if (activeKey && id) {
            getAboutData(activeKey, id);
        }
    }, []);

    if (loading) {
        return (
            <div className="loader">
                <Spin tip="Loading" size="large" />
            </div>
        )
    } else {
        return (
            <div className="background-container" style={{ width: "100%" }}>
                <div className="card-container">
                    {responseData && responseData.length > 0
                        ? <Row
                            className="rowBody preview-container preview-tab-container"
                            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                        >
                            {responseData.map((data, i) => {
                                if (data.awardsLogos.url)
                                    return <motion.div initial={{ opacity: 0, scale: 0.5 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        transition={{
                                            duration: 0.8,
                                            delay: 0.5,
                                            ease: [0, 0.71, 0.2, 1.01]
                                        }}>
                                        <Col className="card-item-container" style={{ borderLeft: `5px solid ${color[(i % 7)]}`, height: '250px', display: 'flex', justifyContent: 'center' }}>
                                            <Col
                                                className="gutter-row tabContent info-labels"
                                                span={15}
                                                style={{ display: 'flex', justifyContent: 'center' }}

                                                onClick={() => {
                                                    onCardClickHandler({ ...data, mediaLink: data.awardsLogos });
                                                }}
                                            >
                                                <img src={data.awardsLogos.url} style={{ maxWidth: '200px' }} alt="awardsLogos" />
                                            </Col>
                                        </Col>
                                    </motion.div>

                            })

                            }
                        </Row> : <div style={{
                            marginTop: '20px',
                            marginBottom: '10px',
                            textAlign: 'center',
                            width: '100%',
                            fontSize: 'large'
                        }} className="info-container" > Nothing here at the moment </div>}
                </div>
            </div >
        );
    }
}
