import React, { useState, useEffect } from "react";
import { Card, Tag, Button, Dropdown, Row, Col } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import callIcon from "../../assets/images/callIcon2.svg";
import "./micrositeDetails.scss";
import ReactPlayer from "react-player/youtube";
import ProfileDropdown from "../../components/ProfileDropdown/ProfileDropdown";
import Leftarrow from "../../assets/images/Leftarrow.svg";
import OrganisationLogin from "../../assets/images/organisationLogin.svg";
import userLogin from "../../assets/images/userLogin.svg";
import userLogout from "../../assets/images/userLogout.svg";
import helpIcon from "../../assets/images/helpIcon.svg";
import { motion } from "framer-motion";
import { LinkOutlined } from "@ant-design/icons";
import ViewDownloadBrochure from "./ViewAndDownloadBrochure";
import Linkedin from "../../assets/images/linkedIn.svg";
import mail from "../../assets/images/mail.svg";

import Wysisyg from "./Wysisyg";

export default function MicrositeDetailsPage({ data }) {
  const age = localStorage.getItem("age");
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [isShowProfile, setIsShowProfile] = useState(false);
  const [disable, setDisable] = useState(false);
  const location = useLocation();
  const { state } = location;
  const phoneNumber = localStorage.getItem("Phone Number");
  const partner = localStorage.getItem("partner");
  // const userData = JSON.parse(localStorage.getItem("user"));
  const [hideDropDown, setHideDropDown] = useState(true);

  const ageCheck = () => {
    const age = localStorage.getItem("age");
    // console.log(age);
    if (age < 18) {
      setDisable(true);
    }
  };

  const jobTypeTitle = (data) => {
    if (data === "FREELANCING") {
      return "Freelancing";
    }
    if (data === "FULL_TIME") {
      return "Full time";
    }
    if (data === "INTERNSHIP") {
      return "Internship";
    }
    if (data === "PART_TIME") {
      return "Part time";
    }
    if (data === "TEMPORARY") {
      return "Temporary";
    }
    if (data === "REMOTE") {
      return "Remote";
    }
    return "";
  };

  const aboutUsTitle = (data) => {
    if (data === "WhyDo") {
      return "Why do we do what we do ?";
    }
    if (data === "whatDo") {
      return "What do we do ?";
    }
    if (data === "howDo") {
      return "How do we do what we do ?";
    }
    return "";
  };

  const closeDropdown = () => {
    setIsShowProfile(!isShowProfile);
  };

  const phoneHandler = () => {
    localStorage.setItem("tabValue", "contact-us");
    if (state.page === "work-experience") {
      localStorage.setItem("contact-tabValue", "EXPERIENCES_OF_WORK");
    } else if (
      state.page === "vacancy" ||
      state.page === "apprenticeship" ||
      state.page === "why-work"
    ) {
      localStorage.setItem("contact-tabValue", "HUMAN_RESOURCE");
    } else if (state.page === "service" || state.page === "product" || state.page === "news_tab") {
      localStorage.setItem("contact-tabValue", "SALES");
    }
    navigate(-1);
  };

  const LinkHandler = () => {
    const urlLink = state?.data?.urlLink || state?.data?.webLink;

    if (urlLink) {
      // Create a hidden anchor tag
      const anchor = document.createElement("a");
      anchor.href = urlLink;
      anchor.target = "_blank"; // Open the link in a new tab/window if needed

      // Programmatically trigger a click event on the anchor tag
      const clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      anchor.dispatchEvent(clickEvent);
    } else {
      // Handle the case when urlLink is not defined
      console.error("urlLink is not defined.");
    }
  };

  useEffect(() => {
    ageCheck();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("sharedSite")) { setHideDropDown(localStorage.getItem("sharedSite") === 'false' ? false : true) }
  }, []);

  const LogoutHandler = () => {
    // localStorage.removeItem("_grecaptcha");
    // localStorage.removeItem("age");
    // localStorage.removeItem("Phone Number");
    // localStorage.removeItem("tabValue");
    // localStorage.removeItem("accessToken");
    // localStorage.removeItem("sector");
    // localStorage.removeItem("subsector");
    // localStorage.removeItem("size");
    // localStorage.removeItem("Interest/AssetType");
    // localStorage.removeItem("zoom");
    // localStorage.removeItem("lat");
    // localStorage.removeItem("lng");
    // localStorage.removeItem("prof");
    // localStorage.removeItem("micrositeID");
    // localStorage.removeItem("micrositeName");
    // localStorage.removeItem("ageID");
    // localStorage.removeItem("searchName");
    // localStorage.removeItem("OrganisationType");

    localStorage.clear();
    navigate(`/`);
  };

  const changeAgeHandler = () => {
    localStorage.clear();
    localStorage.setItem("changeAge", true)
    navigate(`/`);
  }

  const handleCardTitle = ({ state }) => {
    let title;

    if (state?.page === "about-us") {
      title = aboutUsTitle(state?.data?.assetTitle);
    } else if (state?.page === "courses" || state?.page === "teams-and-roles") {
      title = state?.data?.subTitle;
    }
    else if (state?.page === 'news_tab') {
      title = state?.data?.title
    }
    else {
      title = state?.data?.assetTitle;
    }
    return title
  }


  return (
    <motion.div
      className="background-container spl-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="site-card-border-less-wrapper">
        <div className="microsite-header">
          <div className="back-btn" type="link" onClick={() => navigate(-1)}>
            <img src={Leftarrow} />
          </div>
          <div style={{ visibility: `${(hideDropDown === true) ? 'hidden' : "visible"}` }} >
            <Dropdown
              trigger={["click"]}
              placement="bottomRight"
              overlay={
                <div
                  className="dropdown"
                  style={{
                    border: "1px solid #569dfa",
                    borderRadius: "4px",
                    backgroundColor: "#fff",
                    width: "180px",
                  }}
                >
                  {/* ORG LOGIN */}
                  <div
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #f0f0f0",
                      cursor: "pointer",
                    }}
                  >
                    <a href={`https://orgadmin.expo365.app/login/${localStorage.getItem("partnerId")}`} target="_blank" style={{ color: "black", cursor: "pointer" }}>
                      <div className="dropdown-items"
                        style={{
                          display: "flex",
                          alignContent: "center",
                        }}
                      >
                        <img src={OrganisationLogin} alt="main logo" />
                        <label
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                        >
                          Organisation Login
                        </label>
                      </div>
                    </a>
                  </div>

                  {/* HELP */}
                  <div
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #f0f0f0",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className="dropdown-items"
                      style={{
                        display: "flex",
                        alignContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/help")}
                    >
                      <img src={helpIcon} alt="main logo" />
                      <label style={{ marginLeft: "10px", cursor: "pointer" }}>
                        Help
                      </label>
                    </div>
                  </div>

                  {/* USER LOGIN */}
                  {(age === "23" || age === "37" || age === "53") && !phoneNumber && !accessToken ? (
                    <div
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid #f0f0f0",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className="dropdown-items"
                        style={{
                          display: "flex",
                          alignContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate("/login", { state: { page: "home" } })
                        }
                      >
                        <img src={userLogin} alt="main logo" />
                        <label
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                        >
                          User Login
                        </label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {/* USER LOGOUT */}
                  {age === "23" || age === "37" || age === "53" ? (
                    <div
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid #f0f0f0",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className="share-dropdown"
                        style={{
                          display: "flex",
                          alignContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={LogoutHandler}
                      >
                        <img src={userLogout} alt="main logo" />
                        <label style={{ marginLeft: "10px", cursor: "pointer" }}>
                          User Logout
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid #f0f0f0",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className="share-dropdown"
                        style={{
                          display: "flex",
                          alignContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={changeAgeHandler}
                      >
                        <img src={userLogout} alt="main logo" />
                        <label style={{ marginLeft: "10px", cursor: "pointer" }}>
                          Change age
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              }
            >
              <div className="microsite-drop-btn">
                <MenuUnfoldOutlined />
              </div>
            </Dropdown>
          </div>
        </div>
        {
          state.page !== 'ACCREDITATIONS_AWARDS' ? (state?.data?.mediaLink?.mediaType == "SOCIAL_MEDIA" ? (
            <div className="carousel-video">
              <ReactPlayer
                className="video-player"
                controls={true}
                url={state?.data?.mediaLink?.url}
                height="100%"
                config={{
                  youtube: {
                    playerVars: {
                      fs: 1,
                      showinfo: 0,
                      modestbranding: 0,
                      showinfo: 1,
                      allowfullscreen: true,
                    },
                  },
                }}
              ></ReactPlayer>
            </div>
          ) : state?.data?.mediaLink?.mediaType == "VIMEO_MEDIA" ? (
            <div className="carousel-video">
              <iframe
                controls={true}
                src={state?.data?.mediaLink?.url}
                height="100%"
                width="100%"
                style={{ backgroundColor: "black" }}
              />
              {/* {console.log(state?.data?.mediaLink?.url)} */}
            </div>
          ) : state?.data?.mediaLink?.mediaType == "IMAGE" ? (
            <div className="carousel-img" style={{ marginBottom: '15px', width: '100%' }} >
              <img src={state?.data?.mediaLink?.url} />
            </div>
          ) : (
            <></>
          )) : null
        }

        <Card
          title={
            handleCardTitle({ state })
          }
          bordered={state.page === 'ACCREDITATIONS_AWARDS' ? false : true}
        >

          {(state.page === 'ACCREDITATIONS_AWARDS' && state?.data?.mediaLink?.mediaType == "IMAGE") ? (
            <div className="" style={{ marginBottom: '15px', width: '100%', display: 'flex', justifyContent: 'center', maxHeight: '70vh' }} >
              <img style={{ objectFit: 'contain', width: '100%' }} src={state?.data?.mediaLink?.url} />
            </div>
          ) : null}

          {state.page === 'news_tab' ?
            <Wysisyg state={state} />
            : <p>
              {state?.data?.description !== '' ? <pre>{state?.data?.description}</pre> : null}
            </p>}
          {state.page === "apprenticeship" || state.page === "vacancy" ? (
            <div>
              <div className="type pointers">
                <p className="pointer-headers">
                  {state.page === "apprenticeship"
                    ? "Apprenticeship type:"
                    : "Vacancy type:"}
                  &nbsp;
                </p>
                <p>
                  {state?.data?.apprenticeshipType === "PART_TYPE"
                    ? "Part Time"
                    : "Full Time"}
                  &nbsp;
                </p>
              </div>

              {state?.data?.jobTypeEnum && jobTypeTitle(state?.data?.jobTypeEnum) ? <div className="type pointers">
                <p className="pointer-headers">Job type:&nbsp;</p>
                <p> {jobTypeTitle(state?.data?.jobTypeEnum)}</p>
              </div> : ''}

              <div className="type pointers">
                <p className="pointer-headers">Compensation:&nbsp;</p>
                <p> {state?.data?.compensation}</p>
              </div>

              <div className="type pointers">
                <p className="pointer-headers">Compensation type:&nbsp;</p>
                <p>
                  {" "}
                  {state?.data?.compensationType === "ANNUALLY"
                    ? "Annually"
                    : "Anually"}
                </p>
                {/* <p style={{ margin: "0px" }}>{state?.data?.compensationType}</p> */}
              </div>

              <div className="type pointers">
                <p className="pointer-headers">Closing date:&nbsp;</p>
                <p> {state?.data?.closingDate}</p>
              </div>

              <div
                className="type skills"
                style={{ display: "flex", alignItems: "start" }}
              >
                <p className="skillsLabel pointer-headers">Skills:&nbsp;</p>
                <div className="skillTags">
                  {state?.data?.skills.map((skill) => (
                    <Tag color="red">{skill.name}</Tag>
                  ))}
                </div>
              </div>
            </div>
          ) :
            null
          }
          {state.page === "contract" ? (
            <div>
              {state?.data?.procurementReference ? (
                <div className="type pointers">
                  <p className="pointer-headers">Procurement Reference:&nbsp;</p>
                  <p>{state?.data?.procurementReference}&nbsp;</p>
                </div>
              ) : null}

              {state?.data?.postalCode ? (
                <div className="type pointers">
                  <p className="pointer-headers">Postal code:&nbsp;</p>
                  <p> {state?.data?.postalCode}</p>
                </div>
              ) : null}

              {state?.data?.valueOfContract ? (
                <div className="type pointers">
                  <p className="pointer-headers">Value Of Contract:&nbsp;</p>
                  <p> {state?.data?.valueOfContract}</p>
                  {/* <p style={{ margin: "0px" }}>{state?.data?.compensationType}</p> */}
                </div>
              ) : null}

              {state?.data?.startDate ? (
                <div className="type pointers">
                  <p className="pointer-headers">Start date:&nbsp;</p>
                  <p> {state?.data?.startDate}</p>
                </div>
              ) : null}

              {state?.data?.endDate ? (
                <div className="type pointers">
                  <p className="pointer-headers">End date:&nbsp;</p>
                  <p> {state?.data?.endDate}</p>
                </div>
              ) : null}

              {state?.data?.closeDate ? (
                <div className="type pointers">
                  <p className="pointer-headers">Close date:&nbsp;</p>
                  <p> {state?.data?.closeDate}</p>
                </div>
              ) : null}

              {state?.data?.closeTime ? (
                <div className="type pointers">
                  <p className="pointer-headers">Close time:&nbsp;</p>
                  <p> {state?.data?.closeTime}</p>
                </div>
              ) : null}

              {state?.data?.contractType ? (
                <div className="type pointers">
                  <p className="pointer-headers">Contract Type:&nbsp;</p>
                  <p> {state?.data?.contractType}</p>
                </div>
              ) : null}

              {/* {state?.data?.webLink ? (
                 <div className="type pointers">
                 <p className="pointer-headers">Web Link:&nbsp;</p>
                 <p> {state?.data?.webLink}</p>
               </div>
              ) : null} */}

              {state?.data?.email ? (
                <div className="type pointers">
                  <p className="pointer-headers">Email:&nbsp;</p>
                  <p> {state?.data?.email}</p>
                </div>
              ) : null}

            </div>
          ) :
            null
          }
          {((state.page === 'OUR_TEAM' || state.page === 'OUR_CLIENT') && state?.data?.aboutUsTeamDataOutPut?.linkedin) ? (
            <div style={{ width: '100%' }} >
              <Row
                className="rowBody"
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                style={{ overflow: 'hidden' }}
              >
                <Col
                  className="gutter-row tabContent contactTab"
                  span={24}
                >
                  <Col
                    className="gutter-row col-style"
                    xs={6} xl={4}                            >
                    <img src={Linkedin} alt="linkedIn"></img>
                    <label style={{ marginLeft: "3px" }}>
                      Linkedin
                    </label>
                  </Col>
                  <Col
                    className="gutter-row"
                  >
                    <a href={state?.data?.aboutUsTeamDataOutPut?.linkedin} target="_blank">
                      {state?.data?.aboutUsTeamDataOutPut?.linkedin}
                    </a>
                  </Col>
                </Col>
              </Row>
            </div>
          ) : null}
          {((state.page === 'OUR_TEAM' || state.page === 'OUR_CLIENT') && state?.data?.aboutUsTeamDataOutPut?.email) ? (
            <div style={{ width: '100%' }} >
              <Row
                className="rowBody"
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                style={{ overflow: 'hidden' }}
              >
                <Col
                  className="gutter-row tabContent contactTab"
                  span={24}
                >
                  <Col
                    className="gutter-row col-style"
                    xs={6} xl={4}                            >
                    <img src={mail} alt="linkedIn"></img>
                    <label style={{ marginLeft: "3px" }}>
                      Email
                    </label>
                  </Col>
                  <Col
                    className="gutter-row"
                  >
                    <a href={state?.data?.aboutUsTeamDataOutPut?.email} target="_blank">
                      {state?.data?.aboutUsTeamDataOutPut?.email}
                    </a>
                  </Col>
                </Col>
              </Row>
            </div>
          ) : null}
          <div style={{ display: "flex", justifyContent: "end" }}>
            {state.page === "apprenticeship" || state.page === "vacancy" ? (
              <div className="apply-btn">
                <a href={state?.data?.urlLink} target="_blank">
                  {state?.data?.urlLink && (
                    <button disabled={disable} className="applyButton">
                      Apply
                    </button>
                  )}
                </a>
              </div>
            ) :
              null
            }
            {state.page != "about-us" ? (
              <div className="phone-icon">
                <button
                  disabled={disable}
                  className="call-btn"
                  onClick={phoneHandler}
                >
                  <img src={callIcon} />
                </button>
              </div>
            ) : null}
            {state?.data?.urlLink || state?.data?.webLink ? (
              <Button
                className="link-btn"
                icon={<LinkOutlined />}
                onClick={LinkHandler}
                style={{ height: 35, width: 35, marginLeft: 10 }}
              ></Button>
            ) : null}
          </div>
        </Card>

        {(state.page === 'product' || state.page === 'service') && state.data.brochureAvailable && <ViewDownloadBrochure state={state} />}

      </div>
      {isShowProfile ? (
        <div>
          <ProfileDropdown closeDropdown={closeDropdown} />
        </div>
      ) : null}
    </motion.div>
  );
}
