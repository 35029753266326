import React, { useState } from "react";
import {
  Input,
  Form,
  Button,
  Upload,
  Space,
  Typography,
  Select,
  Tooltip,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { postRequest } from "../../api/http";
import userServiceApi from "../../api/userapiservice";
import "./Help.scss";
import SearchBarHelp from "./searchHelp";
import "./Feedback.scss";
import { useForm } from "antd/lib/form/Form";

function FeedBack() {
  const [loading, setLoading] = useState(false);
  const { Dragger } = Upload;
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState(null);
  const partnerId = localStorage.getItem("partnerId");
  const [form] = useForm();
  const [fileSizeOk, setFileSizeOk] = useState(false);

  // const checkFile = (file) => {
  //   const isPDFOrDOC =
  //     file.type === "application/pdf" || file.type === "application/msword";
  //   if (!isPDFOrDOC) {
  //     toast.error("You can only upload PDF and DOC files!");
  //   }
  //   return isPDFOrDOC;
  // };

  const checkFile = (file) => {
    const maxSize = 2.5 * 1024 * 1024;
    const isSizeValid = file.size <= maxSize;

    console.log("size", isSizeValid);
    if (!isSizeValid) {
      setFileSizeOk(false);
      toast.error("File size exceeds 2.5MB!");
    } else {
      setFileSizeOk(true);
    }

    return isSizeValid;
  };

  const onFinish = async (values) => {
    try {
      if(file){
      if (fileSizeOk) {
        setLoading(true);
        if (
          values &&
          values.message &&
          values.email &&
          values.feedbackAboutEnum
        ) {
          var formData = new FormData();
          if (file) {
            formData.append("file", file, fileName);
          }
          const formatedData = {
            feedbackAboutEnum: values.feedbackAboutEnum,
            message: values.message,
            email: values.email,
            ageTagId: localStorage.getItem("ageID"),
          };
          const json = JSON.stringify(formatedData);
          const blob = new Blob([json], {
            type: "application/json",
          });
          formData.append("endUserFeedbackDto", blob);
          // console.log(data)
          const response = await postRequest(
            userServiceApi.postFeedbackForm(partnerId),
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response) {
            setFileName(null);
            toast.success("Feedback sent successfully");
            setFileSizeOk(false);
            form.resetFields();
            setLoading(false);
            // window.location.reload();
            // getFiles();
            // setLoader(false);
          }
        }
      } else {
        toast.error("File size exceeds 2.5MB!");
      }
    } else {
      setLoading(true);
      if (
        values &&
        values.message &&
        values.email &&
        values.feedbackAboutEnum
      ) {
        var formData = new FormData();
        const formatedData = {
          feedbackAboutEnum: values.feedbackAboutEnum,
          message: values.message,
          email: values.email,
          ageTagId: localStorage.getItem("ageID"),
        };
        const json = JSON.stringify(formatedData);
        const blob = new Blob([json], {
          type: "application/json",
        });
        formData.append("endUserFeedbackDto", blob);
        // console.log(data)
        const response = await postRequest(
          userServiceApi.postFeedbackForm(partnerId),
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response) {
          toast.success("Feedback sent successfully");
          form.resetFields();
          setLoading(false);
          // window.location.reload();
          // getFiles();
          // setLoader(false);
        }
      }
    }
    } catch (error) {
      // console.log(error)
      toast.error(error.error);
      // setLoading(false);
    }
  };

  const handleUploadAPI = ({ file }) => {
    // console.log("file", file);
    const fileName = file.name;
    setFileName(fileName);
    // var formData = new FormData();
    const blob = new Blob([file], {
      type: "multipart/form-data",
    });
    setFile(blob);

    // formData.append("file", blob);

    if (Object.values(fileList).length > 0) {
      // toast.error("You can upload only one file")
    } else {
      setFileList((pre) => {
        return { ...pre, [file.uid]: file };
      });
    }
  };

  const uploadProps = {
    accept: ".pdf, .jpeg, .png, .jpg",
    beforeUpload: checkFile,
    maxCount: 1,
    multiple: false,
    showUploadList: false,
    customRequest: handleUploadAPI,
  };

  //   if (loading) return <LoadingView message="Loading..." />;

  return (
    <>
      <SearchBarHelp />
      <div className="form-container-feedback">
        <Form
          className="feedback-form"
          form={form}
          //   labelCol={{ span: 4 }}
          labelAlign="left"
          onFinish={onFinish}
        >
          <Form.Item
            className="form-item-feedback"
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Email is required" },
              { type: "email", message: "Enter valid email" },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            className="form-item-feedback"
            label="I want to"
            name="feedbackAboutEnum"
            rules={[
              {
                required: true,
                message: "Feedback type is required",
              },
            ]}
          >
            <Select name="feedbackAboutEnum" placeholder="I want to..">
              <Select.Option value="COMMENT">Leave a comment</Select.Option>
              <Select.Option value="REPORT">Report a bug</Select.Option>
              <Select.Option value="SUGGESTION">
                Suggest an improvement
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            className="form-item-feedback"
            rules={[
              {
                required: true,
                message: "Feedback message is required",
              },
            ]}
            label="Message"
            name="message"
          >
            <Input.TextArea
              maxLength={250}
              showCount
              rows={3}
              placeholder="Message"
            />
          </Form.Item>
          <Form.Item
            className="form-item-feedback"
            label="Document"
            name="feedbackDocument"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please upload a feedback document!",
            //   },
            // ]}
          >
            <div className="files-feedback">
              <div className="file-upload-feedback">
                {/* <label>Add Employer's insurance liability document</label> */}
                <Tooltip
                  title={"Upload image or pdf with size less than 2.5MB"}
                >
                  <div className="upload-component">
                    {/* <Space direction="horizontal"> */}
                    <Dragger {...uploadProps} className="dropbox">
                      <div className="box-content">
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                      </div>
                    </Dragger>
                  </div>
                </Tooltip>
              </div>
              <div className="existing">
                {/* <label>Existing document</label> */}
                {fileName ? (
                  <Space className="fileList-Item">
                    <Typography className="nodoc-text">{fileName}</Typography>
                  </Space>
                ) : (
                  <Space className="fileList-Item">
                    <Typography>No document uploaded</Typography>
                  </Space>
                )}
              </div>
            </div>
          </Form.Item>

          <div className="submitBtn-feedback">
            <Button className="greenBtn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default FeedBack;
