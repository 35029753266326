import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyCQkpuEg2lYfJzME40WsKZKkMoJU2bmRAk",
  authDomain: "connectsu-service.firebaseapp.com",
  projectId: "connectsu-service",
  storageBucket: "connectsu-service.appspot.com",
  messagingSenderId: "237290583102",
  appId: "1:237290583102:web:77cb775a471da40c838d6f",
  measurementId: "G-0T2PT3QGJY",
};
const app = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(app);
export default firebaseAuth;
