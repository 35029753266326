import React, { useEffect, useState } from "react";
import { Form, Button, Card, Spin, Input } from "antd";
import { toast } from "react-toastify";
import { getRequest } from "../../api/http";
import userServiceApi from "../../api/userapiservice";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import ytPlayBtn from "../../assets/images/YouTube_play_button.svg";
import "./Help.scss";
import ModalPlayer from "../../components/PlayerModal/playerModal";
import SearchBarHelp from "./searchHelp";
import { SearchOutlined } from "@ant-design/icons";

function Help() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [mediatype, setmediatype] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [helpTabName, setHelpTabName] = useState([]);
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    setHelpTabName(data.map(ele => ele.assetTypeEnum));
  }, [data]);

  const tabName = (tab) => {
    if (tab === "BUSINESS_CARD") {
      return "Business card";
    } else if (tab === "CAROUSEL") {
      return "Corousel";
    } else if (tab === "ABOUT_US") {
      return "About us";
    } else if (tab === "PRODUCT") {
      return "Product";
    } else if (tab === "CONTRACTS") {
      return "Contract";
    } else if (tab === "SERVICE") {
      return "Service";
    } else if (tab === "APPRENTICESHIP") {
      return "Apprenticeship";
    } else if (tab === "VACANCY") {
      return "Vacancy";
    } else if (tab === "COURSES") {
      return "Courses";
    } else if (tab === "WHY_WORK") {
      return "Why work for us";
    } else if (tab === "TEAMS_AND_ROLES") {
      return "Teams and roles";
    } else if (tab === "WORK_EXPERIENCE") {
      return "Experience of work";
    } else if (tab === "FULL_MEMBERS") {
      return "Full Members";
    } else if (tab === "HUB_MEMBERS") {
      return "Hub Members";
    } else if (tab === "SPONSOR_LOGOS") {
      return "Sponsor Logos";
    } else if (tab === "ENGAGEMENT_TAB") {
      return "Engagement Tab";
    } else if (tab === "RESTORE_MEMBERS") {
      return "Restore Members";
    } else if (tab === "HOME") {
      return "Home";
    } else if (tab === "FAVORITES") {
      return "Favorites";
    } else if (tab === "ORGANIZATION_LOGIN") {
      return "+ Organization";
    } else if (tab === "EDIT_PROFILE") {
      return "Edit Profile";
    } else if (tab === "MICROSITES") {
      return "Microsites";
    } else if (tab === "USER_LOGIN") {
      return "User Login";
    } else if (tab === "SEARCH_AND_FILTERS") {
      return "Search and Filters";
    } else if (tab === "VIEWS") {
      return "Views";
    }
  };

  const convertToStandardURL = (shortUrl) => {
    if (shortUrl?.indexOf("youtu.be") !== -1) {
      const video_id = shortUrl?.split("youtu.be/")[1].split("?")[0];
      return `https://www.youtube.com/watch?v=${video_id}`;
    }
    return shortUrl;
  };

  const PlayVideoHandler = (url, mediaType) => {
    setmediatype(mediaType);
    setVideoUrl(url);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setVideoUrl("");
  };

  const getHelpData = async () => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await getRequest(searchTerm === '' ? userServiceApi.help() : userServiceApi.getFilteredHelpInfo("END_USER", searchTerm), { headers });
      if (response) {
        setData(response);
      }
    } catch (error) {
      toast.error(error.error);
    }
    finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    const handler = setTimeout(() => {
      getHelpData();
    }, 500);
    return () => {
      clearTimeout(handler)
    }
  }, [searchTerm]);

  return (
    <>
      <SearchBarHelp />
      <div className="searchbar-containers" style={{ margin: '10px', justifyContent: 'end', display: 'flex' }} >
        <Input
          className="searchbar"
          placeholder={`Search help`}
          value={searchTerm}
          suffix={
            <SearchOutlined
              style={{ color: "#CC0E2D", verticalAlign: "middle" }}
            />
          }
          onChange={(e) => { setSearchTerm(e.target.value) }}
          style={{ width: '328px' }}
        />
      </div>

      {(!loading && helpTabName.length > 0) ? helpTabName.map((tabNames, i) => (
        <div className="card-container-help">
          <div
            key={i}
            style={{ display: "flex", flexDirection: "column", width: '100%' }}
          >
            <div className="title-help">{tabName(tabNames)}</div>
            <div className="org-card-container-help">
              {data && data.length > 0
                ? data.map((datas) =>
                  datas?.assetTypeEnum === tabNames ? (
                    <motion.div
                      initial={{ opacity: 0, scale: 0.5 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{
                        duration: 0.8,
                        delay: 0.5,
                        ease: [0, 0.71, 0.2, 1.01],
                      }}
                    >
                      <Card
                        className="card-item help-page-card-item card-hover"
                        key={datas.id}
                        bordered={false}
                      >
                        <div
                          className="help-text-container"
                        >
                          <span
                            className="datas-position text-bold"
                            style={{
                              maxWidth: "300px",
                              fontSize: "13px",
                              fontWeight: "bold",
                              paddingBottom: "3px",
                              lineHeight: "1",
                              // listStyle: "none",
                              fontFamily:
                                "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
                            }}
                          >
                            {/* {datas.name} */}
                            {/* <img src={datas.mediaLink} alt={datas.name}
                            className="preview-image" /> */}
                            {datas.title}
                            <div>
                              {/* <Rate value={datas.averageRating} disabled allowHalf style={{fontSize:13}}></Rate> */}
                              {/* <p>{datas}</p> */}
                            </div>
                          </span>
                          <div className="sector-info-container">
                            <span
                              className="datas-details text-bold"
                              style={{
                                fontSize: "12px",
                                paddingBottom: "3px",
                                lineHeight: "1",
                                // listStyle: "none",
                                fontFamily:
                                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
                              }}
                            >
                              {/* {datas.type} */}
                              {/* {datas.type === "EDUCATION_SECTOR"
                            ? "Education"
                            : datas.type === "PUBLIC_SECTOR"
                              ? "Public"
                              : datas.type === "BUSINESS_SECTOR"
                                ? "Business"
                                : ""} */}
                              {datas.description}
                            </span>
                            {/* <span className="datas-details text-bold">
                          {datas?.sectorsOutputs[0]?.sectorOutput.name}
                        </span> */}
                          </div>
                          <div>
                            {datas?.webLink ? (
                              <Button
                                type="primary"
                                className="btn-km"
                                onClick={() =>
                                  window.open(datas?.webLink, "_blank")
                                }
                                style={{ cursor: "pointer" }}
                              >
                                Know More
                              </Button>
                            ) : null}
                          </div>
                        </div>
                        <div className="image-container"  >
                          {/* <img
                        src={datas?.mediaLink}
                        alt={datas.name}
                        className="preview-image"
                      /> */}
                          {datas.mediaLink?.mediaType === "SOCIAL_MEDIA" &&
                            datas.mediaLink?.url ? (
                            <>
                              {(() => {
                                const standardURL = convertToStandardURL(
                                  datas.mediaLink.url
                                );
                                const videoID = standardURL
                                  .split("v=")[1]
                                  ?.split("&")[0];
                                return (
                                  <div
                                    className="media youtube"
                                    style={{ objectFit: "contain" }}
                                    onClick={() =>
                                      PlayVideoHandler(
                                        datas.mediaLink?.url,
                                        datas.mediaLink?.mediaType
                                      )
                                    }
                                  >
                                    <img
                                      src={`https://img.youtube.com/vi/${videoID}/hqdefault.jpg`}
                                      alt=""
                                      className="video-preview-image"
                                      style={{
                                        height: "100%",
                                        borderRadius: "8px",
                                      }}
                                    />
                                    <img
                                      className="ytBtnOverlay"
                                      src={ytPlayBtn}
                                    />
                                  </div>
                                );
                              })()}
                            </>
                          ) : datas.mediaLink?.mediaType === "VIMEO_MEDIA" &&
                            datas.mediaLink?.url ? (
                            <div
                              className="media vimeo youtube"
                              onClick={() => {
                                PlayVideoHandler(
                                  datas.mediaLink?.url,
                                  datas.mediaLink?.mediaType
                                );
                              }}
                            >
                              <img
                                src={`https://vumbnail.com/${datas.mediaLink?.url?.split("/")[4]
                                  }.jpg`}
                                className="video-preview-image"
                                style={{
                                  height: "100%",
                                  borderRadius: "8px",
                                }}
                              />
                              <img className="ytBtnOverlay" src={ytPlayBtn} />
                              {/* <iframe
                              controls="false"
                              // video={VimeoLinkPlayHandler(data?.mediaLink.url)}
                              // src={`${datas.mediaLink?.url}?title=0&byline=0&portrait=0&transparent=0&autoplay=1`}
                              src={datas.mediaLink?.url}
                              light={true}
                              // responsive={true}
                              // onEnd={() => setVideoCompleted(false)}
                              // onProgress={() => setVideoCompleted(true)}
                              // onPlaying={() => setVideoCompleted(true)}
                              // onPlay={() => setVideoCompleted(true)}
                              // onPause={() => setVideoCompleted(false)}
                              allowFullScreen
                              height="100%"
                              width="100%"
                              style={{ backgroundColor: "black" }}
                            /> */}
                              {/* </div> */}
                            </div>
                          ) : (
                            <div className="media">
                              <div className="slider-Img">
                                <img
                                  src={datas.mediaLink?.url}
                                  alt=""
                                  className="home-preview-img"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </Card>
                    </motion.div>
                  ) : null
                )
                : null}
            </div>
            <div className="connectsu-line-help"></div>
          </div>
        </div>
      )) : null}

      {helpTabName.length === 0 ? <div style={{
        fontWeight: 'bold',
        marginTop: '20px',
        marginBottom: '10px',
        fontSize: '15px',
        textAlign: 'center'
      }} > No Data </div> : null}
      {loading && (
        <div className="no-data-warning">
          <Spin tip="Loading" size="large" />
        </div>
      )}

      {isModalOpen ? (
        <ModalPlayer
          closeModal={handleCancel}
          mediaLink={videoUrl}
          mediaType={mediatype}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Help;
